export default {
  "main": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
    "dialog": {
      "success": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Succès! Entité ", _interpolate(_named("entity")), " enregistré(e)."])},
      "successUser": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Succès! Utilisateur ", _interpolate(_named("username")), " enregistré(e)."])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error"])},
      "validation": {
        "success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès!"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le résultat est supérieur au maximum"])},
        "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le résultat est inférieur au minimum"])},
        "negative": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur négative n'est pas autorisée"])},
        "greater100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Une valeur supérieure à 100 n'est pas autorisée"])},
        "coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La somme des valeurs doit être supérieure à 100"])}
      },
      "errorMessage": {
        "saveError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur: la demande n'a pas été sauvée!"])},
        "missingRequiredAtt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributs obligatoires manquants"])},
        "missingObjAtt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributs/types obligatoires manquants sur "])},
        "incorrectAtt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribut incorrect sur "])},
        "attWrongType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attributs avec un type incorrect "])}
      }
    },
    "filter": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "savedFilters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres enregistrés"])},
      "addCriterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un critère"])},
      "deleteMsg": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Êtes-vous sûr de vouloir supprimer le filtre (", _interpolate(_named("filter")), ")?"])}
    },
    "confirmationDialog": {
      "confirmationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partir"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir partir, vous perdrez vos données si vous continuez!"])}
    },
    "confirmationDeleteDialog": {
      "confirmationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de supprimer cette garantie"])}
    },
    "confirmationDialogCloseDemand": {
      "confirmationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partir"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de passer vers le mode expert? Une fois accepté, le retour vers le mode de simulation simple est impossible."])}
    },
    "confirmationDialogCancelOfferOrOrder": {
      "confirmationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir annuler cette"])},
      "suite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sans suite "])}
    },
    "confirmationDialogBackToCommercial": {
      "confirmationButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr de vouloir retourner vers commercial ?"])}
    },
    "AdditionalActionsDialog": {
      "cancelOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'offre"])},
      "cancelOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la commande"])},
      "returnToFrontOffice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner vers commercial"])}
    },
    "backToCommercialNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre est en attente retour commerce."])},
    "OffreCanceledNotification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'offre a été annulé."])},
    "missingRecipientPhoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le numéro de téléphone du destinataire est manquant ou vide."])},
    "missingRecipientEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'adresse e-mail du destinataire est manquante ou vide."])},
    "TaskSelectionDialog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de sélectionner l'action à exécuter :"])},
    "welcome": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["My company: ", _interpolate(_named("company")), "!"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La clé de votre succès numérique!"])},
    "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformez vos défis en opportunités et dominez le futur avec notre plateforme d'innovation technologique."])},
    "thanks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci"])},
    "requiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["* Obligatoire"])},
    "messageRequiredField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez les champs obligatoires avant d'enregistrer"])},
    "selectOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-- Sélectionner une option --"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "years": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre demande est en cours de traitement. Veuillez patienter un moment"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous à votre compte"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORIGINATION"])},
    "registration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscription"])},
    "forgot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "forgotName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié"])},
    "forgotNameHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre nom d'utilisateur pour réinitialiser votre mot de passe."])},
    "validationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez insérer le code de validation reçu"])},
    "dueDateMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez depassé la date d'échéance limite"])},
    "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
    "signInToExistantAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avez-vous déjà un compte?"])},
    "resendPasswordMsg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tu n'as pas reçu de code ?"])},
    "resendPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renvoie le nouveau code"])},
    "noResults": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat"])},
    "duplicatedBalanceSheet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Même situation financière créer en double dans la même période."])},
    "date": {
      "invalidDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date invalide!!"])}
    },
    "connect": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "sign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
      "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
      "passwordConfirmation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation mot de passe"])},
      "changePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changer le mot de passe"])},
      "changePasswordTitleTemporary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous utilisez un mot de passe temporaire, veuillez définir votre propre mot de passe"])},
      "changePasswordTitleExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a expiré, veuillez définir un nouveau mot de passe"])},
      "changePasswordMessageFailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe et la confirmation du mot de passe saisis sont différents, ils doivent avoir la même valeur"])},
      "changePasswordMessageSuccessfully": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre mot de passe a été mis à jour avec succès."])},
      "rulesPassowrd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit contenir un chiffre de 1 à 9, une lettre minuscule, une lettre majuscule, un caractère spécial, sans espace, et il doit comporter entre 8 et 16 caractères. "])},
      "sendCodeMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un code de vérification vous a été envoyé"])},
      "changePasswordMessageUnchanged": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre nouveau mot de passe doit être différent de vos trois derniers mots de passe."])},
      "wrongCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur ou mot de passe incorrect. Vous avez que 3 tentatives pour réessayer."])}
    },
    "forgotPassword": {
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "passordFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum huit caractères, au moins une lettre majuscule et minuscule, un chiffre et un caractère spécial"])},
      "passordConfirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation mot de passe"])},
      "passordValidation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demander un code de validation"])},
      "validation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le code"])},
      "verificationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code de Vérification"])}
    },
    "register": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créez votre compte"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone portable"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
      "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
      "passordFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimum huit caractères, au moins une lettre majuscule et minuscule, un chiffre et un caractère spécial"])},
      "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation mot de passe"])},
      "validationCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez insérer le code de validation reçu"])},
      "validateSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider le code SMS"])},
      "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Champ obligatoire"])},
      "confirmPasswordCorrectly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur! Veuillez confirmer votre mot de passe correctement."])},
      "fillSMS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur! Veuillez remplir tous les codes SMS."])},
      "invalidPassord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe incorrect"])},
      "invalidEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email invalide"])},
      "invalidPhone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone invalide"])},
      "claimedTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette tâche a déjà été prise en charge. Veuillez actualiser votre liste de tâches"])}
    },
    "labels": {
      "AccessDenied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occupation"])},
      "AccessDeniedMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas la permission d'accéder à cette page. Veuillez contacter votre administrateur système si vous pensez qu'il s'agit d'une erreur."])},
      "AccessDeniedAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour à l'accueil"])},
      "by": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par"])},
      "months": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
      "isCfClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Capitole"])},
      "renewal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client Renouvelant "])},
      "days": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jours"])},
      "partyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type tiers"])},
      "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
      "usageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d`usage"])},
      "familyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Âge"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
      "gender": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
      "birthdepartement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Département de naissance"])},
      "socioprofessionalcategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie Socio professionnelle"])},
      "bornCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de naissance"])},
      "postalCode_cityOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal / Ville de naissance"])},
      "manager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigeant"])},
      "telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléphone"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["recherche"])},
      "dateOfEntry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’entrée"])},
      "relationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de relation"])},
      "sharingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage de partage"])},
      "employerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'employeur"])},
      "activityArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d'activité"])},
      "primaryEmployer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employeur principal"])},
      "contractType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contrat"])},
      "occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
      "hoursNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'heures"])},
      "tenureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de titularisation"])},
      "employerSeniority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancienneté employeur"])},
      "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ou"])},
      "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
      "endDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
      "adresse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "number": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])},
      "joblocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de travail"])},
      "identityDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents d’identité"])},
      "of": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["de"])},
      "showing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher"])},
      "results": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["résultats"])},
      "signatureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de signature"])},
      "sliders": {
        "gracePeriodTerms": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), " ", _interpolate(_named("period")), " de franchise"])},
        "1_months": {
          "slider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("param")), " Mois"])},
          "presentation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Mois (", _interpolate(_named("param")), " Mois) "])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])}
        },
        "3_months": {
          "slider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("param")), " Trimestre"])},
          "presentation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Trimestre (", _interpolate(_named("param")), " Trimestre) "])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestre"])}
        },
        "6_months": {
          "slider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("param")), " Semestre"])},
          "presentation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Semestre (", _interpolate(_named("param")), " Semestre) "])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semestre"])}
        },
        "12_months": {
          "slider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("param")), " An"])},
          "presentation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["An (", _interpolate(_named("param")), " An) "])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An"])}
        },
        "default": {
          "slider": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("param")), " périodes de ", _interpolate(_named("duration")), " ", _interpolate(_named("period"))])},
          "serviceInfo": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("param")), " ", _interpolate(_named("period")), " (", _interpolate(_named("duration")), " périodes de ", _interpolate(_named("param2")), " ", _interpolate(_named("period2")), ")"])},
          "presentation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("duration")), " ", _interpolate(_named("period")), " (", _interpolate(_named("param")), " périodes de ", _interpolate(_named("duration")), " ", _interpolate(_named("period")), ")"])},
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périodes"])}
        }
      },
      "geographicalZone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe Zone Géographique"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "comments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires"])}
    },
    "menu": {
      "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
      "myDemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demandes"])},
      "third": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiers"])},
      "tasks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
      "scales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barémes"])},
      "availability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Disponibilités"])},
      "command": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes"])},
      "admin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration"])},
      "user": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateurs"])},
      "deliverySchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Planning livraison"])}
    },
    "dossier": {
      "globalLabels": {
        "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une commande"])},
        "dossierList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Commandes"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réintialiser"])},
        "addDossier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter Commande"])},
        "editDossier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer Commande"])},
        "editCommande": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande Véhicule N°"])},
        "editSupplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande Fournisseur N°"])},
        "viewDossier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter Commande"])},
        "numberDossier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande N° "])},
        "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
        "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
        "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour"])},
        "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
        "statutOption": {
          "CREATECOMMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création de Dossier"])},
          "APPROVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approuvé"])}
        }
      },
      "dialog": {
        "Editsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La dossier a été mise à jour avec succès"])},
        "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
      },
      "asset": {
        "dialog": {
          "Editsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande a été mise à jour avec succès"])},
          "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
        },
        "detail": {
          "energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Énergie"])},
          "power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance"])},
          "gearbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boîte de vitesses"])},
          "emission_CO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emissions de CO2"])},
          "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])},
          "show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher les détails de l'asset"])}
        },
        "command": {
          "vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicule"])},
          "option": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
          "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessoires"])},
          "fees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais divers"])},
          "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
          "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])},
          "priceHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix HT"])},
          "priceTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix TTC"])},
          "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref Commande"])},
          "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer une commande fournisseur"])},
          "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
          "Vehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicule"])},
          "Equipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipement"])},
          "Boat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boat"])},
          "SupplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande Fournisseur"])}
        }
      },
      "enum": {
        "statut": {
          "COMMAND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
          "VALIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation Commande"])},
          "SUPPLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Approvisionnement"])},
          "DELIVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
          "ARCHIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivage"])}
        }
      },
      "button": {
        "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler dossier"])},
        "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer dossier"])},
        "addACriterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un critère"])},
        "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
        "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])}
      },
      "filtreMenu": {
        "filtres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
        "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reférence"])},
        "rentalCommand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande Loueur"])},
        "commandDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Commande"])},
        "deliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Livraison"])},
        "commandRDVDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date RDV Client"])},
        "commandDeliveryAdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de Livraison"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
        "customer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
        "filtresList": {
          "commandDeliveryAdress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de Livraison"])}
        }
      }
    },
    "supplierOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande Fournisseur N°"])},
    "commandVehicle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["commande véhicule N° "])},
    "table": {
      "filterButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "importButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
      "exportButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "columnsButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afficher/Masquer Colonnes"])}
    },
    "header": {
      "userProfilesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Profils de l'Utilisateur"])}
    }
  },
  "enum": {
    "offer": {
      "status": {
        "INITIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation"])},
        "CUSTACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation Client"])},
        "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
        "CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractualisation"])},
        "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulation"])},
        "CUSTREFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refus Client"])},
        "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse en cours"])},
        "DOCCOMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document Completé"])},
        "DOCVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document valide"])},
        "DOCNOTVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document non valide"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision Acceptée"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision Refusée"])},
        "DOCGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat généré"])},
        "CONTRACTSIGN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat Signé"])},
        "OFFCOMPLTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre Complétée"])},
        "ACCEPTED_RESERVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptée avec réserves"])},
        "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision en cours"])},
        "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])},
        "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etude Prise en charge"])},
        "ONHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etude en attente"])},
        "ANALYSIS_ON_GOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etude Prise en charge"])},
        "ANALYSIS_ON_HOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etude en attente"])},
        "MANSIGNATURE_INPROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature manuelle en cours"])},
        "ELECSIGNATURE_INPROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature électronique en cours"])},
        "MANSIGNATURE_COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature manuelle terminée"])},
        "ELECSIGNATURE_COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature électronique terminée"])},
        "ANALYSIS_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse terminée"])}
      },
      "comments": {
        "1111C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaires commercial"])},
        "1112C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motivation Projet"])}
      },
      "financingPhase": {
        "simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation"])}
      },
      "ClientTaskDefinition": {
        "STEP_ENTER_CUSTOMER_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter Offre"])},
        "STEP_COMPLETE_OFFER_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter Demande"])},
        "STEP_UPLOAD_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter Documents"])},
        "STEP_START_FINANCING_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer vers Etude"])},
        "STEP_VALIDATE_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider Documents"])},
        "STEP_ENTER_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir Décision"])},
        "ANALYSIS_ON_GOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse en cours "])},
        "STEP_PRINT_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer Documents"])},
        "STEP_SIGN_CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer Contrat"])},
        "STEP_START_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procéder à l'analyse"])},
        "STEP_ENTER_DELEGATION_LEVEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau de délégation"])},
        "STEP_MODIFY_CONDITIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier Conditions"])},
        "STEP_ENTER_OPINION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir Avis"])},
        "CUSTACCEPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptation Client"])},
        "ANALYSIS_ON_HOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etude en attente"])},
        "ANALYSIS_FINISHED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse terminée"])},
        "MANSIGNATURE_INPROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature manuelle en cours"])},
        "ELECSIGNATURE_INPROGRESS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature électronique en cours"])},
        "MANSIGNATURE_COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature manuelle terminée"])},
        "ELECSIGNATURE_COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature électronique terminée"])},
        "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
        "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision Acceptée"])},
        "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision Refusée"])},
        "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre annulée"])},
        "SEND_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmis à la décision"])},
        "SEND_SALES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour vers commercial"])},
        "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
        "ACCEPTED_RESERVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepté avec réserves"])},
        "ANALYSIS_DRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse par DRI"])},
        "ANALYSIS_NOT_ON_GOING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande en attente de prise en charge"])},
        "ANALYSIS_ON_GOING_DRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse en cours par DRI"])},
        "ANALYSIS_ON_HOLD_DRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse en attente par DRI"])},
        "CONTRACTSIGN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature du contrat"])},
        "CUSTREFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refus du client"])},
        "DOCCOMPLETE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents complétés"])},
        "DOCGEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération du document"])},
        "DOCNOTVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document non valide"])},
        "DOCVALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document valide"])},
        "ELECSIGNATURE_CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature électronique annulée"])},
        "ELECSIGNATURE_EXPIRED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature électronique expirée"])},
        "INITIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre crée"])},
        "OFFCOMPLTE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre complétée"])},
        "PAYMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déversement Back-Office"])},
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
        "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision refusée"])},
        "SEND_DRI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmis à la DRI"])}
      }
    },
    "party": {
      "type": {
        "party-Person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne physique"])},
        "party-Organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne morale"])},
        "party-FrenchOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne morale"])},
        "party-FrenchPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne physique"])}
      }
    }
  },
  "task": {
    "completePreCondition": {
      "DISTRIBUTIONCTX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande véhicule"])},
      "DISTRIBUTIONCTX_CDV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation commande"])}
    },
    "tabs": {
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
      "enterCustomerDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter Offre"])},
      "completeOfferInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter Demande"])},
      "uploadDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter Documents"])},
      "sendFinancingRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer Vers Etude"])},
      "validateDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents Valides"])},
      "typeMonoDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir Décision"])},
      "printDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer Document"])},
      "signContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attribuer un Contrat"])},
      "analysis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre Analyse"])},
      "periodicity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Périodicité"])},
      "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉTUDE"])},
      "startOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démarrer la commande"])},
      "EnterOpinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir Avis"])},
      "Decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
      "EnterDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir Décision"])},
      "DecisionHistory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique Décision"])}
    },
    "study": {
      "questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QUESTIONNAIRE"])},
      "algorithms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ALGORITHMES"])},
      "Score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCORE"])},
      "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
      "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation du Score"])},
      "cotations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COTATIONS"])},
      "labRiskRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COTATION RISQUE LAB"])},
      "customerRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation Client"])},
      "materialRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation Matériel"])},
      "Note NIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note NIO"])},
      "NoteLAB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note LAB"])},
      "consolidatedRating": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Note Consolidée"])},
      "ratios": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RATIOS"])},
      "reserves": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RÉSERVES"])},
      "reservesList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des réserves"])},
      "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "refresh": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actualiser"])},
      "scoreEvaluation": {
        "Accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepté"])},
        "Rejected": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeté"])}
      },
      "ratiosCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "RatingBonus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation Bonus"])},
      "RatingMalus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation Malus"])},
      "AssetClassification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation Matériel"])},
      "CATEGORY_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratios Financiers"])},
      "CATEGORY_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ratios Quantitatives"])},
      "reserveList": {
        "Reserve_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantissement"])},
        "Reserve_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise Maritime"])},
        "Reserve_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution avec quotité %"])},
        "Reserve_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothéque"])}
      },
      "ratingHistoryTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique des Cotations"])},
      "calculatedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur Calculée"])},
      "updatedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur Mise à Jour"])},
      "whoModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qui a Modifié"])},
      "whenModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de Modification"])}
    },
    "actions": {
      "STEP_ENTER_CUSTOMER_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter Offre"])},
      "STEP_COMPLETE_OFFER_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter Demande"])},
      "STEP_UPLOAD_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compléter Documents"])},
      "STEP_START_FINANCING_REQUEST": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer vers Etude"])},
      "STEP_VALIDATE_DOCUMENTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider Documents"])},
      "STEP_ENTER_DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisir Décision"])},
      "STEP_PRINT_DOCUMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer Documents"])},
      "STEP_SIGN_CONTRACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signer Contrat"])},
      "STEP_START_ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Procéder à l'analyse"])},
      "ENTER_COMMENT_TO_CANCEL_OFFER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler l'offre"])},
      "ENTER_COMMENT_TO_CANCEL_ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la commande"])},
      "ENTER_COMMENT_TO_RETURN_MIDDLE_OFFICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner vers commercial"])}
    },
    "labels": {
      "value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
      "offerRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf de la demande"])},
      "clientRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf du client"])},
      "taskAssignee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur affecté"])},
      "actionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur affecté"])},
      "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom client"])},
      "organizationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom société"])},
      "interventionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'intervention"])},
      "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date échéance"])},
      "offerStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut demande"])},
      "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
      "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des actions"])},
      "partyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type tiers"])},
      "enterYourDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre décision"])},
      "reasonForDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif de la décision"])},
      "youHaveExceed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez depassé 65000 caractères"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Merci de préciser la situation financière"])},
      "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Champ obligatoire"])},
      "startAnalysisButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commencer l'analyse"])},
      "pauseAnalysisButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre l'analyse en Attente"])},
      "continueAnalysisButtonLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer l'analyse"])},
      "actionValidationSuccess": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Votre action (", _interpolate(_named("actionType")), ") a été prise en compte."])},
      "particular": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Particulier"])},
      "professionnal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnel"])}
    },
    "reasons": {
      "Reason_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux endettement, RAV"])},
      "Reason_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH trop élevé"])},
      "Reason_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation professionnelle hors critère"])},
      "Reason_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation personnelle hors critère"])},
      "Reason_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant, durée trop faible"])},
      "Reason_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client non satisfait"])},
      "Reason_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux non adapté"])},
      "Reason_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrence plus intéréssante"])}
    },
    "reserves": {
      "Reserve_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantissement"])},
      "Reserve_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expertise Maritime"])},
      "Reserve_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caution avec quotité %"])},
      "Reserve_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothéque"])},
      "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
    },
    "buttons": {
      "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
      "validate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valider"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "next_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivant"])},
      "previous_field": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
      "send_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transmettre"])},
      "download_document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger"])},
      "claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prendre en charge"])},
      "unclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler prise en charge"])},
      "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assigner"])},
      "delegate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déléguer"])},
      "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poursuive"])},
      "CANCELLATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "Offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre"])},
      "Order": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
      "Return_to_Commerce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retour vers commerce"])},
      "Send_to_Commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retourner vers commercial"])},
      "Classed_without_suite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classer sans suite"])},
      "CANCEL_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler la signature"])},
      "RESTART_TRANSACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relancer la signature"])},
      "go_to_action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceder à l'action"])},
      "additional_actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
    },
    "tooltip": {
      "noAvailable_Actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il semble que le contexte ne soit pas assigné ou que les actions supplémentaires ne soient pas disponibles dans cette situation."])}
    },
    "completeOffer": {
      "party": {
        "person": {
          "situation": {
            "dateOfBirthChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance enfant"])},
            "childrenAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
            "suffix": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ans"])}
          }
        }
      },
      "asset": {
        "totalFraisTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total frais TTC"])}
      }
    },
    "completeDocument": {
      "party": {
        "person": {
          "section": {
            "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDENTITÉ"])},
            "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SITUATION"])},
            "meansOfCommunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOYENS DE COMMUNICATION"])},
            "occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFESSION"])},
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADRESSE"])},
            "financialSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SITUATION FINANCIÈRE"])}
          },
          "identity": {
            "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
            "usageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d`usage"])},
            "familyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
            "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
            "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
            "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
            "birthplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal / Ville de naissance"])}
          },
          "situation": {
            "MaritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Marital"])},
            "matrimonialRegime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime matrimonial"])},
            "nbOfDependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de personnes à charge"])},
            "nbOfChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'enfants"])},
            "homemakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes au foyer"])},
            "retirementPassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passage retraite le"])},
            "dateOfBirthChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance enfant"])},
            "childrenAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])}
          },
          "meansOfCommunication": {
            "contactMechanism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
            "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
            "nonSolicitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sollicitation"])}
          },
          "occupation": {
            "employerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'employeur"])},
            "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d'activité"])},
            "principal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employeur principal"])},
            "occupationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contrat"])},
            "positionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation professionnelle"])},
            "professionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
            "nbHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'heures"])},
            "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'entrée"])},
            "tenureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de titularisation"])},
            "employerCreationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date création employeur"])}
          },
          "address": {
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type adresse"])},
            "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
            "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
            "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
            "streetNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])}
          },
          "financialSituation": {
            "analysisDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'analyse"])},
            "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
            "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
            "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
            "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])}
          }
        },
        "organization": {
          "section": {
            "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDENTITÉ"])},
            "contactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PERSONNES À CONTACTER"])},
            "meansOfCommunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOYENS DE COMMUNICATION"])},
            "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADRESSE"])}
          },
          "identity": {
            "siren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siren"])},
            "commercialName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom commercial"])},
            "legalCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie juridique"])},
            "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "activitySector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
            "acronym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acronyme"])},
            "useName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'usage"])}
          },
          "contactPerson": {
            "familyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
            "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
            "qualificationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction"])},
            "signingAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouvoir de signature"])},
            "hasSigningAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
            "hasNotSigningAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
          },
          "meansOfCommunication": {
            "contactMechanism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
            "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
            "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
            "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
            "nonSolicitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sollicitation"])}
          },
          "address": {
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type adresse"])},
            "codePostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
            "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
            "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
            "streetNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])},
            "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 1"])},
            "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 2"])},
            "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 3"])}
          }
        }
      }
    },
    "supportingDocumentLabel": {
      "IDENTITYPROOFPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité"])},
      "IDENTITYPROOFPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identité"])},
      "REVENUEPROOFPP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenus"])},
      "REVENUEPROOFPM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liasse fiscale"])},
      "BANKIDPROOF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
      "CNI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CNI"])},
      "MF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matricule fiscale"])},
      "BULLETINS_SALAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulletins de salaire"])},
      "DERNIER_EXERCICE_COMPTABLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier exercice comptable"])},
      "RIB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RIB"])},
      "VEHICULE_INVOICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facture du véhicule"])},
      "DOC_reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOC réserve"])},
      "Autres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
    },
    "validateDocument": {
      "headers": {
        "checkListAcceptation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification liste"])},
        "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
        "documentsToProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents à traiter"])},
        "documentsValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents Validés"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "gatheringProcessStatus": {
          "PROCESSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En traitement"])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
          "CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle"])},
          "LIST_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste clôturée"])},
          "TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
          "VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide"])}
        },
        "supportingDocumentStatus": {
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
          "OVERWRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacé"])},
          "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
          "VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])},
          "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeté"])}
        }
      }
    },
    "uploadDocument": {
      "labels": {
        "bulkUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléchargement Par Lot"])},
        "bulkUploadCTA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez télécharger vos documents par lot et les affecté par la suite"])},
        "clickToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cliquez pour télécharger"])},
        "dropToUpload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou faites glisser et déposez"])}
      },
      "headers": {
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "received": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eléments reçus"])},
        "Validated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eléments Validés"])},
        "gatheringProcessStatus": {
          "PROCESSING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En traitement"])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
          "CONTROL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle"])},
          "LIST_CLOSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste clôturée"])},
          "TIMEOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timeout"])},
          "VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide"])}
        },
        "supportingDocumentStatus": {
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
          "OVERWRITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplacé"])},
          "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente"])},
          "VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])},
          "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rejeté"])},
          "QUALIFIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reçu"])}
        }
      }
    },
    "historiesStep": {
      "headers": {
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
        "executionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'exécution"])},
        "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
        "action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action"])},
        "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executée par"])},
        "variables": {
          "variables": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Variables"])},
          "offerReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence Demand"])},
          "offerStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Demande"])},
          "offerPhase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase Demande"])},
          "offerCreationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création Demande"])},
          "customerReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence client"])},
          "customerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du client"])},
          "customerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Client"])},
          "clientDecisionStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut de décision du client"])},
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])}
        },
        "candidateGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil Utilisateur"])},
        "histories": {
          "resourceUid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ID"])},
          "actionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "userId": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
          "oldValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur avant modification"])},
          "newValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur aprés modification"])},
          "actionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de modification"])},
          "modificationHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
          "noDataMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun Historique enregistré"])},
          "Claim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise en charge"])},
          "unclaim": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prise en charge annulée"])},
          "Assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assignée"])},
          "delegate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déléguée"])},
          "Complete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Executée"])},
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vraie"])},
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faux"])},
          "Executed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exécuté"])},
          "Cancelled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])}
        }
      }
    },
    "printDocument": {
      "preview": {
        "tittle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document sélectionné"])},
        "noPages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No pages"])}
      },
      "templateList": {
        "selectAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner tout"])}
      },
      "templateForwardDocuments": {
        "recipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire(s)"])}
      },
      "sentCTA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document(s) envoyé(s) avec succès"])},
      "filesName": {
        "Fiche_de_decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiche de décision"])},
        "Dossier_contrat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dossier contrat"])},
        "Contrat_Credit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat credit"])}
      },
      "signature_labels": {
        "bouttons": {
          "INITSIGNATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialiser la signature"])}
        },
        "SIGNATUREMETHOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de signature"])},
        "MANUAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature Manuelle"])},
        "ELECTRONIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature électronique"])},
        "messages": {
          "signatureUpdatedSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All associated parties SignatureDates updated successfully!"])},
          "signatureUpdatedError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["An error occurred with the associated parties SignatureDates updated!"])},
          "signDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de signature"])}
        }
      }
    }
  },
  "tooltip": {
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
    "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Delivery Address"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price"])},
    "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Discount"])},
    "priceHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price HT"])},
    "priceTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Price TTC"])}
  },
  "demand": {
    "financialSituation": {
      "sumOfInterests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût total intérêt"])},
      "sumOfTotalPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total à rembourser"])},
      "aprc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEG"])},
      "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEA"])},
      "debtBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endettement Avant"])},
      "ravBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAV Avant"])},
      "debtAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endettement Aprés"])},
      "ravAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAV Aprés"])},
      "filterByFinancialProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer par produit financier"])},
      "pricingDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails des prix"])},
      "bearing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PALIER"])},
      "amountToFinance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à financier"])},
      "term": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terme"])},
      "remunerationRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux rémunération"])},
      "periodicitRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periodicité taux"])},
      "paymentDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date payement"])},
      "securityDeposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt de garantie"])},
      "securityDepositMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode dépôt garantie"])},
      "summaryOfTheOffer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif de l'offre"])},
      "calculate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Calculer"])},
      "totalInterestCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût total des intérêts"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVENUS ET CHARGES"])},
      "partyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beneficiaire"])},
      "partyRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Role"])},
      "coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quotité"])},
      "amountValueWoTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prime HT"])},
      "amountValueWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prime TTC"])}
    },
    "tabs": {
      "tiers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiers"])},
      "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
      "financing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financement"])},
      "history": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Historique"])},
      "documents": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents"])},
      "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message"])},
      "simulation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Simulation"])},
      "guarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guarantee"])},
      "study": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etude"])},
      "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande fournisseur"])}
    },
    "filter": {
      "creationDateFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date création du"])},
      "creationDateTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au"])}
    },
    "sliders": {
      "gracePeriodTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchise sur loyer"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualité"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
      "firstPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er Loyer"])},
      "annualRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
      "residualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])},
      "downPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport"])},
      "mileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilométrage"])},
      "presentation": {
        "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualité"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
        "firstPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er Loyer"])},
        "annualRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "residualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])},
        "downPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport"])},
        "mileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilémetrage"])}
      }
    },
    "product": {
      "services": {
        "panel": {
          "payment": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Ac Prestations / ", _interpolate(_named("duration"))])},
          "totalAmount": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Hs Prestation / ", _interpolate(_named("duration"))])}
        },
        "names": {
          "finance_ASSUR_DEC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance DEC"])},
          "finance_DIM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance DIM"])},
          "finance_FRAISDOS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais Dossier"])},
          "FRAIS_ASSISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance Fees"])},
          "FRAIS_MAINTENANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais Maintenance"])}
        },
        "columns": {
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
          "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de Calcul"])},
          "amountMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])},
          "amountHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant HT"])},
          "amountTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant TTC"])},
          "amountMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
          "qt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QT"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
          "annualRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux Annuel"])},
          "annualRateMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux annuel Min"])},
          "annualRateMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux annuel Max"])},
          "calculationMethodOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de calcul"])},
          "paymentDatesOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Paiement"])},
          "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])},
          "basisValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur de la base"])}
        },
        "duration": {
          "Upfront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FLAT"])},
          "Installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉCHEANCE"])}
        },
        "enum": {
          "basis": {
            "FinancedAssetGrossValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Matériel TTC"])},
            "FinancedAssetNetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Matériel HT"])},
            "FinancedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Financé"])}
          },
          "calculationMethodOptions": {
            "annualvalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Annuel"])},
            "fixedvalueperinstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Fixe Par Écheance"])}
          },
          "paymentDatesOptions": {
            "upfront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement flat"])},
            "installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement échelonné"])}
          }
        }
      },
      "messages": {
        "priceIsRequired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le prix soit être supérieur à 0"])}
      },
      "financingProduct": {
        "productLabel": {
          "ACTIVITY_CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CREDIT"])},
          "ACTIVITY_LLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLD"])},
          "ACTIVITY_LOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA"])},
          "ACTIVITY_RACC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACC"])},
          "ACTIVITY_RACHC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACHC"])},
          "ACTIVITY_RACI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACI"])},
          "finance_LOA_AUTO_ZEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA Auto Zen"])},
          "finance_RAC_CREADIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACC._SGAR_01"])},
          "finance_RAC_CREADIT_01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACC_SGAR_02"])},
          "finance_RAC_CREADIT_02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACC_SGAR_03"])},
          "finance_RAC_IMM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAC_IMM"])},
          "finance_LLD_CONFORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LLD Confort"])},
          "finance_PACK_LOA_AUTO_ZEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA Pack Auto Zen"])}
        }
      },
      "productLabel": {
        "finance.PACK_LOA_AUTO_ZEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LOA Auto Zen"])}
      }
    },
    "button": {
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler Demande"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrer Demande"])},
      "addTier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Tiers"])},
      "addAsset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Asset"])},
      "addFinancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un Financement"])},
      "expertMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Expert"])},
      "normalMode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mode Normal"])},
      "globalPaymentSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échéancier Global"])},
      "detailPaymentSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échéancier détaillé"])},
      "print": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimer offre"])},
      "addACriterion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un critère"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "addAdemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une demande"])},
      "pleaseChooseYourAsset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de choisir votre asset"])},
      "initWorkflow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialisation workflow"])}
    },
    "simulation": {
      "form": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à financer"])}
      },
      "bien": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant du bien"])}
      },
      "globalPaymentSchedule": {
        "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédant"])},
        "panel": {
          "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence dossier"])},
          "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
          "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Barème"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
          "downPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport"])},
          "residualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur Résiduelle"])},
          "residualValueAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur financée"])},
          "residualValueAmountWoTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur financée HT"])},
          "residualValueAmountWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur financée TTC"])},
          "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
          "durationUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
          "sumOfFinancialPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Loyer"])},
          "firstPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier loyer"])},
          "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux d’intérêt"])},
          "taeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEG"])},
          "taea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEA"])},
          "sumOfInterests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total intérêts"])},
          "sumOfServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total accessoires"])},
          "creditCost": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total intérêts + accessoires"])},
          "sumOfTotalPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût HT"])},
          "tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût TVA"])},
          "sumOfTotalPaymentsTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût TTC"])}
        },
        "table": {
          "stepNo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])},
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["type"])},
          "dueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Ech."])},
          "periodStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "periodEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Fin"])},
          "inProgress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours"])},
          "amortization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amortissement"])},
          "interest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intérêt"])},
          "financialInstallmentWOTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer HT"])},
          "taxes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA Loyer"])},
          "financialInstallmentWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer TTC"])},
          "servicesWOTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessoires HT"])},
          "tva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TVA Accessoires"])},
          "totalTva": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total TVA"])},
          "sumOfTotalPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total HT"])},
          "sumOfTotalPaymentsTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total TTC"])},
          "types": {
            "firstLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Déblocage"])},
            "defaultLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéance"])},
            "lastLine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])}
          }
        }
      },
      "tabs": {
        "financingConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions de financement"])},
        "services": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
        "servicesDetail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédant"])},
        "globalPaymentSchedule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Echéancier Global"])}
      },
      "invalidSlideValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur doit être comprise entre la plage max et min"])},
      "invalidResidualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La valeur résiduelle doit être inférieure au montant à financer"])}
    },
    "asset": {
      "usageItems": {
        "PRIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Privé"])},
        "PERSONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnelle"])},
        "PROFESSIONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnelle"])}
      },
      "totalPriceTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Véhicule TTC"])},
      "totalTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total TTC"])},
      "totalHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total HT"])},
      "selectProductLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche par marque, modèle & finition..."])},
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "bodywork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrosserie"])},
      "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])},
      "vehicleWeight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
      "génération": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Génération"])},
      "phase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Phase"])},
      "colors": {
        "RED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rouge"])},
        "BLACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noir"])},
        "GREEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vert"])},
        "WHITE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanc"])},
        "BLUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bleu"])}
      },
      "energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Énergie"])},
      "emission_co2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Émission CO2"])},
      "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])},
      "unit_price_ht_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Unitaire HT Après Remise"])},
      "unit_price_ht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Unitaire HT"])},
      "unit_price_ttc_after_discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix TTC"])},
      "desciption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
      "info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de choisir votre voiture parmi le catalogue"])},
      "equipement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de choisir votre équipement parmi le catalogue"])},
      "assetEquipement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de choisir votre asset parmi le catalogue"])},
      "assetListInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des assets"])},
      "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])},
      "OPTION_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OPTIONS"])},
      "ACCESSORY_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ACCESSOIRES"])},
      "OTHER_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais divers"])},
      "numberOfHours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d’heure"])},
      "vehicle": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VÉHICULE"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicule"])},
        "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
        "fournisseur": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
        "engine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finition"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dénomination"])},
        "bodywork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrosserie"])},
        "energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie"])},
        "power": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance"])},
        "powerUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CV"])},
        "lengthUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mm"])},
        "weightUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kg"])},
        "lengthkmUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["km"])},
        "emissionUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["g/Km"])},
        "emissionCO2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission co2"])},
        "gearBox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boite de vitesse"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])},
        "chassisReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num Chassis"])},
        "numberProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num Fabrication"])},
        "registrationNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Num immatriculation"])},
        "currentMileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’Edition Pv des mines"])},
        "registrationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’immatriculation"])},
        "taxe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])},
        "energyLabels": {
          "Diezel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diezel"])},
          "electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Électrique"])},
          "Gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Essence"])},
          "Hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])}
        }
      },
      "equipment": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EQUIPEMENT"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement"])},
        "version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "equipmentType": {
          "OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occasion"])},
          "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuve"])}
        }
      },
      "Residualvalue": {
        "min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min"])},
        "max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max"])},
        "default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par defaut"])}
      },
      "boat": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bateau"])},
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom du bateau"])},
        "weaponsSecurityEquipment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Matériel d’armement et sécurité"])},
        "weaponsSecurityEquipmentValue": {
          "BASIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basique"])},
          "COASTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotier"])},
          "OFFSHORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauturier"])},
          "MID-SHORE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Semi-Hauturier"])}
        },
        "designCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie de conception"])},
        "attachementPort": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Port d’attache"])},
        "flag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pavillon"])},
        "nbEngine": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de moteur"])},
        "orderSigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BDC signé"])},
        "orderSignedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de signature du BDC"])},
        "flagValue": {
          "EN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["French"])},
          "FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])}
        },
        "orderSignedSituation": {
          "yes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yes"])},
          "no": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No"])}
        },
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "priceTtc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["prix TTC"])},
        "coverage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couverture"])},
        "engineCharacteristics": {
          "engineBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque moteur"])},
          "engineConstructionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de construction moteur"])},
          "engineSerialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de série moteur"])},
          "enginePower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance moteur"])},
          "enginePowerDIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance DIN moteur"])}
        },
        "boatType": {
          "OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occasion"])},
          "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuf"])}
        }
      },
      "engineCharacteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques du moteur"])}
    },
    "documents": {
      "columns": {
        "justifyType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de justificatif "])},
        "documentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de document"])},
        "document": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document"])},
        "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réception"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])}
      }
    },
    "party": {
      "activitySector": {
        "CONSULTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consultant"])},
        "INVESTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investir"])}
      },
      "roles": {
        "GARANT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garant"])},
        "CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client"])},
        "COEMPRUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Co-emprunteur"])},
        "BANQUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
        "MGCMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société de gestion"])},
        "SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
        "NOTAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notaire"])},
        "ASSUREUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assureur"])},
        "VENDOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur"])},
        "DEALER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dealer"])},
        "EXPERT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expert"])},
        "INSURED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Insured"])},
        "DISTRIBUTOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apporteur"])},
        "PROSPECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propsect"])},
        "EMPLOYEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employee"])},
        "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
        "COLOCATAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Colocataire"])},
        "DIRG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigeant"])}
      },
      "statusMarital": {
        "MARRIED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marié(e)"])},
        "SINGLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Célibataire"])},
        "DIVORCED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Divorcé(e)/Séparé(e)"])},
        "PACSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pacsé(e)"])},
        "PENDING DIVORCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En instance de divorce"])},
        "WIDOW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuf(ve)"])},
        "CONCUBIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concubin(e), Commune"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
      },
      "country": {
        "FRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France"])},
        "PORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
        "BELG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgique"])},
        "ALLEM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allemagne"])},
        "ESP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espagne"])},
        "ITA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italie"])},
        "TUN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisie"])},
        "MAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroc"])}
      },
      "geographicZone": {
        "Corse": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Corse"])},
        "DOM_H": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOM (sauf Guyane et Mayotte)"])},
        "Dom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Guyane et Mayotte"])},
        "France_M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["France Metropolitaine"])}
      },
      "jobActivity": {
        "AGR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculture"])},
        "BAS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque/Assurance"])},
        "CMC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce"])},
        "AP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administartion publique"])},
        "ENS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement"])},
        "SAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Santé"])},
        "RESH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration/Hôtellerie"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
      },
      "occupationType": {
        "Test period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d'essai"])},
        "CDD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDD"])},
        "CDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CDI"])}
      },
      "positionType": {
        "IC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["independant-Commercant"])},
        "SPCD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur privé - Cadre dirigeant"])},
        "SPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur privé - employé"])},
        "SPC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur public - Cadre"])},
        "SPEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur public - Enseignant"])},
        "NAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non actif - Retraité"])},
        "NAE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non actif - Etudiant"])},
        "IPL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Independant-Profession libérale"])},
        "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
      },
      "legalCategory": {
        "c2e834b1-b5c2-4834-b560-416a04f232fb": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OrgLegal category 1"])}
      },
      "partyClass": {
        "BDF1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A1"])},
        "BDF1-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A1-"])},
        "BDF1+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A1+"])},
        "BDF2+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A2+"])},
        "BDF2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A2"])},
        "BDF2-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A2-"])},
        "BDF3+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A3+"])},
        "BDF3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A3"])},
        "BDF3-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A3-"])},
        "BDF4+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A4+"])},
        "BDF4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A4"])},
        "BDF4-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A4-"])},
        "BDF5+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A5+"])},
        "BDF5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A5"])},
        "BDF5-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A5-"])},
        "BDF6+": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A6+"])},
        "BDF6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A6"])},
        "BDF6-": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A6-"])},
        "BDF7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A7"])},
        "BDF8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A8"])},
        "BDFP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AP"])},
        "BDF0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A0"])}
      },
      "regimeMatrimonial": {
        "SB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séparation de bien"])},
        "CB": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté de bien"])},
        "CBRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté de bien réduit aux acquêts"])},
        "CONT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contractuel"])},
        "NONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun"])}
      },
      "title": {
        "Mme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mme"])},
        "M": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["M."])}
      },
      "addressPreferenceType": {
        "SIEGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siège Sociale"])},
        "LIVRAISON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
        "FACTURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])},
        "Delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
        "Invoicing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facturation"])}
      },
      "addressType": {
        "Residential": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Residentielle"])},
        "Commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerciale"])}
      },
      "type": {
        "person": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne physique"])},
        "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne morale"])}
      },
      "contactMechanism": {
        "emailcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EMAIL_CONTACT"])},
        "messengercontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MESSENGER_CONTACT"])},
        "phonecontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PHONE_CONTACT"])},
        "label": {
          "emailcontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "messengercontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
          "phonecontact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])}
        },
        "options": {
          "EMAIL_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "MESSENGER_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
          "PHONE_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])}
        }
      },
      "label": {
        "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
        "adressLine1Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 1"])},
        "adressLine2Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 2"])},
        "adressLine3Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 3"])},
        "statusMarital": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Marital"])}
      },
      "relationType": {
        "DIRG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est dirigeant de"])},
        "PRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Est président de"])}
      },
      "manager": {
        "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impossible de supprimer le dirigeant"])}
      },
      "managerType": {
        "DIRG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirigeant"])},
        "PRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Président"])}
      }
    },
    "elements": {
      "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "organization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisme"])},
      "credits": {
        "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet"])},
        "monthly_payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualité"])},
        "amount_to_repay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnt. à rembourser"])},
        "original_amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnt. d'origine"])},
        "taken_back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Repris"])},
        "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
        "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])}
      },
      "projects": {
        "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
        "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Seuls cinq projets sont autorisés."])}
      },
      "discovered": {
        "overdraft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvert bancaire"])},
        "treasury": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trésorerie"])},
        "bad_debits": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'impayés"])}
      }
    },
    "filtreMenu": {
      "filtres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "demandReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference demande"])},
      "clientReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference client"])},
      "statusDemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut demande"])},
      "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "contributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervenant"])},
      "companyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom société"])},
      "monthlyinstallement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de loyer"])},
      "numberofpaymentterms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nbr de termes"])},
      "whenModified": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de modification"])},
      "demandCreator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerciale/Agence"])},
      "SIREN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
      "listOfDemand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des demandes"])},
      "listOfThird": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des tiers"])},
      "listOfTask": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des taches"])},
      "externeReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference externe"])},
      "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nationalité"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "birthdate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de naissance"])},
      "zipCode_City": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal / ville de résidence"])},
      "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pays de résidence"])},
      "birthplacecity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ville de naissance"])},
      "filtresList": {
        "reseau_de_vente": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau de vente"])},
        "Type_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "Intervenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intervenants"])},
        "Canal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "Sous_Canal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous Canal"])},
        "Date_création_du/Au": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date création du/Au"])},
        "Modéle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modéle"])},
        "Immatriculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Immatriculation"])},
        "PostalCode_city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal / Ville de naissance"])},
        "Telephone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telephone"])}
      }
    }
  },
  "party": {
    "situation": {
      "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SITUATION"])},
      "maritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Marital"])},
      "matrimonialRegime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime matrimonial"])},
      "peopleInCharge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de personnes à charge"])},
      "numberOfChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'enfants"])},
      "homemakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes au foyer"])},
      "becameRetiredIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passage retraite le"])},
      "childDateOfBirth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance enfant"])},
      "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])}
    },
    "income": {
      "details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails"])}
    },
    "activity": {
      "consulting": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulting"])},
      "investing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement"])}
    },
    "APE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APE"])},
    "typeComunication": {
      "Phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
      "E-mail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-mail"])},
      "Messenger": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])}
    },
    "establishments": {
      "type": {
        "french": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Français"])},
        "tunisian": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunisien"])},
        "marocco": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maroc"])}
      }
    },
    "account": {
      "statut": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "blocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bloqué"])}
      },
      "type": {
        "deposit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôt"])},
        "cash": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Espèces"])}
      }
    },
    "role": {
      "new": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial"])},
      "activated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
      "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terminé"])}
    },
    "relation": {
      "relation": {
        "ascending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ascendant"])},
        "descending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Descendant"])}
      },
      "relationType": {
        "REP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REP"])},
        "MGCOMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MGCOMP"])},
        "DIRG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DIRG"])},
        "PRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PRES"])}
      }
    },
    "periodicity": {
      "MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuelle"])},
      "YEARLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuelle"])},
      "QUARTERLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrielle"])},
      "Mensuelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuelle"])}
    },
    "thirsFilters": {
      "customerStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "thirdReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference"])}
    }
  },
  "locale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fr"])},
  "mask": {
    "input": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["##/##/####"])},
    "format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY"])},
    "dateTime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DD/MM/YYYY HH:mm:ss"])},
    "formatWo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DDMMYYYY"])},
    "format_en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YYYY/MM/DD"])},
    "fullFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yyyy-MM-dd HH:mm:ss.SSSSSS"])}
  },
  "asset": {
    "vehicleType": {
      "vo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VO"])},
      "vn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VN"])},
      "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VN"])},
      "OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VO"])}
    },
    "good": {
      "vehicle": {
        "brand": {
          "AUDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDI"])},
          "GOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOLF"])},
          "HUYNDAI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUYNDAI"])}
        }
      }
    }
  },
  "decision": {
    "label": {
      "DECISION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
      "RESERVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserves"])},
      "decisionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de décision"])}
    },
    "status": {
      "ACCEPTED_RESERVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptée avec Reserves"])},
      "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptée"])},
      "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Refusée"])},
      "CUSTOMER_ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre Acceptée"])},
      "CUSTOMER_REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offre Refusée"])},
      "CREATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision en cours"])}
    },
    "generalInfo": {
      "COMMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "DECIDEUR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décideur"])},
      "PROFIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])}
    },
    "opinionTab": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVIS"])},
      "opinionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l’avis"])},
      "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
      "analysisReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference de l’analyse"])},
      "opinionMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décideur"])},
      "decisionOpinion": {
        "OP01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorable"])},
        "OP02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defavorable"])},
        "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "reserves": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserves"])},
        "addReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "reserveTable": {
          "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
          "reserveCombobox": {
            "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
          },
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "commentCombobox": {
            "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
          }
        }
      }
    },
    "analysisTab": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ANALYSE"])},
      "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "analysisMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyste"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "analysisDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'analyse"])},
      "analysisReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference de l’analyse"])},
      "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "successMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succès : l'analyse a été sauvegardée "])}
    },
    "decisionTab": {
      "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DECISION"])},
      "decisionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de décision"])},
      "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
      "analysisReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference de l’analyse"])},
      "decisionMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décideur"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])}
    },
    "analysisReferenceSidebar": {
      "AnalysisRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference de l’analyse :"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "filter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])}
    }
  },
  "opinion": {
    "enterOpinionTab": {
      "opinion": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
        "opinionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l’avis"])},
        "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
        "analysisReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference de l’analyse"])},
        "decisionOpinion": {
          "OP01": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorable"])},
          "OP02": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defavorable"])},
          "undefined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["-"])}
        },
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])}
      },
      "reserves": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserves"])},
        "addReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "reserveTable": {
          "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
          "reserveCombobox": {
            "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
          },
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "commentCombobox": {
            "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
          }
        }
      }
    },
    "enterDecisionTab": {
      "decision": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
        "decisionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de décision"])},
        "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
        "decisionReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif de la décision"])},
        "analysisReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de l’analyse"])},
        "decisionStatus": {
          "ACCEPTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision Acceptée"])},
          "REFUSED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision Refusée"])},
          "ACCEPTED_RESERVES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceptée avec réserves"])}
        },
        "decisionreasons": {
          "Reason_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux endettement, RAV"])},
          "Reason_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH trop élevé"])},
          "Reason_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation professionnelle hors critère"])},
          "Reason_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation personnelle hors critère"])},
          "Reason_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant, durée trop faible"])},
          "Reason_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client non satisfait"])},
          "Reason_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux non adapté"])},
          "Reason_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrence plus intéréssante"])}
        },
        "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])}
      },
      "reserves": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserves"])},
        "addReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "reserveTable": {
          "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
          "reserveCombobox": {
            "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
          },
          "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "commentCombobox": {
            "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
          }
        }
      }
    },
    "decisionTab": {
      "decision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décision"])},
      "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
      "opinionMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décideur"])},
      "profile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
      "opinionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l’avis"])},
      "opinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
      "analysisReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference de l’analyse"])},
      "reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve"])},
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "decisionReserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réserve de Décision"])},
      "decisionComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire de Décision"])},
      "delegationLevel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niveau de délégation"])},
      "decisionMaker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décideur"])}
    }
  },
  "incomeAndExpenses": {
    "label": {
      "incomeAndExpenses": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenus et charges"])}
    },
    "type": {
      "AUTRES_CHARGES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])},
      "APL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["APL"])},
      "SALAIRE_RET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenu après passage retraire"])},
      "SALAIRE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyenne salaire"])},
      "LOYER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer"])},
      "ALLOCATIONS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Allocations"])},
      "PENSION_RECUE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension alim. reçue"])},
      "FONCIERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenus fonciers"])},
      "PENSION_VERSEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pension alim. versée"])},
      "AUTRES_REVENUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres revenus"])}
    },
    "periodicity": {
      "MONTHLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuelle"])},
      "YEARLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuelle"])},
      "QUARTERLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrielle"])}
    }
  },
  "TaskList": {
    "TaskListFilter": {
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut demande"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference demande"])},
      "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom client"])},
      "roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roles Utilisateur"])},
      "groupes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes Utilisateur"])},
      "actionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date action du"])},
      "actionIssueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Au"])},
      "myAction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montrer mes actions uniquement"])},
      "externeReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference externe"])}
    }
  },
  "middleOffice": {
    "documents": {
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document requis/trouvé à ce stade!"])}
    },
    "precondition": {
      "precondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préconditions"])},
      "ListOfPreConditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List des préconditions"])},
      "PreconditionsToProcess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préconditions à traiter"])},
      "PreconditionsValid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préconditions validées"])},
      "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "NoData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun PreCondition est enregistré"])},
      "checkpoint": {
        "Status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "Label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
        "CreationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
        "CompletionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réalisation"])},
        "EditingUser ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur de modification"])}
      },
      "odm": {
        "supportingdocument": {
          "checkpoints": {
            "businessrulevalue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["checkpoints"])}
          }
        }
      },
      "checkpointStutus": {
        "PENDING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["non satisfait"])},
        "COMPLETED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["satisfait"])}
      },
      "preconditionStatus": {
        "Processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En traitement"])},
        "VALID": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide"])}
      },
      "label": {
        "LAB-CHKP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents requis"])},
        "LAB-CHKP2-Reserve": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
      }
    },
    "decision": {
      "decisionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de décision :"])},
      "enterYourDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrez votre décision :"])},
      "reasonForDecision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motif de la décision :"])},
      "profil": {
        "GRP_PROSPECT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client / prospect"])},
        "GRP_SALES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendeur partenaire"])},
        "GRP_ASS_COMM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assistance commerciale"])},
        "GRP_RISK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement"])},
        "GRP_RISK_LV1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement"])},
        "GRP_RISK_LV2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement"])},
        "GRP_RISK_LV3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement"])},
        "GRP_MO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Middle"])},
        "GRP_MANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Direction"])},
        "GRP_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
        "GRP_SALESMANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable vendeur partenaire"])},
        "GRP_RISKMANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable engagement"])},
        "GRP_MOMANAGER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable middle"])},
        "GRP_SUPERADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrateur"])},
        "_commentRelatedToGRP_Finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["I don't have the mapping for this GRP"])},
        "GRP_Finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRP_Finance"])}
      },
      "reasons": {
        "Reason_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux endettement, RAV"])},
        "Reason_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RH trop élevé"])},
        "Reason_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation professionnelle hors critère"])},
        "Reason_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation personnelle hors critère"])},
        "Reason_5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant, durée trop faible"])},
        "Reason_6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Client non satisfait"])},
        "Reason_7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux non adapté"])},
        "Reason_8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concurrence plus intéréssante"])}
      },
      "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
      "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune décision enregistrée pour cette offre"])},
      "emptyReason": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun motif"])}
    },
    "generalInfo": {
      "label": {
        "demandRef": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf de la demande"])},
        "demandStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut demande"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
        "refClient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf du client"])},
        "assignedSalesPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commercial"])},
        "assignedSalesPersonNetwork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau de vente"])},
        "associatedNetworks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "associatedNetworkNodes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous Canal"])},
        "distributor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaire"])}
      },
      "progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AVANCEMENT"])}
    },
    "party": {
      "person": {
        "section": {
          "raccourcie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACCOURCI"])},
          "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDENTITÉ"])},
          "meansOfCommunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOYENS DE COMMUNICATION"])},
          "establishments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉTABLISSEMENTS"])},
          "situation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SITUATION"])},
          "occupation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PROFESSION"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADRESSE"])},
          "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
          "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DONNÉES BANCAIRES"])},
          "financialSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REVENUS ET CHARGES"])},
          "expossure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours Client"])},
          "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÉTAILS"])},
          "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLASSIFICATION"])},
          "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROLE"])},
          "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RELATION"])},
          "income": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenus mensuels"])},
          "expense": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charges mensuelles"])},
          "exposure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EXPOSURE"])},
          "identityDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCUMENTS D’IDENTITÉ"])}
        },
        "identity": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
          "usageName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d`usage"])},
          "familyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
          "birthDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
          "age": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])},
          "nationality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
          "birthplace": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code Postal / Ville de naissance"])},
          "birthName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de naissance"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
          "genderLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Genre"])},
          "bornCountry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de naissance"])},
          "gender": {
            "MALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Homme"])},
            "FEMALE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Femme"])}
          }
        },
        "situation": {
          "MaritalStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut Marital"])},
          "matrimonialRegime": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime matrimonial"])},
          "nbOfDependent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de personnes à charge"])},
          "nbOfChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'enfants"])},
          "homemakers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personnes au foyer"])},
          "retirementPassage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passage retraite le"])},
          "dateOfBirthChildren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance enfant"])},
          "childrenAge": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Age"])}
        },
        "meansOfCommunication": {
          "contactMechanism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
          "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
          "nonSolicitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sollicitation"])}
        },
        "occupation": {
          "employerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'employeur"])},
          "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d'activité"])},
          "principal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employeur principal"])},
          "occupationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de contrat"])},
          "positionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
          "nbHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'heures"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'entrée"])},
          "tenureDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de titularisation"])},
          "employerCreationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancienneté employeur"])},
          "occupationTypes": {
            "PE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période d’essai "])},
            "CDD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat de travail à durée déterminée"])},
            "CDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrat de travail à durée indéterminée "])}
          }
        },
        "financialSituation": {
          "analysisDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'analyse"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
          "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fermer"])},
          "periodicity": {
            "MONTHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuelle"])},
            "YEARLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuelle"])},
            "QUARTERLY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trimestrielle"])},
            "Mensuelle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensuelle"])}
          }
        },
        "address": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type adresse"])},
          "postalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
          "streetNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])},
          "propertystatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Property Status"])},
          "livingstatus": {
            "PROPERTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriété"])},
            "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
          },
          "propertyStatusValues": {
            "PROPERTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriété"])},
            "RENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location"])},
            "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
          }
        },
        "exposure": {
          "Outstanding_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entité Porteuse de l’Encours"])},
          "Outstanding_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'encours"])},
          "gross_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours Brut"])},
          "net_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours Net"])},
          "residual_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])},
          "effective_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de calcul de l'encours"])},
          "contract_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference du Contrat"])},
          "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début du contrat"])},
          "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin du Contrat"])},
          "share_financing_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QP Financement"])},
          "share_risk_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QP Risque"])}
        },
        "identityDoc": {
          "documentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de document"])},
          "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
          "issuer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emetteur"])},
          "issueDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’emission"])},
          "expirationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’expiration"])},
          "docTypes": {
            "DRIVINGLICENCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Permis de conduite"])},
            "CNI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carte nationale d’identité"])},
            "PASSPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passeport"])}
          }
        },
        "documents": {
          "documenttype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de document"])}
        },
        "financialInstitution": {
          "BANK_CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
          "GROUP_CEMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres banques"])}
        },
        "typeOutstanding": {
          "OFF_BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors bilan"])},
          "ON_BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
        }
      },
      "organization": {
        "section": {
          "raccourcie": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RACCOURCI"])},
          "identity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDENTITÉ"])},
          "meansOfCommunication": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MOYENS DE COMMUNICATION"])},
          "establishments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÉTABLISSEMENTS"])},
          "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADRESSE"])},
          "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONTACT"])},
          "account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DONNÉES BANCAIRES"])},
          "financialSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SITUATION FINANCIERE"])},
          "expossure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours Client"])},
          "detail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÉTAILS"])},
          "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLASSIFICATION"])},
          "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ROLE"])},
          "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RELATION"])},
          "exposure": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENCOURS CLIENT"])}
        },
        "identity": {
          "siren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siren"])},
          "commercialName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom commercial"])},
          "legalCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie juridique"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "activitySector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
          "acronym": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acronyme"])},
          "commercialRegister": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro du registre de commerce"])},
          "isCreated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise Crée"])},
          "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
          "apeCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code APE"])},
          "naceCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code NACE"])},
          "commercialRegisterNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro du registre de commerce"])},
          "customerType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clientèle"])},
          "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activitè de l'entreprise"])},
          "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nom de la banque "])}
        },
        "meansOfCommunication": {
          "contactMechanism": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "emailAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
          "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
          "identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messenger"])},
          "nonSolicitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sollicitation"])}
        },
        "establishments": {
          "siret": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIRET"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
          "address": {
            "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type adresse"])},
            "codePostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
            "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
            "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
            "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])}
          },
          "types": {
            "AG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agence"])},
            "AUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])}
          }
        },
        "contact": {
          "familyName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
          "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
          "qualificationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonction"])},
          "signingAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pouvoir de signature"])},
          "hasSigningAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
          "hasNotSigningAuthority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])},
          "fonction": {
            "CAC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAC"])},
            "CEO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CEO"])},
            "CPT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comptable"])},
            "CTO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CTO"])},
            "DG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DG"])},
            "TRES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trésorier"])}
          }
        },
        "address": {
          "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type adresse"])},
          "codePostal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code postal"])},
          "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
          "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rue"])},
          "streetNum": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N°"])},
          "line1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 1"])},
          "line2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 2"])},
          "line3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse N° 3"])}
        },
        "account": {
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
          "iban": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IBAN"])},
          "bic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BIC"])},
          "bankAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de compte"])},
          "bankName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de la banque"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
          "preferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préféré"])},
          "isPreferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oui"])},
          "isNotPreferred": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non"])}
        },
        "financialSituation": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
          "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
          "supportingDocument": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justificatif"])},
          "nature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur"])}
        },
        "classification": {
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
          "partyClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation"])}
        },
        "role": {
          "role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
          "nonSolicitation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non sollicitation"])}
        },
        "relation": {
          "relation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Relation"])},
          "relationType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type relation"])},
          "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiers"])},
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
          "sharingRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage de partage"])}
        },
        "exposure": {
          "Outstanding_entity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entité Porteuse de l’Encours"])},
          "Outstanding_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'encours"])},
          "gross_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours Brut"])},
          "net_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours Net"])},
          "residual_value": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])},
          "effective_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de calcul de l'encours"])},
          "contract_reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réference du Contrat"])},
          "start_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Début du contrat"])},
          "end_date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fin du Contrat"])},
          "share_financing_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QP Financement"])},
          "share_risk_pool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["QP Risque"])},
          "total_gross_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total encours brut"])},
          "total_net_outstanding": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total encours net"])}
        },
        "financialInstitution": {
          "BANK_CF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Banque"])},
          "GROUP_CEMP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres banques"])}
        },
        "typeOutstanding": {
          "OFF_BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors bilan"])},
          "ON_BS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bilan"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre"])}
        }
      }
    },
    "asset": {
      "totalAsset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL ASSET"])},
      "totalHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL HT"])},
      "totalTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TOTAL TTC"])}
    }
  },
  "sendFinancingRequest": {
    "columns": {
      "label": {
        "actionName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'action"])},
        "actionUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
        "actionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de l'action"])}
      }
    },
    "comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])}
  },
  "middleOfficeService": {
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "amountWoTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant HT"])},
    "amountWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant TTC"])},
    "columns": {
      "label": {
        "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux annuel"])},
        "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base de calcul"])},
        "basisValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur de base"])},
        "proposalAccessoryCalculationMethodOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de calcul"])},
        "proposalAccessoryPaymentDatesOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de paiement"])},
        "taxValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])}
      }
    },
    "proposalAccessoryCalculationMethodOptionValue": {
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "FixedValuePerInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mnt fixe par échéance"])},
      "AnnualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant annuel"])}
    },
    "proposalAccessoryPaymentDatesOptionValue": {
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "Upfront": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial"])},
      "Installment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée de financement"])}
    },
    "basisValue": {
      "null": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])},
      "FinancedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant de financement"])}
    }
  },
  "middleOfficeFinancingForm": {
    "label": {
      "financingProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit Financier"])},
      "financedValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à financer"])},
      "horsPrestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hors Prestation"])},
      "avecPrestation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec Prestation"])},
      "financialPaymentWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualité TTC"])},
      "financialPaymentWoTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualité HT"])},
      "numberOfPaymentTerm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
      "nominalRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
      "firstPaymentWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er loyer"])},
      "downPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport"])},
      "mileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kilométrage"])},
      "totalAmountWInsuranceTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout total TTC avec prestation  "])},
      "totalAmountWoInsuranceHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout total HT avec prestation  "])},
      "sumOfFinancialPaymentsWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout total TTC sans prestation  "])},
      "sumOfFinancialPaymentsWoTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout total HT sans prestation  "])},
      "sumOfTotalPaymentsWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout total TTC avec prestation "])},
      "sumOfTotalPaymentsWoTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cout total HT avec prestation "])},
      "sansAquisition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sans aquisition"])},
      "avecAquisition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avec aquisition"])},
      "residualValueAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur Résiduelle"])},
      "totalInstallmentWTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["totalInstallmentWTax "])}
    },
    "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])}
  },
  "middleOfficeFinancingService": {
    "label": {
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SERVICES"])},
      "outOfService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hs prestation"])},
      "over": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sur"])},
      "month": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
      "PerMonth": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["/Mois"])},
      "withOutTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HT"])},
      "withTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TTC"])},
      "gracePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Franchise sur loyer"])}
    }
  },
  "middleOfficeFinancingSituation": {
    "label": {
      "sumOfInterests": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coût total intérêt"])},
      "sumOfTotalPayments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total à rembourser"])},
      "aprc": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEG"])},
      "air": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEA"])},
      "debitBefor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endettement Avant"])},
      "debitAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endettement Aprés"])},
      "ravBefor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAV Avant"])},
      "ravAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RAV Aprés"])}
    },
    "financingSituation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SITUATION FINANCIÈRE"])}
  },
  "summaryPanel": {
    "banner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse de L'Offre"])},
    "customer": {
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "maritalState": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Situation Maritale"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "citizenship": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléphone"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur d'activité"])},
      "quoteBDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation BDF"])}
    },
    "organization": {
      "corporateName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raison sociale"])},
      "commercialName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom Commercial"])},
      "legalCategory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie Juridique"])},
      "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
      "siren": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SIREN"])},
      "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Teléphone"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titre"])},
      "fullName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom & Prénom"])},
      "birthday": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
      "quoteBDF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cotation BDF"])},
      "moodysNote": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notation Moody's"])}
    },
    "asset": {
      "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
      "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "marque": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
      "quantite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
      "totalFrais": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total Frais"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessoires"])},
      "fairsDivers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais divers"])},
      "priceTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix TTC"])}
    },
    "financing": {
      "activityType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’activité"])},
      "financingType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de financement"])},
      "lease": {
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loyer"])},
        "amountRefunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total à rembourser"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
        "durationUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
        "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "residualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])},
        "firstPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er Loyer"])},
        "gracePeriodTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grâce"])},
        "gracePeriodTermsType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de grâce"])},
        "gracePeriodTermsTax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de la grâce"])}
      },
      "loan": {
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
        "monthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mensualité"])},
        "amountRefunded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total à rembourser"])},
        "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
        "durationUnit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
        "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
        "residualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])},
        "firstPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["1er Loyer"])},
        "downPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport"])},
        "taeg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEG"])},
        "taea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAEA"])}
      },
      "service": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des services"])}
      }
    }
  },
  "userPreference": {
    "avatar": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avatar"])},
    "userPreference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préférences de l’utilisateur"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "LanguagesAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langues disponibles"])},
    "PreferredLanguage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue préférée"])},
    "saveChanges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder les modifications"])},
    "languageHint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner une langue préférée, y compris la date, l'heure et le formatage des nombres."])},
    "blockedSave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous devez valider le changement de mot de passe avant de continuer."])}
  },
  "communication": {
    "comMessage": {
      "newMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau message"])},
      "importanTag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["important"])},
      "subjectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sujet"])},
      "referenceLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
      "recipientLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataire"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "sendBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "labelSearchRefernce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez une référence ici ..."])},
      "labelSearchUserG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tapez un destinataire ici ..."])},
      "waitSendMessageLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoi du message, Veuillez patienter..."])},
      "Offer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demande"])},
      "Party": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tiers"])},
      "Group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupe"])},
      "User": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur"])},
      "All": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout"])},
      "missingField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce champ est obligatoire "])},
      "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a envoyé "])},
      "response": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a répondu "])},
      "tansfert": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["a transféré "])},
      "sendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["un message à "])},
      "responseMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["au message "])},
      "tansfertMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["le message à "])}
    },
    "comNotification": {
      "Notifications": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notifications"])},
      "deleteBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
      "transferBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transfert"])},
      "respondBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Répondre"])},
      "cancelBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "sendBtn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer"])},
      "showMore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En savoir plus..."])},
      "showLess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins de détails..."])},
      "selectLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez un ou plusieurs destinataires"])},
      "valueOfRecipient": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur du destinataire sélectionné"])},
      "monthlyMonthly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il s'agit de toutes vos notifications des 30 derniers jours"])},
      "alertFailedSendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échec de l'envoi du message"])},
      "alertSuccessSendMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message envoyé avec succès"])},
      "messageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message envoyé avec succès"])},
      "messageForwarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message transféré avec succès"])},
      "failedMessageSent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message non envoyé"])},
      "failedMessageForwarded": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Message non transféré"])},
      "searchField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherché por Sujet, Emetteur, Reférence..."])},
      "menu": {
        "sortByDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier por date"])},
        "sortByImportance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier por importance"])},
        "sortByUnread": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trier por non lue"])}
      },
      "buttons": {
        "ALL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
        "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non Lus"])},
        "READ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lus"])}
      }
    }
  },
  "Analysis": {
    "Avis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Avis"])},
    "Comment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
    "opinion": {
      "APPROVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Favorable"])},
      "REJECTED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défavorable"])}
    },
    "errors": {
      "algo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, cette section est actuellement indisponible en raison de problèmes techniques avec nos services. Nous travaillons dur pour résoudre le problème et vous remercions de votre patience."])},
      "noOpinion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Merci de saisir un avis"])}
    }
  },
  "picklists": {
    "legal-category": {
      "2400": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fiducie"])},
      "2900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre groupement de droit privé non doté de la personnalité morale"])},
      "3110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Représentation ou agence commerciale d'état ou organisme public étranger immatriculé au RCS"])},
      "3120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société commerciale étrangère immatriculée au RCS"])},
      "3205": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation internationale"])},
      "3210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État, collectivité ou établissement public étranger"])},
      "3220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société étrangère non immatriculée au RCS"])},
      "3290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre personne morale de droit étranger"])},
      "4110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public national à caractère industriel ou commercial doté d'un comptable public"])},
      "4120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public national à caractère industriel ou commercial non doté d'un comptable public"])},
      "4130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitant public"])},
      "4140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public local à caractère industriel ou commercial"])},
      "4150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régie d'une collectivité locale à caractère industriel ou commercial"])},
      "4160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution Banque de France"])},
      "5191": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société de caution mutuelle"])},
      "5192": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société coopérative de banque populaire"])},
      "5193": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse de crédit maritime mutuel"])},
      "5194": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse (fédérale) de crédit mutuel"])},
      "5195": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association coopérative inscrite (droit local Alsace Moselle)"])},
      "5196": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse d'épargne et de prévoyance à forme coopérative"])},
      "5202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en nom collectif"])},
      "5203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en nom collectif coopérative"])},
      "5306": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en commandite simple"])},
      "5307": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en commandite simple coopérative"])},
      "5308": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en commandite par actions"])},
      "5309": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en commandite par actions coopérative"])},
      "5310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en libre partenariat (SLP)"])},
      "5370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société de Participations Financières de Profession Libérale Société en commandite par actions (SPFPL SCA)"])},
      "5385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société d'exercice libéral en commandite par actions"])},
      "5410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL nationale"])},
      "5415": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL d'économie mixte"])},
      "5422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL immobilière pour le commerce et l'industrie (SICOMI)"])},
      "5426": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL immobilière de gestion"])},
      "5430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL d'aménagement foncier et d'équipement rural (SAFER)"])},
      "5431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL mixte d'intérêt agricole (SMIA)"])},
      "5432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL d'intérêt collectif agricole (SICA)"])},
      "5442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL d'attribution"])},
      "5443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL coopérative de construction"])},
      "5451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL coopérative de consommation"])},
      "5453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL coopérative artisanale"])},
      "5454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL coopérative d'intérêt maritime"])},
      "5455": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL coopérative de transport"])},
      "5458": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL coopérative de production (SCOP)"])},
      "5459": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SARL union de sociétés coopératives"])},
      "5460": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre SARL coopérative"])},
      "5470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société de Participations Financières de Profession Libérale Société à responsabilité limitée (SPFPL SARL)"])},
      "5485": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société d'exercice libéral à responsabilité limitée"])},
      "5499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société à responsabilité limitée (sans autre indication)"])},
      "5505": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA à participation ouvrière à conseil d'administration"])},
      "5510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA nationale à conseil d'administration"])},
      "5515": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA d'économie mixte à conseil d'administration"])},
      "5520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds à forme sociétale à conseil d'administration"])},
      "5522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA immobilière pour le commerce et l'industrie (SICOMI) à conseil d'administration"])},
      "5525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA immobilière d'investissement à conseil d'administration"])},
      "5530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA d'aménagement foncier et d'équipement rural (SAFER) à conseil d'administration"])},
      "5531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société anonyme mixte d'intérêt agricole (SMIA) à conseil d'administration"])},
      "5532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA d'intérêt collectif agricole (SICA) à conseil d'administration"])},
      "5542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA d'attribution à conseil d'administration"])},
      "5543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de construction à conseil d'administration"])},
      "5546": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA de HLM à conseil d'administration"])},
      "5547": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de production de HLM à conseil d'administration"])},
      "5548": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA de crédit immobilier à conseil d'administration"])},
      "5551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de consommation à conseil d'administration"])},
      "5552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de commerçants-détaillants à conseil d'administration"])},
      "5553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative artisanale à conseil d'administration"])},
      "5554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative (d'intérêt) maritime à conseil d'administration"])},
      "5555": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de transport à conseil d'administration"])},
      "5558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de production  (SCOP) à conseil d'administration"])},
      "5559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA union de sociétés coopératives à conseil d'administration"])},
      "5560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre SA coopérative à conseil d'administration"])},
      "5570": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société de Participations Financières de Profession Libérale Société anonyme à conseil d'administration (SPFPL SA à conseil d'administration)"])},
      "5585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société d'exercice libéral à forme anonyme à conseil d'administration"])},
      "5599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA à conseil d'administration (s.a.i.)"])},
      "5605": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA à participation ouvrière à directoire"])},
      "5610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA nationale à directoire"])},
      "5615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA d'économie mixte à directoire"])},
      "5620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds à forme sociétale à directoire"])},
      "5622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA immobilière pour le commerce et l'industrie (SICOMI) à directoire"])},
      "5625": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA immobilière d'investissement à directoire"])},
      "5630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Safer anonyme à directoire"])},
      "5631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA mixte d'intérêt agricole (SMIA)"])},
      "5632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA d'intérêt collectif agricole (SICA)"])},
      "5642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA d'attribution à directoire"])},
      "5643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de construction à directoire"])},
      "5646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA de HLM à directoire"])},
      "5647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société coopérative de production de HLM anonyme à directoire"])},
      "5648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA de crédit immobilier à directoire"])},
      "5651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de consommation à directoire"])},
      "5652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de commerçants-détaillants à directoire"])},
      "5653": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative artisanale à directoire"])},
      "5654": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative d'intérêt maritime à directoire"])},
      "5655": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de transport à directoire"])},
      "5658": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA coopérative de production (SCOP) à directoire"])},
      "5659": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA union de sociétés coopératives à directoire"])},
      "5660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre SA coopérative à directoire"])},
      "5670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société de Participations Financières de Profession Libérale Société anonyme à Directoire (SPFPL SA à directoire)"])},
      "5685": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société d'exercice libéral à forme anonyme à directoire"])},
      "5699": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SA à directoire (s.a.i.)"])},
      "5710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SAS, société par actions simplifiée"])},
      "5770": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société de Participations Financières de Profession Libérale Société par actions simplifiée (SPFPL SAS)"])},
      "5785": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société d'exercice libéral par action simplifiée"])},
      "5800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société européenne"])},
      "6100": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse d'Épargne et de Prévoyance"])},
      "6210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement européen d'intérêt économique (GEIE)"])},
      "6220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement d'intérêt économique (GIE)"])},
      "6316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coopérative d'utilisation de matériel agricole en commun (CUMA)"])},
      "6317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société coopérative agricole"])},
      "6318": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Union de sociétés coopératives agricoles"])},
      "6411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société d'assurance à forme mutuelle"])},
      "6511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sociétés Interprofessionnelles de Soins Ambulatoires "])},
      "6521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile de placement collectif immobilier (SCPI)"])},
      "6532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile d'intérêt collectif agricole (SICA)"])},
      "6533": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement agricole d'exploitation en commun (GAEC)"])},
      "6534": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement foncier agricole"])},
      "6535": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement agricole foncier"])},
      "6536": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement forestier"])},
      "6537": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement pastoral"])},
      "6538": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement foncier et rural"])},
      "6539": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile foncière"])},
      "6540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile immobilière"])},
      "6541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile immobilière de construction-vente"])},
      "6542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile d'attribution"])},
      "6543": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile coopérative de construction"])},
      "6544": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile immobilière d' accession progressive à la propriété"])},
      "6551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile coopérative de consommation"])},
      "6554": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile coopérative d'intérêt maritime"])},
      "6558": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile coopérative entre médecins"])},
      "6560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre société civile coopérative"])},
      "6561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP d'avocats"])},
      "6562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP d'avocats aux conseils"])},
      "6563": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP d'avoués d'appel"])},
      "6564": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP d'huissiers"])},
      "6565": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de notaires"])},
      "6566": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de commissaires-priseurs"])},
      "6567": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de greffiers de tribunal de commerce"])},
      "6568": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de conseils juridiques"])},
      "6569": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de commissaires aux comptes"])},
      "6571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de médecins"])},
      "6572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de dentistes"])},
      "6573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP d'infirmiers"])},
      "6574": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de masseurs-kinésithérapeutes"])},
      "6575": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de directeurs de laboratoire d'analyse médicale"])},
      "6576": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de vétérinaires"])},
      "6577": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP de géomètres experts"])},
      "6578": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SCP d'architectes"])},
      "6585": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre société civile professionnelle"])},
      "6589": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile de moyens"])},
      "6595": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse locale de crédit mutuel"])},
      "6596": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse de crédit agricole mutuel"])},
      "6597": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société civile d'exploitation agricole"])},
      "6598": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitation agricole à responsabilité limitée"])},
      "6599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre société civile"])},
      "6901": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre personne de droit privé inscrite au registre du commerce et des sociétés"])},
      "7111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorité constitutionnelle"])},
      "7112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorité administrative ou publique indépendante"])},
      "7113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ministère"])},
      "7120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service central d'un ministère"])},
      "7150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service du ministère de la Défense"])},
      "7160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service déconcentré à compétence nationale d'un ministère (hors Défense)"])},
      "7171": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service déconcentré de l'État à compétence (inter) régionale"])},
      "7172": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service déconcentré de l'État à compétence (inter) départementale"])},
      "7179": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Autre) Service déconcentré de l'État à compétence territoriale"])},
      "7190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecole nationale non dotée de la personnalité morale"])},
      "7210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commune et commune nouvelle"])},
      "7220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Département"])},
      "7225": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collectivité et territoire d'Outre Mer"])},
      "7229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Autre) Collectivité territoriale"])},
      "7230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Région"])},
      "7312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commune associée et commune déléguée"])},
      "7313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Section de commune"])},
      "7314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ensemble urbain"])},
      "7321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association syndicale autorisée"])},
      "7322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association foncière urbaine"])},
      "7323": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association foncière de remembrement"])},
      "7331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public local d'enseignement"])},
      "7340": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pôle métropolitain"])},
      "1000": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entrepreneur individuel"])},
      "2110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indivision entre personnes physiques"])},
      "2120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indivision avec personne morale"])},
      "2210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société créée de fait entre personnes physiques"])},
      "2220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société créée de fait avec personne morale"])},
      "2310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en participation entre personnes physiques"])},
      "2320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en participation avec personne morale"])},
      "2385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Société en participation de professions libérales"])},
      "2700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paroisse hors zone concordataire"])},
      "2800": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assujetti unique à la TVA"])},
      "7341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Secteur de commune"])},
      "7342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["District urbain"])},
      "7343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté urbaine"])},
      "7344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métropole"])},
      "7345": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat intercommunal à vocation multiple (SIVOM)"])},
      "7346": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté de communes"])},
      "7347": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté de villes"])},
      "7348": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communauté d'agglomération"])},
      "7349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre établissement public local de coopération non spécialisé ou entente"])},
      "7351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution interdépartementale ou entente"])},
      "7352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution interrégionale ou entente"])},
      "7353": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat intercommunal à vocation unique (SIVU)"])},
      "7354": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat mixte fermé"])},
      "7355": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat mixte ouvert"])},
      "7356": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission syndicale pour la gestion des biens indivis des communes"])},
      "7357": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pôle d'équilibre territorial et rural (PETR)"])},
      "7361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre communal d'action sociale"])},
      "7362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse des écoles"])},
      "7363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse de crédit municipal"])},
      "7364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement d'hospitalisation"])},
      "7365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat inter hospitalier"])},
      "7366": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public local social et médico-social"])},
      "7367": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre Intercommunal d'action sociale (CIAS)"])},
      "7371": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Office public d'habitation à loyer modéré (OPHLM)"])},
      "7372": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service départemental d'incendie et de secours (SDIS)"])},
      "7373": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public local culturel"])},
      "7378": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régie d'une collectivité locale à caractère administratif"])},
      "7379": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(Autre) Établissement public administratif local"])},
      "7381": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisme consulaire"])},
      "7382": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public national  fonction d'administration centrale"])},
      "7383": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public national à caractère scientifique culturel et professionnel"])},
      "7384": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre établissement public national d'enseignement"])},
      "7385": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre établissement public national administratif à compétence territoriale limitée"])},
      "7389": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public national à caractère administratif"])},
      "7410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement d'intérêt public (GIP)"])},
      "7430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Établissement public des cultes d'Alsace-Lorraine"])},
      "7450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etablissement public administratif, cercle et foyer dans les armées"])},
      "7470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement de coopération sanitaire à gestion publique"])},
      "7490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre personne morale de droit administratif"])},
      "8110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime général de la Sécurité Sociale"])},
      "8120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime spécial de Sécurité Sociale"])},
      "8130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution de retraite complémentaire"])},
      "8140": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutualité sociale agricole"])},
      "8150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime maladie des non-salariés non agricoles"])},
      "8160": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime vieillesse ne dépendant pas du régime général de la Sécurité Sociale"])},
      "8170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régime d'assurance chômage"])},
      "8190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre régime de prévoyance sociale"])},
      "8210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mutuelle"])},
      "8250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance mutuelle agricole"])},
      "8290": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre organisme mutualiste"])},
      "8310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité social économique d’entreprise"])},
      "8311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité social économique d'établissement"])},
      "8410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat de salariés"])},
      "8420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat patronal"])},
      "8450": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordre professionnel ou assimilé"])},
      "8470": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centre technique industriel ou comité professionnel du développement économique"])},
      "8490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre organisme professionnel"])},
      "8510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution de prévoyance"])},
      "8520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Institution de retraite supplémentaire"])},
      "9110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Syndicat de copropriété"])},
      "9150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association syndicale libre"])},
      "9210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association non déclarée"])},
      "9220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association déclarée"])},
      "9221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association déclarée d'insertion par l'économique"])},
      "9222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association intermédiaire"])},
      "9223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement d'employeurs"])},
      "9224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association d'avocats à responsabilité professionnelle individuelle"])},
      "9230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association déclarée, reconnue d'utilité publique"])},
      "9240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congrégation"])},
      "9260": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Association de droit local (Bas-Rhin, Haut-Rhin et Moselle)"])},
      "9300": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fondation"])},
      "9900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre personne morale de droit privé"])},
      "9970": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupement de coopération sanitaire à gestion privée"])}
    },
    "activity": {
      "CONSULTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil"])},
      "INVESTING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Investissement"])},
      "0111Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de céréales (à l exception du riz), de légumineuses et de graines oléagineuses"])},
      "0112Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture du riz"])},
      "0113Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de légumes, de melons, de racines et de tubercules"])},
      "0114Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de la canne à sucre"])},
      "0115Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture du tabac"])},
      "0116Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de plantes à fibres"])},
      "0119Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres cultures non permanentes"])},
      "0121Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de la vigne"])},
      "0122Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de fruits tropicaux et subtropicaux"])},
      "0123Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture d agrumes"])},
      "0124Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de fruits à pépins et à noyau"])},
      "0125Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture d autres fruits d arbres ou d arbustes et de fruits à coque"])},
      "0126Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de fruits oléagineux"])},
      "0127Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de plantes à boissons"])},
      "0128Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques"])},
      "0129Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres cultures permanentes"])},
      "0130Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproduction de plantes"])},
      "0141Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de vaches laitières"])},
      "0142Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage d autres bovins et de buffles"])},
      "0143Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de chevaux et d autres équidés"])},
      "0144Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de chameaux et d autres camélidés"])},
      "0145Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage d ovins et de caprins"])},
      "0146Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de porcins"])},
      "0147Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de volailles"])},
      "0149Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage d autres animaux"])},
      "0150Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture et élevage associés"])},
      "0161Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien aux cultures"])},
      "0162Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien à la production animale"])},
      "0163Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement primaire des récoltes"])},
      "0164Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement des semences"])},
      "0170Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chasse, piégeage et services annexes"])},
      "0210Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sylviculture et autres activités forestières"])},
      "0220Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitation forestière"])},
      "0230Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récolte de produits forestiers non ligneux poussant à l état sauvage"])},
      "0240Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de soutien à l exploitation forestière"])},
      "0311Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pêche en mer"])},
      "0312Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pêche en eau douce"])},
      "0321Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquaculture en mer"])},
      "0322Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquaculture en eau douce"])},
      "0510Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de houille"])},
      "0610Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de pétrole brut"])},
      "0620Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de gaz naturel"])},
      "0729Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction d autres minerais de métaux non ferreux"])},
      "0811Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d ardoise"])},
      "0812Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitation de gravières et sablières, extraction d argiles et de kaolin"])},
      "0891Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction des minéraux chimiques et d engrais minéraux"])},
      "0892Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de tourbe"])},
      "0893Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de sel"])},
      "0899Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités extractives n.c.a."])},
      "0910Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien à l extraction d hydrocarbures"])},
      "0990Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien aux autres industries extractives"])},
      "1011Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de la viande de boucherie"])},
      "1012Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de la viande de volaille"])},
      "1013A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation industrielle de produits à base de viande"])},
      "1013B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charcuterie"])},
      "1020Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de poisson, de crustacés et de mollusques"])},
      "1031Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de pommes de terre"])},
      "1032Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation de jus de fruits et légumes"])},
      "1039A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre transformation et conservation de légumes"])},
      "1039B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de fruits"])},
      "1041A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d huiles et graisses brutes"])},
      "1041B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d huiles et graisses raffinées"])},
      "1042Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de margarine et graisses comestibles similaires"])},
      "1051A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de lait liquide et de produits frais"])},
      "1051B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de beurre"])},
      "1051C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fromage"])},
      "1051D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits laitiers"])},
      "1052Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de glaces et sorbets"])},
      "1061A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meunerie"])},
      "1061B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités du travail des grains"])},
      "1062Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits amylacés"])},
      "1071A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication industrielle de pain et de pâtisserie fraîche"])},
      "1071B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuisson de produits de boulangerie"])},
      "1071C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boulangerie et boulangerie-pâtisserie"])},
      "1071D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pâtisserie"])},
      "1072Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de biscuits, biscottes et pâtisseries de conservation"])},
      "1073Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pâtes alimentaires"])},
      "1081Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de sucre"])},
      "1082Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de cacao, chocolat et de produits de confiserie"])},
      "1083Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation du thé et du café"])},
      "1084Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de condiments et assaisonnements"])},
      "1085Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de plats préparés"])},
      "1086Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d aliments homogénéisés et diététiques"])},
      "1089Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits alimentaires n.c.a."])},
      "1091Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d aliments pour animaux de ferme"])},
      "1092Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d aliments pour animaux de compagnie"])},
      "1101Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de boissons alcooliques distillées"])},
      "1102A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vins effervescents"])},
      "1102B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vinification"])},
      "1103Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de cidre et de vins de fruits"])},
      "1104Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production d autres boissons fermentées non distillées"])},
      "1105Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de bière"])},
      "1106Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de malt"])},
      "1107A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrie des eaux de table"])},
      "1107B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de boissons rafraîchissantes"])},
      "1200Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits à base de tabac"])},
      "1310Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation de fibres textiles et filature"])},
      "1320Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tissage"])},
      "1330Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennoblissement textile"])},
      "1391Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d étoffes à mailles"])},
      "1392Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles textiles, sauf habillement"])},
      "1393Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de tapis et moquettes"])},
      "1394Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de ficelles, cordes et filets"])},
      "1395Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de non-tissés, sauf habillement"])},
      "1396Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres textiles techniques et industriels"])},
      "1399Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres textiles n.c.a."])},
      "1411Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements en cuir"])},
      "1412Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements de travail"])},
      "1413Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements de dessus"])},
      "1414Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements de dessous"])},
      "1419Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres vêtements et accessoires"])},
      "1420Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles en fourrure"])},
      "1431Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles chaussants à mailles"])},
      "1439Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres articles à mailles"])},
      "1511Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprêt et tannage des cuirs ; préparation et teinture des fourrures"])},
      "1512Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles de voyage, de maroquinerie et de sellerie"])},
      "1520Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de chaussures"])},
      "1610A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sciage et rabotage du bois, hors imprégnation"])},
      "1610B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprégnation du bois"])},
      "1621Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de placage et de panneaux de bois"])},
      "1622Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de parquets assemblés"])},
      "1623Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de charpentes et d autres menuiseries"])},
      "1624Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d emballages en bois"])},
      "1629Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d objets divers en bois ; fabrication d objets en liège, vannerie et sparterie"])},
      "1711Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pâte à papier"])},
      "1712Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de papier et de carton"])},
      "1721A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de carton ondulé"])},
      "1721B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de cartonnages"])},
      "1721C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d emballages en papier"])},
      "1722Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles en papier à usage sanitaire ou domestique"])},
      "1723Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles de papeterie"])},
      "1724Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de papiers peints"])},
      "1729Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres articles en papier ou en carton"])},
      "1811Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimerie de journaux"])},
      "1812Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre imprimerie (labeur)"])},
      "1813Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de pré-presse"])},
      "1814Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliure et activités connexes"])},
      "1820Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproduction d enregistrements"])},
      "1910Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cokéfaction"])},
      "1920Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raffinage du pétrole"])},
      "2011Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de gaz industriels"])},
      "2012Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de colorants et de pigments"])},
      "2013A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enrichissement et retraitement de matières nucléaires"])},
      "2013B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits chimiques inorganiques de base n.c.a."])},
      "2014Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits chimiques organiques de base"])},
      "2015Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits azotés et d engrais"])},
      "2016Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matières plastiques de base"])},
      "2017Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de caoutchouc synthétique"])},
      "2020Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pesticides et d autres produits agrochimiques"])},
      "2030Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de peintures, vernis, encres et mastics"])},
      "2041Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de savons, détergents et produits d entretien"])},
      "2042Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de parfums et de produits pour la toilette"])},
      "2051Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits explosifs"])},
      "2052Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de colles"])},
      "2053Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d huiles essentielles"])},
      "2059Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits chimiques n.c.a."])},
      "2060Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fibres artificielles ou synthétiques"])},
      "2110Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits pharmaceutiques de base"])},
      "2120Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de préparations pharmaceutiques"])},
      "2211Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication et rechapage de pneumatiques"])},
      "2219Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres articles en caoutchouc"])},
      "2221Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de plaques, feuilles, tubes et profilés en matières plastiques"])},
      "2222Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d emballages en matières plastiques"])},
      "2223Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d éléments en matières plastiques pour la construction"])},
      "2229A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pièces techniques à base de matières plastiques"])},
      "2229B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits de consommation courante en matières plastiques"])},
      "2311Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de verre plat"])},
      "2312Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Façonnage et transformation du verre plat"])},
      "2313Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de verre creux"])},
      "2314Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fibres de verre"])},
      "2319Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication et façonnage d autres articles en verre, y compris verre technique"])},
      "2320Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits réfractaires"])},
      "2331Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de carreaux en céramique"])},
      "2332Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de briques, tuiles et produits de construction, en terre cuite"])},
      "2341Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles céramiques à usage domestique ou ornemental"])},
      "2342Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d appareils sanitaires en céramique"])},
      "2343Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d isolateurs et pièces isolantes en céramique"])},
      "2344Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits céramiques à usage technique"])},
      "2349Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits céramiques"])},
      "2351Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de ciment"])},
      "2352Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de chaux et plâtre"])},
      "2361Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d éléments en béton pour la construction"])},
      "2362Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d éléments en plâtre pour la construction"])},
      "2363Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de béton prêt à l emploi"])},
      "2364Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de mortiers et bétons secs"])},
      "2365Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d ouvrages en fibre-ciment"])},
      "2369Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres ouvrages en béton, en ciment ou en plâtre"])},
      "2370Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille, façonnage et finissage de pierres"])},
      "2391Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits abrasifs"])},
      "2399Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres produits minéraux non métalliques n.c.a."])},
      "2410Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidérurgie"])},
      "2420Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier"])},
      "2431Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["étirage à froid de barres"])},
      "2432Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laminage à froid de feuillards"])},
      "2433Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilage à froid par formage ou pliage"])},
      "2434Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tréfilage à froid"])},
      "2441Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de métaux précieux"])},
      "2442Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie de l aluminium"])},
      "2443Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie du plomb, du zinc ou de l étain"])},
      "2444Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie du cuivre"])},
      "2445Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie des autres métaux non ferreux"])},
      "2446Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["élaboration et transformation de matières nucléaires"])},
      "2451Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie de fonte"])},
      "2452Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie d acier"])},
      "2453Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie de métaux légers"])},
      "2454Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie d autres métaux non ferreux"])},
      "2511Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de structures métalliques et de parties de structures"])},
      "2512Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de portes et fenêtres en métal"])},
      "2521Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de radiateurs et de chaudières pour le chauffage central"])},
      "2529Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres réservoirs, citernes et conteneurs métalliques"])},
      "2530Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de générateurs de vapeur, à l exception des chaudières pour le chauffage central"])},
      "2540Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d armes et de munitions"])},
      "2550A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forge, estampage, matriçage ; métallurgie des poudres"])},
      "2550B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découpage, emboutissage"])},
      "2561Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement et revêtement des métaux"])},
      "2562A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décolletage"])},
      "2562B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mécanique industrielle"])},
      "2571Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de coutellerie"])},
      "2572Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de serrures et de ferrures"])},
      "2573A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de moules et modèles"])},
      "2573B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres outillages"])},
      "2591Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fûts et emballages métalliques similaires"])},
      "2592Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d emballages métalliques légers"])},
      "2593Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles en fils métalliques, de chaînes et de ressorts"])},
      "2594Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vis et de boulons"])},
      "2599A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles métalliques ménagers"])},
      "2599B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres articles métalliques"])},
      "2611Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de composants électroniques"])},
      "2612Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de cartes électroniques assemblées"])},
      "2620Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d ordinateurs et d équipements périphériques"])},
      "2630Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d équipements de communication"])},
      "2640Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits électroniques grand public"])},
      "2651A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d équipements d aide à la navigation"])},
      "2651B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d instrumentation scientifique et technique"])},
      "2652Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horlogerie"])},
      "2660Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d équipements d irradiation médicale, d équipements électromédicaux et électrothérapeutiques"])},
      "2670Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériels optique et photographique"])},
      "2680Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de supports magnétiques et optiques"])},
      "2711Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de moteurs, génératrices et transformateurs électriques"])},
      "2712Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériel de distribution et de commande électrique"])},
      "2720Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de piles et d accumulateurs électriques"])},
      "2731Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de câbles de fibres optiques"])},
      "2732Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres fils et câbles électroniques ou électriques"])},
      "2733Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériel d installation électrique"])},
      "2740Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d appareils d éclairage électrique"])},
      "2751Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d appareils électroménagers"])},
      "2752Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d appareils ménagers non électriques"])},
      "2790Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres matériels électriques"])},
      "2811Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de moteurs et turbines, à l exception des moteurs d avions et de véhicules"])},
      "2812Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d équipements hydrauliques et pneumatiques"])},
      "2813Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres pompes et compresseurs"])},
      "2814Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres articles de robinetterie"])},
      "2815Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d engrenages et d organes mécaniques de transmission"])},
      "2821Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fours et brûleurs"])},
      "2822Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériel de levage et de manutention"])},
      "2823Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines et d équipements de bureau (à l exception des ordinateurs et équipements périphériques)"])},
      "2824Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d outillage portatif à moteur incorporé"])},
      "2825Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d équipements aérauliques et frigorifiques industriels"])},
      "2829A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d équipements d emballage, de conditionnement et de pesage"])},
      "2829B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres machines d usage général"])},
      "2830Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines agricoles et forestières"])},
      "2841Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines-outils pour le travail des métaux"])},
      "2849Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres machines-outils"])},
      "2891Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour la métallurgie"])},
      "2892Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour l extraction ou la construction"])},
      "2893Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour l industrie agro-alimentaire"])},
      "2894Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour les industries textiles"])},
      "2895Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour les industries du papier et du carton"])},
      "2896Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour le travail du caoutchouc ou des plastiques"])},
      "2899A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines d imprimerie"])},
      "2899B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres machines spécialisées"])},
      "2910Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de véhicules automobiles"])},
      "2920Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de carrosseries et remorques"])},
      "2931Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d équipements électriques et électroniques automobiles"])},
      "2932Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres équipements automobiles"])},
      "3011Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de navires et de structures flottantes"])},
      "3012Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de bateaux de plaisance"])},
      "3020Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de locomotives et d autre matériel ferroviaire roulant"])},
      "3030Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction aéronautique et spatiale"])},
      "3040Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de véhicules militaires de combat"])},
      "3091Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de motocycles"])},
      "3092Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de bicyclettes et de véhicules pour invalides"])},
      "3099Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres équipements de transport n.c.a."])},
      "3101Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de meubles de bureau et de magasin"])},
      "3102Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de meubles de cuisine"])},
      "3103Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matelas"])},
      "3109A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de sièges d ameublement d intérieur"])},
      "3109B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d autres meubles et industries connexes de l ameublement"])},
      "3211Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frappe de monnaie"])},
      "3212Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles de joaillerie et bijouterie"])},
      "3213Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles de bijouterie fantaisie et articles similaires"])},
      "3220Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d instruments de musique"])},
      "3230Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles de sport"])},
      "3240Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de jeux et jouets"])},
      "3250A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériel médico-chirurgical et dentaire"])},
      "3250B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de lunettes"])},
      "3291Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d articles de brosserie"])},
      "3299Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités manufacturières n.c.a."])},
      "3311Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d ouvrages en métaux"])},
      "3312Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de machines et équipements mécaniques"])},
      "3313Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de matériels électroniques et optiques"])},
      "3314Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d équipements électriques"])},
      "3315Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation et maintenance navale"])},
      "3316Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation et maintenance d aéronefs et d engins spatiaux"])},
      "3317Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation et maintenance d autres équipements de transport"])},
      "3319Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d autres équipements"])},
      "3320A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation de structures métalliques, chaudronnées et de tuyauterie"])},
      "3320B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation de machines et équipements mécaniques"])},
      "3320C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conception d ensemble et assemblage sur site industriel d équipements de contrôle des processus industriels"])},
      "3320D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation d équipements électriques, de matériels électroniques et optiques ou d autres matériels"])},
      "3511Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production d électricité"])},
      "3512Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport d électricité"])},
      "3513Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution d électricité"])},
      "3514Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce d électricité"])},
      "3521Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de combustibles gazeux"])},
      "3522Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution de combustibles gazeux par conduites"])},
      "3523Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de combustibles gazeux par conduites"])},
      "3530Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production et distribution de vapeur et d air conditionné"])},
      "3600Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captage, traitement et distribution d eau"])},
      "3700Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte et traitement des eaux usées"])},
      "3811Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte des déchets non dangereux"])},
      "3812Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte des déchets dangereux"])},
      "3821Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement et élimination des déchets non dangereux"])},
      "3822Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement et élimination des déchets dangereux"])},
      "3831Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démantèlement d épaves"])},
      "3832Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de déchets triés"])},
      "3900Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépollution et autres services de gestion des déchets"])},
      "4110A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion immobilière de logements"])},
      "4110B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion immobilière de bureaux"])},
      "4110C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion immobilière d autres bâtiments"])},
      "4110D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports juridiques de programmes"])},
      "4120A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de maisons individuelles"])},
      "4120B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction d autres bâtiments"])},
      "4211Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de routes et autoroutes"])},
      "4212Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de voies ferrées de surface et souterraines"])},
      "4213A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction d ouvrages d art"])},
      "4213B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction et entretien de tunnels"])},
      "4221Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de réseaux pour fluides"])},
      "4222Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de réseaux électriques et de télécommunications"])},
      "4291Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction d ouvrages maritimes et fluviaux"])},
      "4299Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction d autres ouvrages de génie civil n.c.a."])},
      "4311Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de démolition"])},
      "4312A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de terrassement courants et travaux préparatoires"])},
      "4312B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de terrassement spécialisés ou de grande masse"])},
      "4313Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forages et sondages"])},
      "4321A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux d'installation électrique dans tous locaux"])},
      "4321B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux d installation électrique sur la voie publique"])},
      "4322A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux d installation d eau et de gaz en tous locaux"])},
      "4322B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux d installation d équipements thermiques et de climatisation"])},
      "4329A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux d isolation"])},
      "4329B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres travaux d installation n.c.a."])},
      "4331Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de plâtrerie"])},
      "4332A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de menuiserie bois et pvc"])},
      "4332B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de menuiserie métallique et serrurerie"])},
      "4332C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agencement de lieux de vente"])},
      "4333Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de revêtement des sols et des murs"])},
      "4334Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de peinture et vitrerie"])},
      "4339Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres travaux de finition"])},
      "4391A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de charpente"])},
      "4391B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de couverture par éléments"])},
      "4399A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux d étanchéification"])},
      "4399B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de montage de structures métalliques"])},
      "4399C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de maçonnerie générale et gros uvre de bâtiment"])},
      "4399D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres travaux spécialisés de construction"])},
      "4399E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location avec opérateur de matériel de construction"])},
      "4511Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de voitures et de véhicules automobiles légers"])},
      "4519Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce d autres véhicules automobiles"])},
      "4520A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien et réparation de véhicules automobiles légers"])},
      "4520B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien et réparation d autres véhicules automobiles"])},
      "4531Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d équipements automobiles"])},
      "4532Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d équipements automobiles"])},
      "4540Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce et réparation de motocycles"])},
      "4611Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis"])},
      "4612A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrales d achat de carburant"])},
      "4612B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques"])},
      "4613Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en bois et matériaux de construction"])},
      "4614Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en machines, équipements industriels, navires et avions"])},
      "4615Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en meubles, articles de ménage et quincaillerie"])},
      "4616Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"])},
      "4617A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrales d achat alimentaires"])},
      "4617B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres intermédiaires du commerce en denrées, boissons et tabac"])},
      "4618Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires spécialisés dans le commerce d autres produits spécifiques"])},
      "4619A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centrales d achat non alimentaires"])},
      "4619B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres intermédiaires du commerce en produits divers"])},
      "4621Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de céréales, de tabac non manufacturé, de semences et d aliments pour le bétail"])},
      "4622Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de fleurs et plantes"])},
      "4623Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d animaux vivants"])},
      "4624Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de cuirs et peaux"])},
      "4631Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de fruits et légumes"])},
      "4632A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de viandes de boucherie"])},
      "4632B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de produits à base de viande"])},
      "4632C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de volailles et gibier"])},
      "4633Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de produits laitiers, ufs, huiles et matières grasses comestibles"])},
      "4634Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de boissons"])},
      "4635Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de produits à base de tabac"])},
      "4636Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de sucre, chocolat et confiserie"])},
      "4637Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de café, thé, cacao et épices"])},
      "4638A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de poissons, crustacés et mollusques"])},
      "4638B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) alimentaire spécialisé divers"])},
      "4639A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de produits surgelés"])},
      "4639B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) alimentaire non spécialisé"])},
      "4641Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de textiles"])},
      "4642Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d habillement et de chaussures"])},
      "4643Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d appareils électroménagers"])},
      "4644Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de vaisselle, verrerie et produits d entretien"])},
      "4645Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de parfumerie et de produits de beauté"])},
      "4646Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de produits pharmaceutiques"])},
      "4647Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de meubles, de tapis et d appareils d éclairage"])},
      "4648Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d articles d horlogerie et de bijouterie"])},
      "4649Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d autres biens domestiques"])},
      "4651Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d ordinateurs, d équipements informatiques périphériques et de logiciels"])},
      "4652Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de composants et d équipements électroniques et de télécommunication"])},
      "4661Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de matériel agricole"])},
      "4662Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de machines-outils"])},
      "4663Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de machines pour l extraction, la construction et le génie civil"])},
      "4664Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de machines pour l industrie textile et l habillement"])},
      "4665Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de mobilier de bureau"])},
      "4666Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d autres machines et équipements de bureau"])},
      "4669A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de matériel électrique"])},
      "4669B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de fournitures et équipements industriels divers"])},
      "4669C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de fournitures et équipements divers pour le commerce et les services"])},
      "4671Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de combustibles et de produits annexes"])},
      "4672Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de minerais et métaux"])},
      "4673A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de bois et de matériaux de construction"])},
      "4673B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d appareils sanitaires et de produits de décoration"])},
      "4674A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de quincaillerie"])},
      "4674B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de fournitures pour la plomberie et le chauffage"])},
      "4675Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de produits chimiques"])},
      "4676Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) d autres produits intermédiaires"])},
      "4677Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) de déchets et débris"])},
      "4690Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros (commerce interentreprises) non spécialisé"])},
      "4711A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de produits surgelés"])},
      "4711B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce d alimentation générale"])},
      "4711C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supérettes"])},
      "4711D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supermarchés"])},
      "4711E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Magasins multi-commerces"])},
      "4711F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypermarchés"])},
      "4719A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grands magasins"])},
      "4719B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres commerces de détail en magasin non spécialisé"])},
      "4721Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de fruits et légumes en magasin spécialisé"])},
      "4722Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de viandes et de produits à base de viande en magasin spécialisé"])},
      "4723Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé"])},
      "4724Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé"])},
      "4725Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de boissons en magasin spécialisé"])},
      "4726Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de produits à base de tabac en magasin spécialisé"])},
      "4729Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres commerces de détail alimentaires en magasin spécialisé"])},
      "4730Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de carburants en magasin spécialisé"])},
      "4741Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d ordinateurs, d unités périphériques et de logiciels en magasin spécialisé"])},
      "4742Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de matériels de télécommunication en magasin spécialisé"])},
      "4743Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de matériels audio et vidéo en magasin spécialisé"])},
      "4751Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de textiles en magasin spécialisé"])},
      "4752A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de quincaillerie, peintures et verres en petites surfaces (moins de 400 m²)"])},
      "4752B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de quincaillerie, peintures et verres en grandes surfaces (400 m² et plus)"])},
      "4753Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé"])},
      "4754Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d appareils électroménagers en magasin spécialisé"])},
      "4759A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de meubles"])},
      "4759B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d autres équipements du foyer"])},
      "4761Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de livres en magasin spécialisé"])},
      "4762Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de journaux et papeterie en magasin spécialisé"])},
      "4763Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d enregistrements musicaux et vidéo en magasin spécialisé"])},
      "4764Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d articles de sport en magasin spécialisé"])},
      "4765Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de jeux et jouets en magasin spécialisé"])},
      "4771Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d habillement en magasin spécialisé"])},
      "4772A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de la chaussure"])},
      "4772B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de maroquinerie et d articles de voyage"])},
      "4773Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de produits pharmaceutiques en magasin spécialisé"])},
      "4774Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d articles médicaux et orthopédiques en magasin spécialisé"])},
      "4775Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé"])},
      "4776Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé"])},
      "4777Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d articles d horlogerie et de bijouterie en magasin spécialisé"])},
      "4778A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerces de détail d optique"])},
      "4778B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerces de détail de charbons et combustibles"])},
      "4778C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres commerces de détail spécialisés divers"])},
      "4779Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de biens d occasion en magasin"])},
      "4781Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail alimentaire sur éventaires et marchés"])},
      "4782Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de textiles, d habillement et de chaussures sur éventaires et marchés"])},
      "4789Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres commerces de détail sur éventaires et marchés"])},
      "4791A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente à distance sur catalogue général"])},
      "4791B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente à distance sur catalogue spécialisé"])},
      "4799A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente à domicile"])},
      "4799B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente par automates et autres commerces de détail hors magasin, éventaires ou marchés n.c.a."])},
      "4910Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport ferroviaire interurbain de voyageurs"])},
      "4920Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports ferroviaires de fret"])},
      "4931Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports urbains et suburbains de voyageurs"])},
      "4932Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports de voyageurs par taxis"])},
      "4939A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports routiers réguliers de voyageurs"])},
      "4939B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres transports routiers de voyageurs"])},
      "4939C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphériques et remontées mécaniques"])},
      "4941A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports routiers de fret interurbains"])},
      "4941B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports routiers de fret de proximité"])},
      "4941C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de camions avec chauffeur"])},
      "4942Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de déménagement"])},
      "4950Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports par conduites"])},
      "5010Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports maritimes et côtiers de passagers"])},
      "5020Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports maritimes et côtiers de fret"])},
      "5030Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports fluviaux de passagers"])},
      "5040Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports fluviaux de fret"])},
      "5110Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports aériens de passagers"])},
      "5121Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports aériens de fret"])},
      "5122Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports spatiaux"])},
      "5210A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreposage et stockage frigorifique"])},
      "5210B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreposage et stockage non frigorifique"])},
      "5221Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services auxiliaires des transports terrestres"])},
      "5222Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services auxiliaires des transports par eau"])},
      "5223Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services auxiliaires des transports aériens"])},
      "5224A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutention portuaire"])},
      "5224B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutention non portuaire"])},
      "5229A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie, fret express"])},
      "5229B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affrètement et organisation des transports"])},
      "5310Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de poste dans le cadre d une obligation de service universel"])},
      "5320Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de poste et de courrier"])},
      "5510Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtels et hébergement similaire"])},
      "5520Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement touristique et autre hébergement de courte durée"])},
      "5530Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrains de camping et parcs pour caravanes ou véhicules de loisirs"])},
      "5590Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres hébergements"])},
      "5610A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration traditionnelle"])},
      "5610B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cafétérias et autres libres-services"])},
      "5610C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration de type rapide"])},
      "5621Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services des traiteurs"])},
      "5629A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restauration collective sous contrat"])},
      "5629B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services de restauration n.c.a."])},
      "5630Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débits de boissons"])},
      "5811Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de livres"])},
      "5812Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de répertoires et de fichiers d adresses"])},
      "5813Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de journaux"])},
      "5814Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de revues et périodiques"])},
      "5819Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités d édition"])},
      "5821Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de jeux électroniques"])},
      "5829A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de logiciels système et de réseau"])},
      "5829B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de logiciels outils de développement et de langages"])},
      "5829C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de logiciels applicatifs"])},
      "5911A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de films et de programmes pour la télévision"])},
      "5911B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de films institutionnels et publicitaires"])},
      "5911C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de films pour le cinéma"])},
      "5912Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-production de films cinématographiques, de vidéo et de programmes de télévision"])},
      "5913A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution de films cinématographiques"])},
      "5913B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition et distribution vidéo"])},
      "5914Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projection de films cinématographiques"])},
      "5920Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement sonore et édition musicale"])},
      "6010Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition et diffusion de programmes radio"])},
      "6020A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de chaînes généralistes"])},
      "6020B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edition de chaînes thématiques"])},
      "6110Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommunications filaires"])},
      "6120Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommunications sans fil"])},
      "6130Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommunications par satellite"])},
      "6190Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de télécommunication"])},
      "6201Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation informatique"])},
      "6202A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil en systèmes et logiciels informatiques"])},
      "6202B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tierce maintenance de systèmes et d applications informatiques"])},
      "6203Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion d installations informatiques"])},
      "6209Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités informatiques"])},
      "6311Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement de données, hébergement et activités connexes"])},
      "6312Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portails internet"])},
      "6391Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de presse"])},
      "6399Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services d information n.c.a."])},
      "6411Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de banque centrale"])},
      "6419Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres intermédiations monétaires"])},
      "6420Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des sociétés holding"])},
      "6430Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds de placement et entités financières similaires"])},
      "6491Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit-bail"])},
      "6492Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre distribution de crédit"])},
      "6499Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a."])},
      "6511Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance vie"])},
      "6512Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres assurances"])},
      "6520Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réassurance"])},
      "6530Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisses de retraite"])},
      "6611Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration de marchés financiers"])},
      "6612Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courtage de valeurs mobilières et de marchandises"])},
      "6619A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports juridiques de gestion de patrimoine mobilier"])},
      "6619B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite, n.c.a."])},
      "6621Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["évaluation des risques et dommages"])},
      "6622Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agents et courtiers d assurances"])},
      "6629Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités auxiliaires d assurance et de caisses de retraite"])},
      "6630Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de fonds"])},
      "6810Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des marchands de biens immobiliers"])},
      "6820A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de logements"])},
      "6820B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de terrains et d autres biens immobiliers"])},
      "6831Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agences immobilières"])},
      "6832A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration d immeubles et autres biens immobiliers"])},
      "6832B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supports juridiques de gestion de patrimoine immobilier"])},
      "6910Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités juridiques"])},
      "6920Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités comptables"])},
      "7010Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des sièges sociaux"])},
      "7021Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil en relations publiques et communication"])},
      "7022Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil pour les affaires et autres conseils de gestion"])},
      "7111Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités d architecture"])},
      "7112A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité des géomètres"])},
      "7112B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingénierie, études techniques"])},
      "7120A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôle technique automobile"])},
      "7120B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyses, essais et inspections techniques"])},
      "7211Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche-développement en biotechnologie"])},
      "7219Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche-développement en autres sciences physiques et naturelles"])},
      "7220Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche-développement en sciences humaines et sociales"])},
      "7311Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de publicité"])},
      "7312Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régie publicitaire de médias"])},
      "7320Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["études de marché et sondages"])},
      "7410Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités spécialisées de design"])},
      "7420Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités photographiques"])},
      "7430Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction et interprétation"])},
      "7490A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité des économistes de la construction"])},
      "7490B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités spécialisées, scientifiques et techniques diverses"])},
      "7500Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités vétérinaires"])},
      "7711A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de courte durée de voitures et de véhicules automobiles légers"])},
      "7711B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de longue durée de voitures et de véhicules automobiles légers"])},
      "7712Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de camions"])},
      "7721Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail d articles de loisirs et de sport"])},
      "7722Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de vidéocassettes et disques vidéo"])},
      "7729Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail d autres biens personnels et domestiques"])},
      "7731Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de machines et équipements agricoles"])},
      "7732Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de machines et équipements pour la construction"])},
      "7733Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de machines de bureau et de matériel informatique"])},
      "7734Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de matériels de transport par eau"])},
      "7735Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de matériels de transport aérien"])},
      "7739Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail d autres machines, équipements et biens matériels n.c.a."])},
      "7740Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location-bail de propriété intellectuelle et de produits similaires, à l exception des uvres soumises à copyright"])},
      "7810Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de placement de main-d uvre"])},
      "7820Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de travail temporaire"])},
      "7830Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre mise à disposition de ressources humaines"])},
      "7911Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de voyage"])},
      "7912Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des voyagistes"])},
      "7990Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services de réservation et activités connexes"])},
      "8010Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de sécurité privée"])},
      "8020Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités liées aux systèmes de sécurité"])},
      "8030Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités d'enquête"])},
      "8110Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités combinées de soutien lié aux bâtiments"])},
      "8121Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoyage courant des bâtiments"])},
      "8122Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de nettoyage des bâtiments et nettoyage industriel"])},
      "8129A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désinfection, désinsectisation, dératisation"])},
      "8129B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de nettoyage n.c.a."])},
      "8130Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services d aménagement paysager"])},
      "8211Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services administratifs combinés de bureau"])},
      "8219Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau"])},
      "8220Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de centres d appels"])},
      "8230Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation de foires, salons professionnels et congrès"])},
      "8291Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de recouvrement de factures et des sociétés d information financière sur la clientèle"])},
      "8292Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de conditionnement"])},
      "8299Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de soutien aux entreprises n.c.a."])},
      "8411Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration publique générale"])},
      "8412Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité social"])},
      "8413Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration publique (tutelle) des activités économiques"])},
      "8421Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affaires étrangères"])},
      "8422Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défense"])},
      "8423Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice"])},
      "8424Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités d ordre public et de sécurité"])},
      "8425Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services du feu et de secours"])},
      "8430A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités générales de sécurité sociale"])},
      "8430B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des retraites complémentaires"])},
      "8430C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution sociale de revenus"])},
      "8510Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement pré-primaire"])},
      "8520Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement primaire"])},
      "8531Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement secondaire général"])},
      "8532Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement secondaire technique ou professionnel"])},
      "8541Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement post-secondaire non supérieur"])},
      "8542Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement supérieur"])},
      "8551Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement de disciplines sportives et d activités de loisirs"])},
      "8552Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement culturel"])},
      "8553Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement de la conduite"])},
      "8559A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formation continue d adultes"])},
      "8559B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres enseignements"])},
      "8560Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien à l enseignement"])},
      "8610Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités hospitalières"])},
      "8621Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité des médecins généralistes"])},
      "8622A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de radiodiagnostic et de radiothérapie"])},
      "8622B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités chirurgicales"])},
      "8622C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités des médecins spécialistes"])},
      "8623Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratique dentaire"])},
      "8690A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ambulances"])},
      "8690B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laboratoires d analyses médicales"])},
      "8690C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Centres de collecte et banques d organes"])},
      "8690D": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des infirmiers et des sages-femmes"])},
      "8690E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des professionnels de la rééducation, de l'appareillage et des pédicures-podologues"])},
      "8690F": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de santé humaine non classées ailleurs"])},
      "8710A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement médicalisé pour personnes âgées"])},
      "8710B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement médicalisé pour enfants handicapés"])},
      "8710C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement médicalisé pour adultes handicapés et autre hébergement médicalisé"])},
      "8720A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour handicapés mentaux et malades mentaux"])},
      "8720B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour toxicomanes"])},
      "8730A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour personnes âgées"])},
      "8730B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour handicapés physiques"])},
      "8790A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour enfants en difficultés"])},
      "8790B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour adultes et familles en difficultés et autre hébergement social"])},
      "8810A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide à domicile"])},
      "8810B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil ou accompagnement sans hébergement d adultes handicapés ou de personnes âgées"])},
      "8810C": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aide par le travail"])},
      "8891A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil de jeunes enfants"])},
      "8891B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil ou accompagnement sans hébergement d enfants handicapés"])},
      "8899A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre accueil ou accompagnement sans hébergement d enfants et d adolescents"])},
      "8899B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action sociale sans hébergement n.c.a."])},
      "9001Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts du spectacle vivant"])},
      "9002Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien au spectacle vivant"])},
      "9003A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création artistique relevant des arts plastiques"])},
      "9003B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre création artistique"])},
      "9004Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de salles de spectacles"])},
      "9101Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des bibliothèques et des archives"])},
      "9102Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des musées"])},
      "9103Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des sites et monuments historiques et des attractions touristiques similaires"])},
      "9104Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des jardins botaniques et zoologiques et des réserves naturelles"])},
      "9200Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation de jeux de hasard et d argent"])},
      "9311Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion d installations sportives"])},
      "9312Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de clubs de sports"])},
      "9313Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des centres de culture physique"])},
      "9319Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités liées au sport"])},
      "9321Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des parcs d attractions et parcs à thèmes"])},
      "9329Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités récréatives et de loisirs"])},
      "9411Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations patronales et consulaires"])},
      "9412Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations professionnelles"])},
      "9420Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des syndicats de salariés"])},
      "9491Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations religieuses"])},
      "9492Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations politiques"])},
      "9499Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres organisations fonctionnant par adhésion volontaire"])},
      "9511Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d ordinateurs et d équipements périphériques"])},
      "9512Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d équipements de communication"])},
      "9521Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de produits électroniques grand public"])},
      "9522Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d appareils électroménagers et d équipements pour la maison et le jardin"])},
      "9523Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de chaussures et d articles en cuir"])},
      "9524Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de meubles et d équipements du foyer"])},
      "9525Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d articles d horlogerie et de bijouterie"])},
      "9529Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d autres biens personnels et domestiques"])},
      "9601A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanchisserie-teinturerie de gros"])},
      "9601B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanchisserie-teinturerie de détail"])},
      "9602A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coiffure"])},
      "9602B": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soins de beauté"])},
      "9603Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services funéraires"])},
      "9604Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien corporel"])},
      "9609Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services personnels n.c.a."])},
      "9700Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des ménages en tant qu employeurs de personnel domestique"])},
      "9900Z": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations et organismes extraterritoriaux"])}
    },
    "productTaxOptions": {
      "FR_TAX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT France"])},
      "FR_TAX_EXO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VAT Exclusive"])},
      "NO_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No VAT"])},
      "NORMAL_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normal Rate"])},
      "REDUCED_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reduced rate"])},
      "SPECIFIC_VAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Specific rate"])}
    },
    "codeNace": {
      "A0111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de céréales (à l'exception du riz), de légumineuses et de graines oléagineuses"])},
      "A0112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture du riz"])},
      "A0113": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de légumes, de melons, de racines et de tubercules"])},
      "A0114": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de la canne à sucre"])},
      "A0115": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture du tabac"])},
      "A0116": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de plantes à fibres"])},
      "A0119": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres cultures non permanentes"])},
      "A0121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de la vigne"])},
      "A0122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de fruits tropicaux et subtropicaux"])},
      "A0123": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture d'agrumes"])},
      "A0124": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de fruits à pépins et à noyau"])},
      "A0125": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture d'autres fruits d'arbres ou d'arbustes et de fruits à coque"])},
      "A0126": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de fruits oléagineux"])},
      "A0127": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de plantes à boissons"])},
      "A0128": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture de plantes à épices, aromatiques, médicinales et pharmaceutiques"])},
      "A0129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres cultures permanentes"])},
      "A0130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproduction de plantes"])},
      "A0141": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de vaches laitières"])},
      "A0142": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage d'autres bovins et de buffles"])},
      "A0143": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de chevaux et d'autres équidés"])},
      "A0144": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de chameaux et d'autres camélidés"])},
      "A0145": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage d'ovins et de caprins"])},
      "A0146": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de porcins"])},
      "A0147": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage de volailles"])},
      "A0149": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élevage d'autres animaux"])},
      "A0150": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Culture et élevage associés"])},
      "A0161": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien aux cultures"])},
      "A0162": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien à la production animale"])},
      "A0163": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement primaire des récoltes"])},
      "A0164": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement des semences"])},
      "A0170": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chasse, piégeage et services annexes"])},
      "A0210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sylviculture et autres activités forestières"])},
      "A0220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitation forestière"])},
      "A0230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récolte de produits forestiers non ligneux poussant à l'état sauvage"])},
      "A0240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de soutien à l'exploitation forestière"])},
      "A0311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pêche en mer"])},
      "A0312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pêche en eau douce"])},
      "A0321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquaculture en mer"])},
      "A0322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aquaculture en eau douce"])},
      "B0510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de houille"])},
      "B0520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de lignite"])},
      "B0610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de pétrole brut"])},
      "B0620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de gaz naturel"])},
      "B0710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de minerais de fer"])},
      "B0721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de minerais d'uranium et de thorium"])},
      "B0729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction d'autres minerais de métaux non ferreux"])},
      "B0811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de pierres ornementales et de construction, de calcaire industriel, de gypse, de craie et d'ardoise"])},
      "B0812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitation de gravières et sablières, extraction d’argiles et de kaolin"])},
      "B0891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction des minéraux chimiques et d'engrais minéraux "])},
      "B0892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extraction de tourbe"])},
      "B0893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de sel "])},
      "B0899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités extractives n.c.a."])},
      "B0910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien à l'extraction d'hydrocarbures"])},
      "B0990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien aux autres industries extractives "])},
      "C1011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de la viande de boucherie"])},
      "C1012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de la viande de volaille"])},
      "C1013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation de produits à base de viande"])},
      "C1020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de poisson, de crustacés et de mollusques"])},
      "C1031": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation et conservation de pommes de terre"])},
      "C1032": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation de jus de fruits et légumes"])},
      "C1039": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre transformation et conservation de fruits et légumes"])},
      "C1041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'huiles et graisses"])},
      "C1042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de margarine et graisses comestibles similaires"])},
      "C1051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exploitation de laiteries et fabrication de fromage"])},
      "C1052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de glaces et sorbets"])},
      "C1061": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travail des grains"])},
      "C1062": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits amylacés"])},
      "C1071": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pain et de pâtisserie fraîche"])},
      "C1072": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de biscuits, biscottes et pâtisseries de conservation"])},
      "C1073": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pâtes alimentaires"])},
      "C1081": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de sucre"])},
      "C1082": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de cacao, chocolat et de produits de confiserie"])},
      "C1083": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation du thé et du café"])},
      "C1084": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de condiments et assaisonnements"])},
      "C1085": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de plats préparés"])},
      "C1086": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'aliments homogénéisés et diététiques"])},
      "C1089": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits alimentaires n.c.a."])},
      "C1091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'aliments pour animaux de ferme"])},
      "C1092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'aliments pour animaux de compagnie"])},
      "C1101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de boissons alcooliques distillées"])},
      "C1102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de vin (de raisin)"])},
      "C1103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de cidre et de vins de fruits  "])},
      "C1104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production d'autres boissons fermentées non distillées"])},
      "C1105": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de bière"])},
      "C1106": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de malt"])},
      "C1107": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Industrie des eaux minérales et autres eaux embouteillées et des boissons rafraîchissantes"])},
      "C1200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits à base de tabac"])},
      "C1310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation de fibres textiles et filature"])},
      "C1320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tissage"])},
      "C1330": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ennoblissement textile"])},
      "C1391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'étoffes à mailles"])},
      "C1392": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles textiles, sauf habillement"])},
      "C1393": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de tapis et moquettes"])},
      "C1394": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de ficelles, cordes et filets"])},
      "C1395": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de non-tissés, sauf habillement"])},
      "C1396": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres textiles techniques et industriels"])},
      "C1399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres textiles n.c.a."])},
      "C1411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements en cuir"])},
      "C1412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements de travail"])},
      "C1413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements de dessus"])},
      "C1414": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vêtements de dessous"])},
      "C1419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres vêtements et accessoires"])},
      "C1420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles en fourrure"])},
      "C1431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles chaussants à mailles"])},
      "C1439": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres articles à mailles"])},
      "C1511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apprêt et tannage des cuirs ; préparation et teinture des fourrures"])},
      "C1512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles de voyage, de maroquinerie et de sellerie"])},
      "C1520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de chaussures"])},
      "C1610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sciage et rabotage du bois"])},
      "C1621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de placage et de panneaux de bois"])},
      "C1622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de parquets assemblés"])},
      "C1623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de charpentes et d'autres menuiseries"])},
      "C1624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'emballages en bois"])},
      "C1629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'objets divers en bois ; fabrication d'objets en liège, vannerie et sparterie"])},
      "C1711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pâte à papier"])},
      "C1712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de papier et de carton"])},
      "C1721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de papier et carton ondulés et d'emballages en papier ou en carton"])},
      "C1722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles en papier à usage sanitaire ou domestique"])},
      "C1723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles de papeterie"])},
      "C1724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de papiers peints"])},
      "C1729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres articles en papier ou en carton"])},
      "C1811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imprimerie de journaux"])},
      "C1812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre imprimerie (labeur)"])},
      "C1813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de pré-presse "])},
      "C1814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reliure et activités connexes"])},
      "C1820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reproduction d'enregistrements"])},
      "C1910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cokéfaction"])},
      "C1920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raffinage du pétrole"])},
      "C2011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de gaz industriels"])},
      "C2012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de colorants et de pigments"])},
      "C2013": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits chimiques inorganiques de base"])},
      "C2014": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits chimiques organiques de base"])},
      "C2015": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits azotés et d'engrais"])},
      "C2016": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matières plastiques de base"])},
      "C2017": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de caoutchouc synthétique"])},
      "C2020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de pesticides et d’autres produits agrochimiques"])},
      "C2030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de peintures, vernis, encres et mastics"])},
      "C2041": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de savons, détergents et produits d'entretien"])},
      "C2042": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de parfums et de produits pour la toilette"])},
      "C2051": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits explosifs"])},
      "C2052": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de colles"])},
      "C2053": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'huiles essentielles"])},
      "C2059": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits chimiques n.c.a."])},
      "C2060": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fibres artificielles ou synthétiques"])},
      "C2110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits pharmaceutiques de base"])},
      "C2120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de préparations pharmaceutiques"])},
      "C2211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication et rechapage de pneumatiques"])},
      "C2219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres articles en caoutchouc"])},
      "C2221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de plaques, feuilles, tubes et profilés en matières plastiques"])},
      "C2222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'emballages en matières plastiques"])},
      "C2223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'éléments en matières plastiques pour la construction"])},
      "C2229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres articles en matières plastiques"])},
      "C2311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de verre plat"])},
      "C2312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Façonnage et transformation du verre plat"])},
      "C2313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de verre creux"])},
      "C2314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fibres de verre"])},
      "C2319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication et façonnage d'autres articles en verre, y compris verre technique"])},
      "C2320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits réfractaires"])},
      "C2331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de carreaux en céramique"])},
      "C2332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de briques, tuiles et produits de construction, en terre cuite"])},
      "C2341": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles céramiques à usage domestique ou ornemental"])},
      "C2342": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'appareils sanitaires en céramique"])},
      "C2343": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'isolateurs et pièces isolantes en céramique"])},
      "C2344": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits céramiques à usage technique"])},
      "C2349": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits céramiques "])},
      "C2351": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de ciment"])},
      "C2352": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de chaux et plâtre"])},
      "C2361": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'éléments en béton pour la construction"])},
      "C2362": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'éléments en plâtre pour la construction"])},
      "C2363": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de béton prêt à l'emploi"])},
      "C2364": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de mortiers et bétons secs"])},
      "C2365": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'ouvrages en fibre-ciment"])},
      "C2369": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres ouvrages en béton, en ciment ou en plâtre"])},
      "C2370": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taille, façonnage et finissage de pierres"])},
      "C2391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits abrasifs"])},
      "C2399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits minéraux non métalliques n.c.a."])},
      "C2410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sidérurgie"])},
      "C2420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de tubes, tuyaux, profilés creux et accessoires correspondants en acier "])},
      "C2431": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Étirage à froid de barres"])},
      "C2432": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laminage à froid de feuillards"])},
      "C2433": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilage à froid par formage ou pliage"])},
      "C2434": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tréfilage à froid"])},
      "C2441": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de métaux précieux"])},
      "C2442": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie de l'aluminium"])},
      "C2443": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie du plomb, du zinc ou de l'étain"])},
      "C2444": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie du cuivre"])},
      "C2445": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Métallurgie des autres métaux non ferreux"])},
      "C2446": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élaboration et transformation de matières nucléaires"])},
      "C2451": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie de fonte"])},
      "C2452": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie d'acier"])},
      "C2453": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie de métaux légers"])},
      "C2454": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonderie d'autres métaux non ferreux"])},
      "C2511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de structures métalliques et de parties de structures"])},
      "C2512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de portes et fenêtres en métal"])},
      "C2521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de radiateurs et de chaudières pour le chauffage central"])},
      "C2529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres réservoirs, citernes et conteneurs métalliques"])},
      "C2530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de générateurs de vapeur, à l'exception des chaudières pour le chauffage central"])},
      "C2540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'armes et de munitions"])},
      "C2550": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forge, emboutissage, estampage ; métallurgie des poudres"])},
      "C2561": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement et revêtement des métaux"])},
      "C2562": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usinage"])},
      "C2571": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de coutellerie"])},
      "C2572": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de serrures et de ferrures"])},
      "C2573": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'outillage"])},
      "C2591": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fûts et emballages métalliques similaires"])},
      "C2592": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'emballages métalliques légers"])},
      "C2593": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles en fils métalliques, de chaînes et de ressorts"])},
      "C2594": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de vis et de boulons"])},
      "C2599": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres produits métalliques n.c.a."])},
      "C2611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de composants électroniques"])},
      "C2612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de cartes électroniques assemblées"])},
      "C2620": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'ordinateurs et d'équipements périphériques"])},
      "C2630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'équipements de communication "])},
      "C2640": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de produits électroniques grand public"])},
      "C2651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'instruments et d'appareils de mesure, d'essai et de navigation"])},
      "C2652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Horlogerie"])},
      "C2660": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'équipements d'irradiation médicale, d'équipements électromédicaux et électrothérapeutiques "])},
      "C2670": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériels optique et photographique"])},
      "C2680": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de supports magnétiques et optiques"])},
      "C2711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de moteurs, génératrices et transformateurs électriques"])},
      "C2712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériel de distribution et de commande électrique"])},
      "C2720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de piles et d'accumulateurs électriques"])},
      "C2731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de câbles de fibres optiques"])},
      "C2732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres fils et câbles électroniques ou électriques"])},
      "C2733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériel d'installation électrique"])},
      "C2740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'appareils d'éclairage électrique"])},
      "C2751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'appareils électroménagers"])},
      "C2752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'appareils ménagers non électriques"])},
      "C2790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres matériels électriques"])},
      "C2811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de moteurs et turbines, à l'exception des moteurs d’avions et de véhicules"])},
      "C2812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'équipements hydrauliques et pneumatiques"])},
      "C2813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres pompes et compresseurs"])},
      "C2814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres articles de robinetterie"])},
      "C2815": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'engrenages et d'organes mécaniques de transmission"])},
      "C2821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de fours et brûleurs"])},
      "C2822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matériel de levage et de manutention"])},
      "C2823": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines et d'équipements de bureau (à l'exception des ordinateurs et équipements périphériques)"])},
      "C2824": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'outillage portatif à moteur incorporé"])},
      "C2825": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'équipements aérauliques et frigorifiques industriels"])},
      "C2829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines diverses d'usage général"])},
      "C2830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines agricoles et forestières"])},
      "C2841": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines de formage des métaux"])},
      "C2849": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres machines-outils "])},
      "C2891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour la métallurgie"])},
      "C2892": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour l'extraction ou la construction"])},
      "C2893": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour l'industrie agro-alimentaire"])},
      "C2894": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour les industries textiles"])},
      "C2895": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour les industries du papier et du carton"])},
      "C2896": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de machines pour le travail du caoutchouc ou des plastiques"])},
      "C2899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres machines d'usage spécifique n.c.a."])},
      "C2910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de véhicules automobiles"])},
      "C2920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de carrosseries et remorques"])},
      "C2931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'équipements électriques et électroniques automobiles"])},
      "C2932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres équipements automobiles"])},
      "C3011": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de navires et de structures flottantes"])},
      "C3012": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de bateaux de plaisance"])},
      "C3020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de locomotives et d'autre matériel ferroviaire roulant "])},
      "C3030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction aéronautique et spatiale "])},
      "C3040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de véhicules militaires de combat "])},
      "C3091": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de motocycles"])},
      "C3092": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de bicyclettes et de véhicules pour invalides"])},
      "C3099": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d’autres équipements de transport n.c.a."])},
      "C3101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de meubles de bureau et de magasin"])},
      "C3102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de meubles de cuisine "])},
      "C3103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de matelas"])},
      "C3109": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'autres meubles"])},
      "C3211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frappe de monnaie"])},
      "C3212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d’articles de joaillerie et bijouterie"])},
      "C3213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d’articles de bijouterie fantaisie et articles similaires"])},
      "C3220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'instruments de musique"])},
      "C3230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'articles de sport"])},
      "C3240": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication de jeux et jouets"])},
      "C3250": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d'instruments et de fournitures à usage médical et dentaire "])},
      "C3291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication d’articles de brosserie"])},
      "C3299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités manufacturières n.c.a. "])},
      "C3311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'ouvrages en métaux"])},
      "C3312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de machines et équipements mécaniques"])},
      "C3313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de matériels électroniques et optiques"])},
      "C3314": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'équipements électriques"])},
      "C3315": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation et maintenance navale"])},
      "C3316": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation et maintenance d'aéronefs et d'engins spatiaux "])},
      "C3317": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation et maintenance d'autres équipements de transport"])},
      "C3319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'autres équipements"])},
      "C3320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation de machines et d'équipements industriels"])},
      "D3511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production d'électricité"])},
      "D3512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport d'électricité"])},
      "D3513": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution d'électricité"])},
      "D3514": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce d'électricité"])},
      "D3521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de combustibles gazeux"])},
      "D3522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution de combustibles gazeux par conduites"])},
      "D3523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de combustibles gazeux par conduites"])},
      "D3530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production et distribution de vapeur et d'air conditionné "])},
      "E3600": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Captage, traitement et distribution d'eau"])},
      "E3700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte et traitement des eaux usées"])},
      "E3811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte des déchets non dangereux"])},
      "E3812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Collecte des déchets dangereux"])},
      "E3821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement et élimination des déchets non dangereux"])},
      "E3822": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement et élimination des déchets dangereux"])},
      "E3831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Démantèlement d'épaves"])},
      "E3832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récupération de déchets triés"])},
      "E3900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépollution et autres services de gestion des déchets"])},
      "F4110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Promotion immobilière"])},
      "F4120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de bâtiments résidentiels et non résidentiels"])},
      "F4211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de routes et autoroutes"])},
      "F4212": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de voies ferrées de surface et souterraines"])},
      "F4213": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de ponts et tunnels"])},
      "F4221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de réseaux pour fluides"])},
      "F4222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction de réseaux électriques et de télécommunications"])},
      "F4291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction d'ouvrages maritimes et fluviaux"])},
      "F4299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Construction d'autres ouvrages de génie civil n.c.a."])},
      "F4311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de démolition"])},
      "F4312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de préparation des sites"])},
      "F4313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Forages et sondages"])},
      "F4321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Installation électrique"])},
      "F4322": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de plomberie et installation de chauffage et de conditionnement d'air"])},
      "F4329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres travaux d'installation"])},
      "F4331": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de plâtrerie"])},
      "F4332": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de menuiserie"])},
      "F4333": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de revêtement des sols et des murs"])},
      "F4334": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de peinture et vitrerie"])},
      "F4339": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres travaux de finition"])},
      "F4391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Travaux de couverture"])},
      "F4399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres travaux de construction spécialisés n.c.a."])},
      "G4511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de voitures et de véhicules automobiles légers"])},
      "G4519": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce d'autres véhicules automobiles"])},
      "G4520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien et réparation de véhicules automobiles"])},
      "G4531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'équipements automobiles"])},
      "G4532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'équipements automobiles"])},
      "G4540": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce et réparation de motocycles"])},
      "G4611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en matières premières agricoles, animaux vivants, matières premières textiles et produits semi-finis"])},
      "G4612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en combustibles, métaux, minéraux et produits chimiques"])},
      "G4613": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en bois et matériaux de construction"])},
      "G4614": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en machines, équipements industriels, navires et avions"])},
      "G4615": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en meubles, articles de ménage et quincaillerie"])},
      "G4616": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en textiles, habillement, fourrures, chaussures et articles en cuir"])},
      "G4617": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en denrées, boissons et tabac"])},
      "G4618": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires spécialisés dans le commerce d'autres produits spécifiques"])},
      "G4619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intermédiaires du commerce en produits divers"])},
      "G4621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de céréales, de tabac non manufacturé, de semences et d'aliments pour le bétail "])},
      "G4622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de fleurs et plantes"])},
      "G4623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'animaux vivants"])},
      "G4624": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de cuirs et peaux"])},
      "G4631": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de fruits et légumes"])},
      "G4632": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de viandes et de produits à base de viande"])},
      "G4633": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de produits laitiers, œufs, huiles et matières grasses comestibles"])},
      "G4634": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de boissons"])},
      "G4635": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de produits à base de tabac"])},
      "G4636": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de sucre, chocolat et confiserie"])},
      "G4637": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de café, thé, cacao et épices"])},
      "G4638": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'autres produits alimentaires, y compris poissons, crustacés et mollusques"])},
      "G4639": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros non spécialisé de denrées, boissons et tabac"])},
      "G4641": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de textiles"])},
      "G4642": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'habillement et de chaussures"])},
      "G4643": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'appareils électroménagers"])},
      "G4644": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de vaisselle, verrerie et produits d'entretien"])},
      "G4645": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de parfumerie et de produits de beauté"])},
      "G4646": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de produits pharmaceutiques"])},
      "G4647": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de meubles, de tapis et d'appareils d'éclairage "])},
      "G4648": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'articles d'horlogerie et de bijouterie"])},
      "G4649": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'autres biens domestiques"])},
      "G4651": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'ordinateurs, d'équipements informatiques périphériques et de logiciels"])},
      "G4652": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de composants et d'équipements électroniques et de télécommunication"])},
      "G4661": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de matériel agricole"])},
      "G4662": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de machines-outils"])},
      "G4663": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de machines pour l'extraction, la construction et le génie civil "])},
      "G4664": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de machines pour l'industrie textile et l'habillement"])},
      "G4665": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de mobilier de bureau"])},
      "G4666": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'autres machines et équipements de bureau "])},
      "G4669": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'autres machines et équipements"])},
      "G4671": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de combustibles et de produits annexes"])},
      "G4672": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de minerais et métaux"])},
      "G4673": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de bois, de matériaux de construction et d'appareils sanitaires "])},
      "G4674": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de quincaillerie et fournitures pour plomberie et chauffage"])},
      "G4675": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de produits chimiques"])},
      "G4676": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros d'autres produits intermédiaires"])},
      "G4677": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros de déchets et débris"])},
      "G4690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de gros non spécialisé"])},
      "G4711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail en magasin non spécialisé à prédominance alimentaire"])},
      "G4719": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre commerce de détail en magasin non spécialisé"])},
      "G4721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de fruits et légumes en magasin spécialisé"])},
      "G4722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de viandes et de produits à base de viande en magasin spécialisé"])},
      "G4723": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de poissons, crustacés et mollusques en magasin spécialisé"])},
      "G4724": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de pain, pâtisserie et confiserie en magasin spécialisé"])},
      "G4725": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de boissons en magasin spécialisé"])},
      "G4726": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de produits à base de tabac en magasin spécialisé"])},
      "G4729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres commerces de détail alimentaires en magasin spécialisé "])},
      "G4730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de carburants en magasin spécialisé"])},
      "G4741": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'ordinateurs, d'unités périphériques et de logiciels en magasin spécialisé"])},
      "G4742": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de matériels de télécommunication en magasin spécialisé"])},
      "G4743": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de matériels audio/vidéo en magasin spécialisé"])},
      "G4751": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de textiles en magasin spécialisé"])},
      "G4752": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de quincaillerie, peintures et verres en magasin spécialisé"])},
      "G4753": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de tapis, moquettes et revêtements de murs et de sols en magasin spécialisé"])},
      "G4754": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'appareils électroménagers en magasin spécialisé"])},
      "G4759": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de meubles, appareils d'éclairage et autres articles de ménage en magasin spécialisé"])},
      "G4761": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de livres en magasin spécialisé"])},
      "G4762": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de journaux et papeterie en magasin spécialisé"])},
      "G4763": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'enregistrements musicaux et vidéo en magasin spécialisé"])},
      "G4764": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'articles de sport en magasin spécialisé"])},
      "G4765": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de jeux et jouets en magasin spécialisé"])},
      "G4771": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'habillement en magasin spécialisé"])},
      "G4772": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de chaussures et d'articles en cuir en magasin spécialisé"])},
      "G4773": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de produits pharmaceutiques en magasin spécialisé"])},
      "G4774": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'articles médicaux et orthopédiques en magasin spécialisé"])},
      "G4775": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de parfumerie et de produits de beauté en magasin spécialisé"])},
      "G4776": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de fleurs, plantes, graines, engrais, animaux de compagnie et aliments pour ces animaux en magasin spécialisé"])},
      "G4777": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail d'articles d'horlogerie et de bijouterie en magasin spécialisé"])},
      "G4778": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre commerce de détail de biens neufs en magasin spécialisé"])},
      "G4779": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de biens d'occasion en magasin"])},
      "G4781": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail alimentaire sur éventaires et marchés"])},
      "G4782": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerce de détail de textiles, d'habillement et de chaussures sur éventaires et marchés"])},
      "G4789": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres commerces de détail sur éventaires et marchés"])},
      "G4791": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente à distance"])},
      "G4799": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres commerces de détail hors magasin, éventaires ou marchés"])},
      "H4910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transport ferroviaire interurbain de voyageurs"])},
      "H4920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports ferroviaires de fret "])},
      "H4931": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports urbains et suburbains de voyageurs"])},
      "H4932": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports de voyageurs par taxis"])},
      "H4939": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres transports terrestres de voyageurs n.c.a."])},
      "H4941": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports routiers de fret"])},
      "H4942": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services de déménagement"])},
      "H4950": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports par conduites"])},
      "H5010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports maritimes et côtiers de passagers"])},
      "H5020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports maritimes et côtiers de fret"])},
      "H5030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports fluviaux de passagers"])},
      "H5040": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports fluviaux de fret "])},
      "H5110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports aériens de passagers"])},
      "H5121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports aériens de fret"])},
      "H5122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transports spatiaux"])},
      "H5210": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreposage et stockage"])},
      "H5221": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services auxiliaires des transports terrestres"])},
      "H5222": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services auxiliaires des transports par eau"])},
      "H5223": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services auxiliaires des transports aériens"])},
      "H5224": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manutention"])},
      "H5229": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services auxiliaires des transports "])},
      "H5310": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de poste dans le cadre d'une obligation de service universel "])},
      "H5320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de poste et de courrier"])},
      "I5510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hôtels et hébergement similaire "])},
      "I5520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement touristique et autre hébergement de courte durée "])},
      "I5530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terrains de camping et parcs pour caravanes ou véhicules de loisirs"])},
      "I5590": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres hébergements "])},
      "I5610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Restaurants et services de restauration mobile"])},
      "I5621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services des traiteurs "])},
      "I5629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services de restauration "])},
      "I5630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Débits de boissons"])},
      "J5811": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de livres"])},
      "J5812": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de répertoires et de fichiers d'adresses"])},
      "J5813": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de journaux"])},
      "J5814": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de revues et périodiques"])},
      "J5819": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités d'édition"])},
      "J5821": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition de jeux électroniques"])},
      "J5829": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition d'autres logiciels"])},
      "J5911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Production de films cinématographiques, de vidéo et de programmes de télévision "])},
      "J5912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Post-production de films cinématographiques, de vidéo et de programmes de télévision"])},
      "J5913": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distribution de films cinématographiques, de vidéo et de programmes de télévision "])},
      "J5914": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projection de films cinématographiques"])},
      "J5920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enregistrement sonore et édition musicale "])},
      "J6010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Édition et diffusion de programmes radio"])},
      "J6020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation de télévision et télédiffusion"])},
      "J6110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommunications filaires"])},
      "J6120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommunications sans fil "])},
      "J6130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécommunications par satellite"])},
      "J6190": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de télécommunication "])},
      "J6201": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programmation informatique"])},
      "J6202": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil informatique "])},
      "J6203": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion d'installations informatiques"])},
      "J6209": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités informatiques"])},
      "J6311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traitement de données, hébergement et activités connexes"])},
      "J6312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portails Internet"])},
      "J6391": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de presse"])},
      "J6399": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services d'information n.c.a."])},
      "K6411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de banque centrale"])},
      "K6419": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres intermédiations monétaires"])},
      "K6420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des sociétés holding"])},
      "K6430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fonds de placement et entités financières similaires"])},
      "K6491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Crédit-bail "])},
      "K6492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre distribution de crédit"])},
      "K6499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités des services financiers, hors assurance et caisses de retraite, n.c.a."])},
      "K6511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance vie "])},
      "K6512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres assurances"])},
      "K6520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réassurance"])},
      "K6530": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisses de retraite"])},
      "K6611": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration de marchés financiers"])},
      "K6612": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courtage de valeurs mobilières et de marchandises"])},
      "K6619": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités auxiliaires de services financiers, hors assurance et caisses de retraite"])},
      "K6621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évaluation des risques et dommages"])},
      "K6622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agents et courtiers d'assurances"])},
      "K6629": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités auxiliaires d'assurance et de caisses de retraite"])},
      "K6630": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de fonds"])},
      "L6810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des marchands de biens immobiliers"])},
      "L6820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et exploitation de biens immobiliers propres ou loués "])},
      "L6831": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agences immobilières"])},
      "L6832": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration de biens immobiliers"])},
      "M6910": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités juridiques"])},
      "M6920": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités comptables"])},
      "M7010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des sièges sociaux"])},
      "M7021": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil en relations publiques et communication"])},
      "M7022": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil pour les affaires et autres conseils de gestion"])},
      "M7111": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités d'architecture "])},
      "M7112": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités d'ingénierie"])},
      "M7120": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de contrôle et analyses techniques"])},
      "M7211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche-développement en biotechnologie"])},
      "M7219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche-développement en autres sciences physiques et naturelles"])},
      "M7220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche-développement en sciences humaines et sociales"])},
      "M7311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de publicité"])},
      "M7312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Régie publicitaire de médias"])},
      "M7320": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Études de marché et sondages"])},
      "M7410": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités spécialisées de design"])},
      "M7420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités photographiques"])},
      "M7430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Traduction et interprétation"])},
      "M7490": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités spécialisées, scientifiques et techniques n.c.a."])},
      "M7500": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités vétérinaires"])},
      "N7711": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de voitures et de véhicules automobiles légers"])},
      "N7712": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de camions"])},
      "N7721": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail d'articles de loisirs et de sport "])},
      "N7722": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location de vidéocassettes et disques vidéo"])},
      "N7729": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail d'autres biens personnels et domestiques"])},
      "N7731": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de machines et équipements agricoles"])},
      "N7732": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de machines et équipements pour la construction"])},
      "N7733": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de machines de bureau et de matériel informatique"])},
      "N7734": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de matériels de transport par eau"])},
      "N7735": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail de matériels de transport aérien"])},
      "N7739": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location et location-bail d'autres machines, équipements et biens matériels n.c.a. "])},
      "N7740": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Location-bail de propriété intellectuelle et de produits similaires, à l'exception des œuvres soumises à copyright"])},
      "N7810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de placement de main-d'œuvre "])},
      "N7820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de travail temporaire "])},
      "N7830": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre mise à disposition de ressources humaines"])},
      "N7911": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de voyage"])},
      "N7912": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des voyagistes"])},
      "N7990": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services de réservation et activités connexes"])},
      "N8010": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de sécurité privée "])},
      "N8020": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités liées aux systèmes de sécurité "])},
      "N8030": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités d'enquête"])},
      "N8110": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités combinées de soutien lié aux bâtiments "])},
      "N8121": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nettoyage courant des bâtiments"])},
      "N8122": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de nettoyage des bâtiments et nettoyage industriel"])},
      "N8129": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de nettoyage"])},
      "N8130": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services d'aménagement paysager "])},
      "N8211": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services administratifs combinés de bureau"])},
      "N8219": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Photocopie, préparation de documents et autres activités spécialisées de soutien de bureau"])},
      "N8220": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de centres d'appels"])},
      "N8230": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation de salons professionnels et congrès"])},
      "N8291": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des agences de recouvrement de factures et des sociétés d'information financière sur la clientèle"])},
      "N8292": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de conditionnement"])},
      "N8299": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités de soutien aux entreprises n.c.a."])},
      "O8411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration publique générale"])},
      "O8412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration publique (tutelle) de la santé, de la formation, de la culture et des services sociaux, autre que sécurité sociale "])},
      "O8413": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administration publique (tutelle) des activités économiques"])},
      "O8421": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affaires étrangères"])},
      "O8422": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défense"])},
      "O8423": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Justice"])},
      "O8424": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités d’ordre public et de sécurité"])},
      "O8425": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services du feu et de secours"])},
      "O8430": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité sociale obligatoire"])},
      "P8510": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement pré-primaire"])},
      "P8520": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement primaire"])},
      "P8531": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement secondaire général"])},
      "P8532": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement secondaire technique ou professionnel"])},
      "P8541": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement post-secondaire non supérieur"])},
      "P8542": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement supérieur"])},
      "P8551": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement de disciplines sportives et d'activités de loisirs"])},
      "P8552": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement culturel"])},
      "P8553": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignement de la conduite"])},
      "P8559": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enseignements divers"])},
      "P8560": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien à l'enseignement"])},
      "Q8610": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités hospitalières"])},
      "Q8621": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité des médecins généralistes"])},
      "Q8622": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité des médecins spécialistes"])},
      "Q8623": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pratique dentaire"])},
      "Q8690": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités pour la santé humaine"])},
      "Q8710": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement médicalisé"])},
      "Q8720": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour personnes handicapées mentales, malades mentales et toxicomanes"])},
      "Q8730": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hébergement social pour personnes âgées ou handicapées physiques "])},
      "Q8790": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités d’hébergement social "])},
      "Q8810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action sociale sans hébergement pour personnes âgées et pour personnes handicapées "])},
      "Q8891": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Action sociale sans hébergement pour jeunes enfants"])},
      "Q8899": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autre action sociale sans hébergement n.c.a."])},
      "R9001": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Arts du spectacle vivant"])},
      "R9002": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de soutien au spectacle vivant"])},
      "R9003": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Création artistique"])},
      "R9004": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion de salles de spectacles"])},
      "R9101": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des bibliothèques et des archives"])},
      "R9102": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des musées"])},
      "R9103": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des sites et monuments historiques et des attractions touristiques similaires"])},
      "R9104": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion des jardins botaniques et zoologiques et des réserves naturelles"])},
      "R9200": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation de jeux de hasard et d'argent"])},
      "R9311": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gestion d'installations sportives"])},
      "R9312": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités de clubs de sports"])},
      "R9313": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des centres de culture physique"])},
      "R9319": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités liées au sport"])},
      "R9321": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des parcs d'attractions et parcs à thèmes"])},
      "R9329": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres activités récréatives et de loisirs "])},
      "S9411": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations patronales et consulaires"])},
      "S9412": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations professionnelles"])},
      "S9420": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des syndicats de salariés"])},
      "S9491": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations religieuses"])},
      "S9492": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations politiques"])},
      "S9499": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations associatives n.c.a."])},
      "S9511": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'ordinateurs et d'équipements périphériques"])},
      "S9512": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'équipements de communication"])},
      "S9521": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de produits électroniques grand public"])},
      "S9522": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'appareils électroménagers et d'équipements pour la maison et le jardin"])},
      "S9523": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de chaussures et d'articles en cuir"])},
      "S9524": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation de meubles et d'équipements du foyer"])},
      "S9525": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'articles d'horlogerie et de bijouterie"])},
      "S9529": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réparation d'autres biens personnels et domestiques"])},
      "S9601": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Blanchisserie-teinturerie"])},
      "S9602": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coiffure et soins de beauté"])},
      "S9603": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services funéraires"])},
      "S9604": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entretien corporel"])},
      "S9609": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres services personnels n.c.a."])},
      "T9700": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des ménages en tant qu'employeurs de personnel domestique"])},
      "T9810": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités indifférenciées des ménages en tant que producteurs de biens pour usage propre"])},
      "T9820": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités indifférenciées des ménages en tant que producteurs de services pour usage propre"])},
      "U9900": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activités des organisations et organismes extraterritoriaux"])}
    },
    "socioProfessionalCode": {
      "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculteur exploitant"])},
      "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculteur sur petite exploitation"])},
      "12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculteur sur moyenne exploitation"])},
      "13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agriculteur sur grande exploitation"])},
      "21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Artisan"])},
      "22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerçant et assimilé"])},
      "23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chef entreprise 10 salariés ou plus"])},
      "31": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession libérale"])},
      "33": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadre de la fonction publique"])},
      "34": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professeur, profession scientifique"])},
      "35": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession information/arts/spectacles"])},
      "37": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cadre administratif et commercial d’entreprise"])},
      "38": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingénieur cadre technique d’entreprise"])},
      "42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instituteur et assimilé"])},
      "43": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession intermédiaire santé et travail social"])},
      "44": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clergé-religieux"])},
      "45": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession intermédiaire administrative de la fonction publique"])},
      "46": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profession intermédiaire administrative et commerciale des entreprises"])},
      "47": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technicien"])},
      "48": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contremaitre-agent de maitrise"])},
      "52": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employé civil-agent de service de la FP"])},
      "53": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Policier et militaire"])},
      "54": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employé administratif d’entreprise"])},
      "55": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Employé de commerce"])},
      "56": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne. Service direct aux particuliers"])},
      "61": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier qualifié"])},
      "62": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier qualifié de type industrie"])},
      "63": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier qualifié de type artisanat"])},
      "64": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chauffeur"])},
      "65": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier qualifié manutentionnaire, Magasinier"])},
      "66": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier non qualifié"])},
      "67": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier non qualifié de type industrie"])},
      "68": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier non qualifié de type artisanat"])},
      "69": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ouvrier agricole"])},
      "71": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité agriculteur exploitant"])},
      "72": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité artisan commerce chef d’entreprise"])},
      "73": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité cadre profession intermédiaire"])},
      "74": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité cadre"])},
      "75": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité profession intermédiaire"])},
      "76": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité employé et ouvrier"])},
      "77": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité employé"])},
      "78": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retraité ouvrier"])},
      "81": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chômeur n’ayant jamais travaillé"])},
      "82": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne sans activités professionnelle"])},
      "84": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elève/étudiant"])},
      "85": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans profession - de 60 ans (hors retraite)"])},
      "86": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sans profession + de 60 ans (hors retraite)"])},
      "95": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EAP"])},
      "99": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non renseigné (inconnu)"])}
    }
  },
  "scales": {
    "tabs": {
      "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données générales"])},
      "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
      "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission"])},
      "financial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Élément financier"])},
      "service": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services et prestations"])}
    },
    "filtreMenu": {
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
      "validityFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité à partir de"])},
      "validityTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité jusqu'à"])},
      "financialProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil financier"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "listOfScales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["List des barèmes"])},
      "Category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
      "Class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])}
    },
    "services": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des services et presentations"])},
      "filter": {
        "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
        "validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "TAEG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inclut TAEG"])},
        "paymentType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de payement"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])}
      }
    },
    "commissions": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des commissions"])},
      "calculationMethodOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de calcul"])},
      "amountLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Amount"])},
      "amountMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
      "amountMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
      "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defaut"])},
      "annualRateLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux annuel"])},
      "annualRateMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
      "annualRateMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])},
      "annualRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Defaut"])},
      "basis": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Base"])}
    },
    "financial": {
      "minDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée Min"])},
      "maxDuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée Max"])},
      "numberOfPaymentTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'échéance"])},
      "frequency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fréquence"])},
      "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
      "typeRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de taux"])},
      "rateDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux défault"])},
      "rateMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux min"])},
      "rateMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux max"])},
      "basisRate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux de base"])},
      "firstPaymentDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier loyer defaut %"])},
      "firstPaymentMin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier loyer min %"])},
      "firstPaymentMax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier loyer max %"])},
      "downPaymentDefault": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport moyen"])},
      "maxDownPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport Max"])},
      "minDownpayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport Min"])},
      "minNumberGracePeriodTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode de grâce min"])},
      "maxNumberGracePeriodTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode de grâce max"])},
      "defaultNumberGracePeriodTerms": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode grâce"])},
      "gracePeriodCalculationMethodOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode de calcul"])},
      "lastPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dernier paiement"])},
      "outstandingBalanceBefore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours début"])},
      "outstandingBalanceAfter": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Encours fin"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])}
    },
    "toggle": {
      "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])},
      "advancedConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration avancé avec business rules"])},
      "firstPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier loyer"])},
      "downPayment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apport"])},
      "gracePeriod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Periode de grâce"])},
      "duration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
      "rate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taux"])},
      "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])}
    },
    "button": {
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "status": {
        "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activer"])},
        "suspend": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspend"])}
      }
    },
    "frequencyDuration": {
      "MONTH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mois"])},
      "DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jour"])}
    },
    "picklist": {
      "status": {
        "DRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial"])},
        "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendu"])},
        "USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Active"])}
      },
      "serviceTypes": {
        "maintenanceproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service Maintenance"])},
        "assetinsuranceproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance Bien"])},
        "partyinsuranceproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assurance Tiers"])},
        "simpleserviceproduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais"])}
      },
      "category_class": {
        "ACTIVITY_TYPE_LOAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’activité Prêt"])},
        "ACTIVITY_TYPE_LEASE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’activité Location"])},
        "ACTIVITY_LOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité LOA"])},
        "ACTIVITY_LLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité LLD"])},
        "asset_good_vehicle_brand_AUDI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AUDI"])},
        "NATURE_OPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option"])},
        "asset_good_vehicle_brand_HUYNDAI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HUYNDAI"])},
        "asset_good_vehicle_brand_GOLF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOLF"])},
        "asset_good_vehicle_brand_HUYNDAI_range_i10_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i10_1.2"])},
        "asset_good_vehicle_brand_GOLF_range_GOLF7_7_1_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOLF7_7_1.2"])},
        "PROFILE_LOA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile LOA"])},
        "PROFILE_LLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile LLD"])},
        "PROFILE_CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile CREDIT"])},
        "BODYSTYLE_HATCHBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hatchback"])},
        "GOOD_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicule"])},
        "NATURE_ACCESSORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessoire"])},
        "NATURE_ASSETFEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais d’actif"])},
        "asset_good_vehicle_gearbox_automatic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Automatique"])},
        "GOOD_EQUIP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Equipement"])},
        "NATURE_EQUIPMENTPACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paquet d’équipement"])},
        "asset_good_vehicle_brand_AUDI_range_R8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R8"])},
        "asset_good_vehicle_gearbox_manual": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Manuel"])},
        "asset_good_vehicle_car_bodyStyle_SEDAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEDAN"])},
        "ACTIVITY_CREDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité Crédit"])},
        "ACTIVITY_RACI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité RACI"])},
        "asset_good_vehicle_car_bodyStyle_SUV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SUV"])},
        "asset_good_vehicle_car_bodyStyle_COUPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COUPE"])},
        "asset_good_vehicle_car_bodyStyle_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPORTS"])},
        "asset_good_vehicle_car_bodyStyle_HATCHBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HATCHBACK"])},
        "asset_good_vehicle_car_bodyStyle_MINIVAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MINIVAN"])},
        "asset_good_vehicle_brand_GOLF_range_GOLF7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GOLF7"])},
        "ACTIVITY_RACC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité RACC"])},
        "PROFILE_RACC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile RACC"])},
        "PROFILE_RACHC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile RACHC"])},
        "PROFILE_RACI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile RACI"])},
        "asset_good_vehicle_brand_AUDI_range_R8_RS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["R8_RS"])},
        "asset_good_vehicle_brand_RENAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RENAULT"])},
        "asset_good_vehicle_brand_RENAULT_range_clio": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clio"])},
        "asset_good_vehicle_brand_RENAULT_range_clio_rs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clio rs"])},
        "BROKER_BROKER_A": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Broker A"])},
        "BROKER_VENDOR_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendor 1"])},
        "asset_good_vehicle_brand_BMW_range_X6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X6"])},
        "NATURE_CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CAR"])},
        "NATURE_CARPACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARPACK"])},
        "asset_good_vehicle_energytype_electric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrique"])},
        "asset_good_vehicle_energytype_Diezel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diezel"])},
        "asset_good_vehicle_energytype_Hybrid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hybrid"])},
        "asset_good_vehicle_energytype_Gasoline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gasoline"])},
        "ACTIVITY_RACHC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité RACHC"])},
        "CLIENT_TYPE_ORGANIZATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne morale"])},
        "CLIENT_TYPE_PERSON": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Personne physique"])},
        "asset_good_vehicle_car_bodyStyle_PICKUPTRUCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PICKUPTRUCK"])},
        "asset_good_vehicle_brand_HUYNDAI_range_GRAND i10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRAND i10"])},
        "asset_good_vehicle_brand_HUYNDAI_range_i10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i10"])},
        "asset_good_vehicle_brand_HUYNDAI_range_i20": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i20"])},
        "asset_good_vehicle_brand_BMW_range_X6_0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["X6_0"])},
        "PROFILE_LOAM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile LOAM"])},
        "asset_good_vehicle_brand_BMW_X6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMW_X6"])},
        "asset_good_vehicle_brand_BMW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMW"])},
        "BRAND_HYUNDAI_RANGE_i10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["i10"])},
        "BRAND_BMW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BMW"])},
        "ENERGY_ELECTRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Electrique"])},
        "BRAND_HYUNDAI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HYUNDAI"])},
        "BODYSTYLE_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sports"])},
        "ENERGY_GASSOLINE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gassoline"])},
        "asset_good_Good_Family_AERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aéronefs"])},
        "asset_good_Good_Family_VP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicules particuliers"])},
        "asset_good_Good_Family_BAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bateaux"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.11"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_12": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.12"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_13": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.13"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_21": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.21"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_22": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.22"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_23": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.23"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_24": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.24"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_41": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.41"])},
        "asset_good_Good_Family_VP_SubFamily_29_10_42": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["29.10.42"])}
      },
      "category": {
        "ACTIVITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "ACTIVITY_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d’activité"])},
        "SERVICE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Service"])},
        "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
        "CLIENT_TYPE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de client"])},
        "asset_good_vehicle_brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
        "NATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nature"])},
        "asset_good_vehicle_brand_range_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "asset_good_vehicle_brand_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modéle"])},
        "asset_good_vehicle_energytype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie"])},
        "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profile"])},
        "asset_good_Good_Family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille"])},
        "asset_good_Good_Family_SubFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous famille"])},
        "asset_good_Equipment_brand_range_version": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Version"])},
        "asset_good_Equipment_brand_range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme"])},
        "asset_good_vehicle_gearbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Boite de vitesse"])},
        "asset_good_vehicle_car_bodyStyle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Style"])},
        "finance_EquipmentFinancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Financement d’équipement"])},
        "BRAND_HYUNDAI_RANGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gamme HYUNDAI"])},
        "BROKER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courtier"])}
      }
    },
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
    "error": {
      "constraintViolationExceptionScale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La référence doit être unique. La référence saisi est déjà associé à un autre barème : "])},
      "constraintViolationExceptionFinancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La référence doit être unique. La référence saisi est déjà associé à un autre élément financier: "])},
      "constraintViolationExceptionServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La référence doit être unique. La référence saisi est déjà associé à un autre élément service: "])},
      "constraintViolationExceptionCommission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La référence doit être unique. La référence saisi est déjà associé à une autre comission: "])}
    },
    "applicationRules": {
      "form": {
        "packageFeatureQualification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicabilité"])}
      }
    }
  },
  "availabilities": {
    "filter": {
      "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
      "validityFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité à partir de"])},
      "validityTo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité jusqu’à"])},
      "context": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contexte"])}
    },
    "form": {
      "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Categorie"])},
      "class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
      "priority": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Priorité"])}
    },
    "availabilityList": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Eligibilités"])},
    "addAvailability": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter Disponibilité"])},
    "button": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])}
    },
    "picklist": {
      "context": {
        "AssetProduct": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AssetProduct"])},
        "": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([])}
      }
    }
  },
  "sidebar": {
    "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
    "garanty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garanties"])},
    "advancedConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration avancé "])}
  },
  "garanty": {
    "globalLabels": {
      "Add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "Garanties_list": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des garanties"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réintialiser "])},
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
    },
    "GuaranteeViewList": {
      "Add_criteria": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter un critère"])},
      "filtres": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtres"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "guaranty_name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
      "garanty_type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de Garantie"])},
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réfèrence"])}
    },
    "garantyList": {
      "noDataLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune garantie n’est encore ajoutée"])}
    },
    "garantyFilter": {
      "picklist": {
        "status": {
          "DRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial"])},
          "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])},
          "USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])}
        },
        "type": {
          "PERSONAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie personnelle"])},
          "BANK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie bancaire"])},
          "PROPERTYPLEDGING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantissement du bien"])},
          "BUYBACK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement de reprise"])},
          "MORTGAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
        },
        "Basis": {
          "FinancedAssetsNetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Asset HT"])},
          "FinancedAssetsGrossValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Asset TTC"])},
          "FinancedAssetsGrossValue_1st_PaymentGrossValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Asset HT"])},
          "OutStandingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant En cours"])},
          "FinancedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Financé"])}
        },
        "underlying": {
          "ASSET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
          "OTHER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
          "PROPERTY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriété"])}
        }
      }
    },
    "guaranteeType": {
      "BuyBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement de reprise"])},
      "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])},
      "PersonalGuarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie personnelle"])},
      "BankGuarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie bancaire"])},
      "PropertyPledging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantissement du bien"])}
    },
    "dialog": {
      "Addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La garantie a été ajoutée avec succès"])},
      "Editsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La garantie a été mise à jour avec succès"])},
      "Removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La garantie a été supprimée avec succès"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
    },
    "garantiedetails": {
      "Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMATION GÈNÈRALE "])},
      "Reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence "])},
      "GuaranteeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de garantie"])},
      "GuaranteeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de garantie"])},
      "GuaranteeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "GuarantorDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÈTAILS DU GARANT"])},
      "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle "])},
      "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type du tier"])},
      "SelectedGuarantor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garant Selectionné"])},
      "EvaluationMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode d’évaluation"])},
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
      "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage"])},
      "BasisType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de base"])},
      "Underlyingtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit sous-jacent "])},
      "Commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission "])},
      "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARACTÈRISTIQUES"])},
      "Asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
      "Properties": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétés"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité n'est pas encore prête, elle sera bientôt disponible"])},
      "OtherDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sous jacent"])},
      "Validity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validité"])},
      "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
      "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])}
    }
  },
  "good": {
    "goodCatalogList": {
      "noDataLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catalogue n’est encore ajoutée"])}
    },
    "globalLabels": {
      "images": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Images"])},
      "validity": {
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
        "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])}
      },
      "goodOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer Option"])},
      "goodAccessory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer Accessoire"])},
      "goodFees": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais et service"])},
      "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catalogue"])},
      "percent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage"])},
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer"])},
      "cancelButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
      "import": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Importer"])},
      "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exporter"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sauvegarder"])},
      "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermer"])},
      "OBLIGATORY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
      "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
      "goodSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des Biens"])},
      "catalogSearch": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de Catalogue"])},
      "features": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques"])},
      "price_details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détail des prix"])},
      "accessories": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accessoires"])},
      "residualValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valeur résiduelle"])},
      "associated_goods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biens associés"])},
      "catalogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogues"])},
      "classification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classification"])},
      "options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
      "statutOption": {
        "DRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recrue"])},
        "SUSPENDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suspendu"])},
        "USE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisé"])}
      },
      "addGaranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter Garantie"])},
      "editGaranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Editer Garantie"])},
      "viewGaranty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consulter Garantie"])},
      "GeneratedDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents générés"])},
      "noGeneratedDocs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun document n'a été généré jusqu'à présent."])}
    },
    "goodList": {
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche des Biens"])},
      "noDataLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune bien n’est encore ajoutée"])}
    },
    "goodFilter": {
      "goodReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
      "goodName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
      "goodType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
      "goodBrand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
      "goodRange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
      "goodFamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille"])},
      "goodCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue"])},
      "goodStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "fournisseurs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs"])},
      "listOfGoods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des biens"])},
      "button": {
        "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
      },
      "picklist": {
        "catalog": {
          "catalog_client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue de client"])}
        }
      }
    },
    "goodDetails": {
      "goodAccessories": {
        
      },
      "goodAddProposalItem": {
        
      },
      "goodAssociatedGoods": {
        "goodSearchDialog": {
          
        }
      },
      "goodCatalogs": {
        "button": {
          "addCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter catalogue"])},
          "creatCatalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créer un catalogue"])}
        },
        "goodCatalogList": {
          "noDataLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucune catalogue n’est encore ajoutée"])}
        },
        "goodCatalogCreation": {
          "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["catalogue"])},
          "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reférence"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])}
        },
        "goodCatalogAddition": {
          "button": {
            "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter catalogue"])}
          },
          "catalogFilter": {
            "catalogReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référance"])},
            "catalogName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
            "catalogCreationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de Création"])},
            "catalogEndDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
            "catalogStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])}
          },
          "ScreenTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recherche de Catalogue"])},
          "ScreenSubTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer les catalogue selon la liste des critères"])}
        },
        "catalogReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
        "catalogName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé"])},
        "catalogFrom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
        "catalogUntil": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])},
        "catalogCurrency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
        "catalogPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
        "catalogCatalogPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix catalogue"])}
      },
      "goodClassification": {
        "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégorie"])},
        "categoryClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Classe"])},
        "validity": {
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date fin"])}
        }
      },
      "goodFeatures": {
        "OverallCharacteristicsTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caractéristiques globales"])},
        "MotorizationTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Motorisation"])},
        "ConsumptionTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation"])},
        "OverallCharacteristics": {
          "Family": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Famille"])},
          "Subfamily": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous famille"])},
          "Brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
          "Model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modéle"])},
          "Finishing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finition"])},
          "Bodywork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carosserie"])},
          "NumberOfPlaces": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de places"])},
          "NumberOfDoors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de portes"])}
        },
        "Motorization": {
          "NumberOfCylinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de cylindres"])},
          "Energy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Energie"])},
          "FiscalPower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance fiscale"])},
          "CO2Emissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emission co2"])},
          "numberOfSpeeds": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de vitesse"])}
        },
        "Consumption": {
          "UrbanConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation Urbaine"])},
          "ExtraUrbanConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation Extra Urbaine"])},
          "MixedConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Consommation Mixte"])}
        }
      },
      "goodFeesAndServices": {
        "FeesAndServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais et services"])}
      },
      "goodGeneralInfo": {
        "generalInformations": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations générales"])},
        "propertyReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence du bien"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
        "typeOption": {
          "NATURE": {
            "CAR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voiture"])},
            "EQUIPMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipement"])},
            "GUARANTEE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie"])}
          }
        }
      },
      "goodOptions": {
        "mandatory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obligatoire"])},
        "unit_price_ht": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Unitaire HT"])},
        "type_of_taxe": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de taxe"])},
        "discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])}
      },
      "goodPriceDetails": {
        "currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire HT"])},
        "taxeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de taxe"])},
        "remise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remise"])},
        "validity": {
          "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de début"])},
          "until": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de fin"])}
        },
        "ScreenTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails des prix"])}
      },
      "goodProposalItem": {
        "price_without_tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix hors taxe"])}
      },
      "goodResidualValue": {
        "Default": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Défaut"])},
        "Min": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Min"])},
        "Max": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max"])}
      },
      "goodFeesServices": {
        "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de taxe"])},
        "advancedConfiguration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["configuration avancé"])},
        "unitPriceHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix unitaire HT"])},
        "addFeesService": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["frais et service"])},
        "configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration"])}
      }
    },
    "dialog": {
      "Addsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le catalogue a été ajoutée avec succès"])},
      "Editsuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le catalogue a été mise à jour avec succès"])},
      "Removesuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le catalogue a été supprimée avec succès"])},
      "catalogExistsError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le catalogue existe déjà"])},
      "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreur"])}
    }
  },
  "garantie": {
    "garantiedetails": {
      "Info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFORMATION GÈNÈRALE "])},
      "Reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence "])},
      "GuaranteeName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de garantie"])},
      "GuaranteeType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de garantie"])},
      "GuaranteeStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "GuarantorDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DÈTAILS DU GARANT"])},
      "Role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rôle "])},
      "Type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type du tier"])},
      "SelectedGuarantor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garant Selectionné"])},
      "EvaluationMethod": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthode d’évaluation"])},
      "Amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])},
      "Currency": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devise"])},
      "Percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pourcentage"])},
      "BasisType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de base"])},
      "Underlyingtype": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit sous-jacent "])},
      "Commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission "])},
      "characteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARACTÈRISTIQUES"])},
      "Asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])},
      "Property": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Propriétés"])},
      "other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres"])},
      "warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cette fonctionnalité n'est pas encore prête, elle sera bientôt disponible"])},
      "Basis": {
        "FinancedAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Financé"])},
        "TTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Asset TTC"])},
        "HT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix Asset HT"])},
        "OutstandingAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant En cours"])}
      },
      "statuslist": {
        "Initial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initial"])},
        "Active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actif"])},
        "Inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inactif"])}
      },
      "Typedegarantie": {
        "Personalguarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie personnelle"])},
        "Bankguarantee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Garantie bancaire"])},
        "PropertyPledging": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nantissement du bien"])},
        "BuyBack": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Engagement de reprise"])},
        "Mortgage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hypothèque"])}
      },
      "OtherDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sous jacent"])}
    },
    "error": {
      "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règle(s) ou champ(s) vide(s) manquant(s) !"])},
      "evaluationMethodField": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez une méthode d'évaluation"])}
    }
  },
  "queryBuilder": {
    "editBuisinessRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer la règle métier"])},
    "addRule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])}
  },
  "controls": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contrôles"])},
    "errorMessage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez résoudre les problèmes avant de continuer"])}
  },
  "order": {
    "messages": {
      "createSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande est créée avec succès"])},
      "updateSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande est modifiée avec succès"])},
      "deleteSuccess": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La commande est supprimée avec succès"])}
    },
    "buttons": {
      "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ADD"])},
      "summaryLink": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Synthèse de la commande"])},
      "summary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Récapitulatif de la commande"])}
    },
    "panel": {
      "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ref"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
      "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
      "dateCommand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de commande"])},
      "dateDelivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de livraison"])},
      "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
      "commandReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données Du PV des Mines"])},
      "reportData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données Du PV des Mines"])},
      "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset"])}
    },
    "main": {
      "generalInfo": {
        "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Dossier"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création du dossier"])},
        "provisionalDeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de livraison previsionnel"])},
        "salesChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau de vente"])},
        "channel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Canal"])},
        "subChannel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous canal"])},
        "commercial": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commerciale"])},
        "client": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une commande Client"])}
      },
      "tabs": {
        "commission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["COMMISSION"])}
      }
    },
    "item": {
      "generalInfo": {
        "orderReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Commande"])},
        "externalReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reference Externe"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "orderCreationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création commande"])},
        "initialDeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de livraison initiale"])},
        "provisionalDeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de livraison Prévisionnelle"])},
        "reelDeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de livraison réelle"])},
        "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
        "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
        "from": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A partir De"])}
      },
      "cols": {
        "orderNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réf Commande"])},
        "type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type"])},
        "asset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bien"])},
        "orderCreationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Création Commande"])},
        "provisionalDeliveryDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date Livraison Prévisionnelle"])},
        "supplier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseur"])},
        "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
        "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant Total TTC"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "VEHICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vehicle"])},
        "CUSTOMER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Customer"])},
        "SUPPLIER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supplier"])}
      }
    },
    "commision": {
      "commissionType": {
        "commissionAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une prime"])},
        "commissionEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une prime"])},
        "commissionButtonAdd": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
        "commissionButtonEdit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier"])},
        "commissionsDialog": {
          "beneficiary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bénéficiaire"])},
          "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Description"])},
          "amount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant"])}
        },
        "COMMISSION_TYPE": {
          "VEHICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Véhicle"])}
        }
      },
      "description": {
        "Distrib": {
          "COMM_VEHICLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commission on margin"])}
        }
      },
      "calculeType": {
        "FixedValuePerInstallment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fixe"])}
      },
      "basis": {
        "Margin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marge"])}
      }
    },
    "picklist": {
      "status": {
        "ORDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande"])},
        "VALIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validation"])},
        "MANUFACTURING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fabrication"])},
        "TRANSFORMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Transformation"])},
        "PREPARATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préparation"])},
        "DELEVERY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
        "ARCHIVING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivage"])}
      },
      "item": {
        "status": {
          "DRAFT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brouillon"])},
          "WAITING_FOR_VALIDATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En attente de validation"])},
          "VALIDATED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Validé"])},
          "CANCELLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annulé"])},
          "DELIVERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livré"])}
        }
      }
    },
    "preconditions": {
      "mainTable": {
        "columns": {
          "listOfPreconditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste de préconditions"])},
          "preconditionsToTreat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préconditions à traiter"])},
          "preconditionsToBeValidated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Préconditions à validées"])}
        }
      },
      "documents": {
        "columns": {
          "precondition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé précondition"])},
          "expectedDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Documents Attendus"])},
          "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de réception"])},
          "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
          "commentary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commentaire"])},
          "numberVN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N° VN"])}
        }
      }
    },
    "financing": {
      "typeFinancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de financement"])},
      "labelFinancing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé de financement"])},
      "financingOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisme de financement"])},
      "scale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Échelle de financement "])},
      "offerReference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence de l'offre"])},
      "amountToBeFinancedTTC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à financer TTC"])},
      "amountToBeFinancedHT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant à financer HT"])},
      "contribution": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apports"])},
      "premiumFirstRent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier loyer majoré"])},
      "numberOfInsurance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre d'assurances"])}
    },
    "asset": {
      "dialog": {
        "label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des Biens en stock"])},
        "moreInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Plus d'info"])},
        "lessInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moins d'info"])}
      },
      "messages": {
        "selectAssetError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez choisir un asset!"])}
      },
      "inventoryFilter": {
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])}
      },
      "inventoryCols": {
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
        "model": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dscription"])},
        "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
        "currentMileage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kilométrage actuel"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Couleur"])},
        "activity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activité"])},
        "immatriculation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Num Immatriculation"])},
        "immatriculationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d’immatriculation"])},
        "constructionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de construction"])},
        "numberOfDoors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de portes"])},
        "numberOfSeats": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de places"])},
        "numberOfCylinders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de cylindres"])},
        "gearType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type de vitesse"])},
        "horsePower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance en CV"])},
        "taxHorsePower": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puissance en CV fiscal"])},
        "bodywork": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carosserie"])},
        "chassisNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de Chassis"])},
        "serialNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de série"])},
        "numberOfGears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nombre de vitesse"])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longeur"])},
        "statusField": {
          "RESERVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réservé"])},
          "IN_STOCK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En Stock"])},
          "SOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vendu"])}
        },
        "activityField": {
          "OLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Occasion"])},
          "NEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuve"])}
        }
      },
      "selectAssetFrom": {
        "catalog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catalogue"])},
        "inventory": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stock"])}
      }
    },
    "deliverySchedule": {
      "appointmentDialog": {
        "dialogTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajout rendez-vous"])},
        "object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objet"])},
        "initialDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date initiale"])},
        "startDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date début"])},
        "startHour": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure début"])},
        "clientName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de client"])},
        "brand": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marque"])},
        "range": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modèle"])},
        "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
        "buttons": {
          "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
          "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Annuler"])},
          "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer"])}
        }
      }
    }
  },
  "user": {
    "generalInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations Générales"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Identifiants"])},
    "security": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sécurité"])},
    "actualPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse e-mail"])},
    "accountDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du compte"])},
    "accountEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte activé"])},
    "userWhoEnabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur activé"])},
    "enabledDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'activation"])},
    "accountExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte expiré"])},
    "userWhoExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur expiré"])},
    "expiredDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'expiration"])},
    "accountLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte verrouillé"])},
    "userWhoLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur verrouillé"])},
    "lockedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de verrouillage"])},
    "accountRevoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte révoqué"])},
    "userWhoRevoked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur révoqué"])},
    "revokedDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de révocation"])},
    "creationDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de création"])},
    "creationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de création"])},
    "userOfCreation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur de la création"])},
    "updateDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de mise à jour"])},
    "userOfModification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisateur de la modification"])},
    "resetPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser le mot de passe"])},
    "otpVerification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérification OTP"])},
    "verificationOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option de vérification"])},
    "authorization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autorisation"])},
    "associatedGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Groupes associés"])},
    "team": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Équipes"])},
    "network": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réseau"])},
    "networkNode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nœud de réseau"])},
    "userPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe utilisateur"])},
    "teamResponsible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Responsable équipe"])},
    "temporaryPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe temporaire"])},
    "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
    "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verrouillé"])},
    "accountExpiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration du compte"])},
    "credentialsExpiry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration des identifiants"])},
    "reinitializedPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe réinitialisé"])},
    "createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Créé par"])},
    "updatedBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["modifié par"])},
    "actionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'action"])},
    "actionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'action"])},
    "actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actions"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le mot de passe"])},
    "invalidPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe Invalide"])},
    "invalidConfirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe ne correspond pas"])},
    "regex": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le mot de passe doit comporter au moins 8 caractères, dont au moins une lettre majuscule, un chiffre et un caractère spécial."])},
    "userDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails du profil"])},
    "accountStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut du compte"])}
  },
  "users": {
    "globalesLabels": {
      "actionDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'action"])},
      "actionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Type d'action"])},
      "temporaryPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe réinitialisé"])}
    },
    "filter": {
      "filterLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer"])},
      "username": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
      "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
      "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
      "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
      "status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Statut"])},
      "notExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration du compte"])},
      "credintialsNotExpired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiration des identifiants"])},
      "notLocked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrouillé"])},
      "listOfUsers": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liste des utilisateurs"])},
      "options": {
        "status": {
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])}
        },
        "notLocked": {
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non verrouillé"])},
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verrouillé"])}
        },
        "accountExpity": {
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide"])},
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré"])}
        },
        "credintialsExpired": {
          "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide"])},
          "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré"])}
        }
      }
    },
    "tabs": {
      "userDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de l'utilisateur"])},
      "userLogs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Journal des actions"])}
    },
    "button": {
      "addButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
      "reset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser"])},
      "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])}
    },
    "userLabels": {
      "statusOptions": {
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activé"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désactivé"])}
      },
      "notExpiredOption": {
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré"])}
      },
      "credintialsOption": {
        "true": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Valide"])},
        "false": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Expiré"])}
      }
    },
    "teams": {
      "ANALYSTJUNIOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyst junior"])}
    },
    "network": {
      "BROKERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BROKERS"])},
      "CGI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CGI"])},
      "RENAULT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RENAULT"])},
      "PEUGEOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PEUGEOT"])},
      "GRPCAPITOLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GRPCAPITOLE"])}
    },
    "networkNode": {
      "FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FD"])},
      "Zone_IleDeFrance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zone Ile De France"])},
      "Agence CGI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agence CGI"])},
      "Partners": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partners"])},
      "JEDPLAISANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JEDPLAISANCE"])},
      "Concessionnaire_1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concessionnaire 1"])},
      "Concessionnaire_2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concessionnaire 2"])},
      "Concessionnaire_3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concessionnaire 3"])},
      "Concessionnaire_4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concessionnaire 4"])}
    },
    "error": {
      "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Règle(s) ou champ(s) vide(s) manquant(s) !"])}
    }
  },
  "printDocument": {
    "reference": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Référence"])},
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DOCUMENTS"])},
    "confirmationDialog": {
      "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document pas encore disponible, vous souhaitez attendre?"])},
      "waitTimeout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Document non disponible"])},
      "waitButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Attendre"])}
    }
  },
  "Configuration": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Configuration de l'écran"])},
    "filter": {
      "entityLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Libellé de l'entité"])},
      "entityName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de l'entité"])},
      "entityODM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ODM"])},
      "entityItem": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entité"])},
      "internalCode": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Code interne"])}
    }
  }
}