import {settings} from "@/settings";
import {api} from "@/auth/api";
import {
    ActionType,
    GatheringProcess,
    Offer,
    receivedFile,
    RequiredDocumentType, RequiredDocumentTypeResponse,
    supportingDocuments,
    Task,
    TaskExecuteAction
} from "@/types";
import {executeTask} from "../workflow";
import store from "@/store";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import {headers} from "@/utils";
import axios from "axios";

const odm_offer = 'odm-offer'
const odm_supportingdocument = 'odm-supportingdocument'


export const getOfferDocument = async (entityId: any) => {
const baseUrl = `${settings.api_url}/${odm_offer}/api/1/${odm_offer}`
return new Promise<any>(async (resolve, reject) => {
try {
const result = await api().get(`${baseUrl}/offer/` + entityId + '/associatedgatheringprocess/')
resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}

export const getRequiredDocument = async (processId: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().get(`${baseUrl}process/` + processId + '/requiredDocumentType/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const getProcess = async (processId: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
    return new Promise<GatheringProcess>( async (resolve, reject) => {
        try {
            const result = await api().get(`${baseUrl}process/` + processId + '/')
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const receiveFile = async (processId: any, docId: any, file: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
    const body = file
    return new Promise<receivedFile>(async (resolve, reject) => {
        try {
            const result = await api().post(`${baseUrl}/process/` + processId + `/document/` + docId + `/file/`, body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const qualifyFile = async (processId: any, docId: any, fileId: any, file: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
    const body = file
    return new Promise<receivedFile>(async (resolve, reject) => {
        try {
            const result = await api().post(`${baseUrl}/process/` + processId + `/document/` + docId + `/file/` + fileId + `/qualification/`, body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const manualAddDocument = async (processId: any, file: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}/`
    const body = file
    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().post(`${baseUrl}/process/` + processId + `/supporting/`, body)
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export const sendToControl = async (processId: any) => {
    const baseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`

    return new Promise<any>(async (resolve, reject) => {
        try {
            const result = await api().put(`${baseUrl}/process/` + processId + `/control/`, {})
            resolve(result.data)
        } catch (e) {
            reject(e)
        }
    });
}
export async function executeUploadDocumentAction(task: Task) {
    const qualified = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`);
    const valid = i18n.global.t(`task.uploadDocument.headers.supportingDocumentStatus.VALID`);
    const gatheringProcess = store.state.taskModule.gatheringProcess;

    const excludedDocumentType = ['CONTRAT_SIGNE_PP', 'CONTRAT_SIGNE_PM'];

    const validation = gatheringProcess
        .flatMap((gp: any) => gp.entityTargets)
        .flatMap((targetEntity: any) => targetEntity.documents)
        .filter((doc: any) => !excludedDocumentType.includes(doc.documentType))         
        .some((doc: any) => doc.status !== qualified || doc.status !== 'QUALIFIED' || doc.status !== valid || doc.status !== 'VALID');
    let validationFILE = true;
    for (const gp of gatheringProcess) {
        for (const targetEntity of gp.entityTargets) {
            for (const doc of targetEntity.documents) {
                if (
                    !excludedDocumentType.includes(doc.documentType) && 
                    (Object.keys(doc.document).length === 0 || doc.document.originalFileName === null)
                ) {
                    validationFILE = false;
                    break;
                }
            }
        }
    }

    if (validation && validationFILE) {
        const sentToControl: string[] = [];
        for (const gp of gatheringProcess) {
            for (const targetEntity of gp.entityTargets) {
                for (const doc of targetEntity.documents) {
                 
                    if (
                      !excludedDocumentType.includes(doc.documentType) && 
                      sentToControl.indexOf(doc.processResourceUid) < 0
                    ) {
                        await store.dispatch('taskModule/uploadDocumentModule/sendToControl', {
                            resourceUid: doc.processResourceUid,
                        });
                        sentToControl.push(doc.processResourceUid);
                        Notify.create({
timeout: 5000,
            actions: [{ icon: 'close', color: 'white' }],
                            message: i18n.global.t("main.dialog.validation.success"),
                            color: 'positive',
                        });
                    }
                }
            }
        }

        const taskExecuteAction: TaskExecuteAction = {
            taskId: task.taskId || "",
            variables: {},
        };

        try {
            return executeTask(taskExecuteAction);
        } catch (e) {
            console.error(e);
            return Promise.reject(e);
        }
    } else {
        Notify.create({
            color: 'negative',
            message: 'List incomplete',
            timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
        });
    }

    return validation;
}


export const getRequiredDocumentModifyCondition = async (processId: any) => {
    const requiredDocumentTypeList: RequiredDocumentType[] = []
    return new Promise<RequiredDocumentType[]>(async (resolve, reject) => {
        const qc_header: any = {
            qc: {
                queryId: 'supportingdocument-required-document-type',
                offset: 0,
                limit: 1000000,
            }
        }
        qc_header.qc.parameters = {
            process_id: processId
        }

        const _headers = headers({
            qc: JSON.stringify(qc_header.qc)
        })
        axios.get(settings.api_query_url, {
            headers: _headers
        }).then(res => {
            if (res.data.length) {

                res.data.forEach((item: RequiredDocumentTypeResponse) => {

                    let requiredDocumentType: RequiredDocumentType = {
                        resourceUid: item.doc_type_id,
                        objectType: 'odm.supportingdocument.documenttype',
                        systemUid: 'odm-supportingdocument',
                        businessData: null,
                        daaq: '/',
                        supportingType: [
                            {
                                resourceUid: item.supporting_type_id,
                                objectType: 'odm.supportingdocument.supportingtype',
                                systemUid: 'odm-supportingdocument',
                                businessData: null,
                                daaq: '/',
                                proofLevel: item.proof_level ? +item.proof_level : 0,
                                manual: item.manual === 'true' ? true : false,
                                supportingDocument: {
                                    objectType: 'odm.supportingdocument.supportingdocumentfamily',
                                    systemUid: 'odm-supportingdocument',
                                    resourceUid: item.supporting_document_code,
                                },
                            }]
                    }
                    requiredDocumentTypeList.push(requiredDocumentType)
                })

                resolve(requiredDocumentTypeList)
            } else {
                reject('error get email sales person')
            }
        }).catch(err => reject(err))
    })
}
