import { Module } from "vuex";

import { Address, ContactType, IContactMechanism, PartyAddress, Relation, ThirdState, ThirdType, unreadValues } from "@/types";
import { getPartyClass, getPartyRelations, saveThird } from "@/store/services/thirdService";
import Notify from 'quasar/src/plugins/Notify.js';
import store from "@/store";
import { getThird } from "@/store/services/searchThird";
import { deepClone, globalConfig, mountSelectFields, mountSelectFieldsRecursively, unmountSelectFields } from "@/utils";
import moment from "moment";
import i18n from "@/i18n";
import { formatDate, formatDateLocale } from "@/utils/configuration/formatters-config";
import { toUpperCase } from "uri-js/dist/esnext/util";
import state from "vue-slider-component/typings/utils/state";

const thirdModule: Module<ThirdState, any> = {
  namespaced: true,
  state: {
    third: {},
  },
  getters: {
    getResourceUid(state) {
      return state.third?.id?.resourceUid
    },
    getDeletedRelationUid(state: ThirdState) {
      return state.deletedRelationUid;
    },
    getThirdInit() {
      return {
        id: {
          resourceUid: '',
          objectType: '',
          systemUid: ''
        },
        type: { id: "party-FrenchOrganization", label: "Personne morale" },
        person: {
          title: {
            objectType: 'odm.party.persontitle',
            type: {
              value: '',
              label: ''
            }
          },
          taxGeographicZone: 'France_M',
          birthChildren: [],
          nationality: {
            type: {
              value: '',
              label: ''
            }
          },
          currentMaritalStatus: {
            type: {
              value: '',
              label: ''
            }
          },
          currentMatrimonialRegime: {
            type: {
              value: '',
              label: ''
            }
          },
          gender: {
            type: {
              value: '',
              label: ''
            }
          },
          maritalStatuses: [],
          nbOfDependent: '',
          nbOfChildren: '',
          homemakers: '',
          retirementPassage: '',
          birthplace: {
            type: {
              id: "",
              label: "",
              config: {
                city: "",
                country: {
                  id: "",
                  label: ""
                },
              }
            },
            postalCode: '',
            country: {
              name: '',
              objectType: 'odm.party.country'
            },
            inseeCode: '',
            region: '',
            department: '',
            city: '',
            objectType: 'odm.party.postalcode',
          },
          firstName: '',
          familyName: '',
          birthDate: '',
          contacts: [],
          ids: [],
          jobs: [],
          revenues: [],
          accounts: [],

          addresses: [],
          correspondents: [],
          classifications: [],
          reference: '',
          financialExposures: []
        },
        relations: [],
        accounts: [],
        financialExposures: [],
        correspondents: [],
        organization: {
          siren: '',
          name: '',
          codeApe: '',
          acronym: '',
          isCreated: true,
          commercialRegister: '',
          creationDate: '',
          numTVAIntraCom: '',
          activitySector: {
            objectType: 'odm.party.activitysector',
            resourceUid: '',
            type: {
              value: undefined,
              label: ''
            }
          },
          codeNACE: {
            type: {
              value: '',
              label: ''
            }
          },
          legalCategory: {
            objectType: 'odm.party.organizationlegalcategory',
            resourceUid: '',
            type: {
              value: '',
            }
          },
          contacts: [],
          facilities: [],
          addresses: [],
          balanceSheets: [],
          correspondents: [],
          classifications: [],
          reference: '',
          financialExposures: []
        },
        addresses: [],
        roles: [],
        classifications: [],
        isDisabled: true,
      }
    },
    getSituationInit() {
      return {
        validity: {
          from: '',
          until: ''
        },
        maritalStatus: {},
        matrimonialRegime: {}
      }
    },
    getThirdAnalysisInit() {
      return {
        label: "",
        analysisDate: "",
        status: {
          type: {
            value: '',
            label: ''
          }
        },
        yearlyExpensesTotal: {
          currency: "EUR",
          amount: 0
        },
        yearlyIncomeTotal:{
          currency: "EUR",
          amount: 0
        },
        detail: [{ ...store.getters["thirdModule/getIncomeInit"] }, { ...store.getters["thirdModule/getExpenseInit"] }],
        isDisabled: false,
      }
    },
    getIncomeInit() {
      return {
        type: 'INCOME',
        nature: {
          type: {
            label: '',
            value: ''
          }
        },
        periodicity: {
          type: {
            label: '',
            value: ''
          }
        },
        value: '',
        label: "",
        validity: {
          from: '',
          until: ''
        },
        isDisabled: false,
      }
    },
    getExpenseInit() {
      return {
        type: 'EXPENSE',
        nature: {
          type: {
            label: '',
            value: ''
          }
        },
        periodicity: {
          type: {
            label: '',
            value: ''
          }
        },
        value: '',
        label: "",
        validity: {
          from: '',
          until: ''
        },
        isDisabled: false,
      }
    },
    getThirdJobsInit() {
      return {
        positionType: {
          type: {
            id: "",
            label: ""
          },
        },
        occupationType: {
          type: {
            id: "",
            label: ""
          },
        },
        contractType: {
          type: {
            id: '',
            label: ''
          }
        },
        activity: {
          type: {
            id: "",
            label: ""
          },
        },
        validity: {
          from: '',
          until: ''
        },
        nbYearExperienceActivity: '',
        nbYearExperiencePosition: '',
        employer: {},
        jobLocation: { ...store.getters["thirdModule/getAddressInit"] },
        employerName: '',
        jobName: "",
        principal: false,
        nbHour: '',
        employerCreationDate: '',
        tenureDate: '',
        isDisabled: true
      }
    },
    getThirdSituationInit() {
      return {
        person: {
          currentMaritalStatus: {
            type: {
              value: '',
              label: ''
            }
          },
          currentMatrimonialRegime: {
            type: {
              value: '',
              label: ''
            }
          },
          nbOfDependent: '',
          nbOfChildren: '',
          homemakers: '',
          retirementPassage: ''
        },
      }
    },
    getRoleInit() {
      return {
        role: {
          type: {
            value: '',
            label: '',
          }
        },
        status: {
          type: {
            value: 'ACTIVATED',
            label: '',
          }
        },
        validity: {
          from: moment().format('DD/MM/YYYY'),
          until: ''
        },
        preferences: [],
        facilityPreferences: [],
        nonSolicitation: false,
        isDisabled: true
      }
    },
    getRoleEmpty() {
      return {
        role: {
          type: {
            value: '',
            label: '',
          }
        },
        status: {
          type: {
            value: '',
            label: '',
          }
        },
        validity: {
          from: '',
          until: ''
        },
        nonSolicitation: '  ',
        isDisabled: true
      }
    },
    getPartyClassificationInit() {
      return {
        partyClass: {
          objectType: 'odm.party.partyclass',
          type: {
            value: "",
            label: ""
          }
        },
        validity: {
          from: '',
          until: ''
        },
        classificationDate: formatDate(new Date(), '/').toString(),
        isDisabled: true
      }
    },
    getContactMechanismInit(): IContactMechanism {
      return {
        _controls: {
          attToRemove: ['businessData'],
        },
        type: {
          id: ContactType.EMAIL_CONTACT,
          label: "E-mail",
          config: {
            objectType: "odm.party.contactmechanism.emailcontact",
            fields: ["emailAddress", "objectType", "systemUid", "resourceUid", "validity", "nonSolicitation", "consent", "facility", "daaq"]
          }
        },
        objectType: '',
        emailAddress: '',
        identifier: '',
        phoneNumber: '',
        acceptSMS: false,
        nonSolicitation: false,
        isDisabled: true,
        daaq: "/"
      }
    },
    getThirdIdProofInit() {
      return {
        reference: '',
        emissionDate: '',
        authority: '',
        validity: {
          from: '',
          until: ''
        },
        documentType: {
          type: {
            value: '',
            label: '',
          },


        },
        supportingDocument: null,
        objectType: 'odm.party.identitydocument',
        isDisabled: true
      }
    },
    getFrenchFacilityInit() {
      return {
        _controls: {
          attToRemove: ['isDisabled'],
        },
        facilityType: {
          type: {
            value: '',
          }
        },
        legalId: '',
        activity: {
          from: '',
          until: ''
        },
        name: '',
        address: { ...store.getters["thirdModule/getAddressInit"] },
        siret: '',
        objectType: 'odm.party.facility.frenchfacility',
        isDisabled: true
      }
    },
    getFrenchFacilityEmpty() {
      return {

        facilityType: {
          type: {
            value: '',
          }
        },
        legalId: '',
        activity: {
          from: '',
          until: ''
        },
        name: '',
        address: {},
        siret: '',
        objectType: 'odm.party.facility.frenchfacility',
        isDisabled: true
      }
    },
    getAddressInit(): Address {
      return {
        type: {
          type: {
            value: [],
          },
          objectType: 'odm.party.addresstype',
          resourceUid: ''
        },
        line1: '',
        line2: '',
        line3: '',
        territory: '',
        postalCode: {
          type: {
            value: "",
            label: "",
            config: {
              city: "",
              country: { id: "" },
            }
          },
          postalCode: '',
          country: {
            name: '',
            objectType: 'odm.party.country'
          },
          inseeCode: '',
          region: '',
          department: '',
          city: '',
          objectType: 'odm.party.postalcode',
        },

        objectType: 'odm.party.address',
        isDisabled: true,
        daaq: "/"
      }
    },
    getAccountEmpty() {
      return {
        bankAccount: {
          type: {
            id: '',
            label: ''
          },
          status: {
            id: '',
            label: ''
          },
          validity: {
            from: '',
            until: ''
          },
          isPreferred: ' '
        }
      }
    },
    getAccountInit() {
      return {
        bankAccount: {
          type: {
            id: 'DEPOSIT',
            label: i18n.global.t(`party.account.type.deposit`)
          },
          status: {
            id: 'ACTIVE',
            label: i18n.global.t(`party.account.statut.active`)
          },
          validity: {
            from: '',
            until: ''
          },
          isPreferred: false
        }
      }
    },
    getRelationInit() {
      return {
        objectType: 'odm.party.partyrelation',
        systemUid: 'odm-party',
        relation: {
          objectType: 'odm.party.relation',
          type: {
            value: '',
            label: ''
          }
        },
        relationType: {
          objectType: 'odm.party.relationtype',
          type: {
            value: '',
            label: ''
          }
        },
        validity: {
          from: moment(new Date()).format('DD/MM/YYYY'),
          until: ''
        },

      }
    },
    getRelationEmpty() {
      return {
        objectType: 'odm.party.partyrelation',
        systemUid: 'odm-party',
        relation: {
          objectType: 'odm.party.relation',
          resourceUid: '',
          type: {
            value: '',
            label: ''
          }
        },
        relationType: {
          objectType: 'odm.party.relationtype',
          resourceUid: '',
          type: {
            value: '',
            label: ''
          }
        },
        validity: {
          from: '',
          until: ''
        },

      }
    },
    getExpossureInit() {
      return {
        outstanding_Entity: {
          value: "",
          label: ""
        },
        financialInstitution: {
          value: "",
          label: ""
        },
        outstandingType: {
          value: "",
          label: ""
        },
        grossOutstanding: '',
        netOutstanding: '',
        residualValue: '',
        effectiveDate: '',
        contractReference: '',
        contractValidity: {
          from: '',
          until: ''
        },
        flagFinancialPool: false,
        flagRiskPool: false,
        shareFinancialPool: '',
        shareRiskPool: ''
      }
    },
    getBalanceSheetInit() {
      return {
        status: {
          _controls: {
            attToRemove: ['id', 'label'],
          },
          id: "NEW",
          label: ""
        },
        label: '',
        country: {
          _controls: {
            attToRemove: ['id', 'label'],
          },
          id: 'FRA',
          label: 'France',
        },
        period: {
          from: '',
          until: ''
        },
        supportingDocument: null,
        detail: [],
      }
    },
    getBalanceSheetDetailInit() {
      return {
        nature: {
          id: "",
          label: ""
        },
        label: '',
        value: {
          amount: '',
          currency: 'EUR'
        },
      }
    },
    getBalanceSheetEmpty() {
      return {
        status: {

          id: "",
          label: ""
        },
        label: '',
        country: {
          id: '',
          label: '',
        },
        period: {
          from: '',
          until: ''
        },
        supportingDocument: null,
        detail: [],
      }
    },
    getBalanceSheetDetailEmpty() {
      return {
        nature: {
          id: "",
          label: ""
        },
        label: '',
        value: {
          amount: '',
          currency: 'EUR'
        },
      }
    },
    getCorrespondentInit() {
      return {
        firstName: '',
        middleName: '',
        familyName: '',
        status: { objectType: 'odm.party.partycorrespondentstatus' },
        signingAuthority: false,
        contactCorrespondent: [{ ...store.getters["thirdModule/getContactCorrespondentInit"] }],
        qualificationType: {
          objectType: 'odm.party.qualificationtype',
          resourceUid: ''
        },
        isDisabled: true,
        daaq: "/"
      }
    },
    getCorrespondentEmpty() {
      return {
        firstName: '',
        middleName: '',
        familyName: '',
        status: { objectType: 'odm.party.partycorrespondentstatus' },
        signingAuthority: '',
        contactCorrespondent: [],
        qualificationType: {
          objectType: 'odm.party.qualificationtype',
          resourceUid: ''
        },
        isDisabled: true,

      }
    },
    getContactCorrespondentInit() {
      return {
        daaq: '/',
        contactMechanism: { ...store.getters["thirdModule/getContactMechanismInit"] }
      }
    },
    getThirdContactInit() {
      return {
        contactMechanism: { ...store.getters["thirdModule/getContactMechanismInit"] },
        daaq: '/'
      }
    },
    getPartyAddressInit(): PartyAddress {
      return {
        daaq: "/",
        address: { ...store.getters["thirdModule/getAddressInit"] },
        livingSituation: {
          type: {
            value: '',
            label: ''
          }
        },
      }
    }
  },

  actions: {
    recoverState({ commit }, payload) {
      commit('setRecoverState', payload)
    },
    cleanState({ commit }, payload) {
      commit('setInitial', payload)
    },
    executeSaveThird({ commit }, body) {
      const payload = deepClone(body);
      unmountSelectFields(payload);
      saveThird(payload).then(res => {
        Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
          message: i18n.global.t('main.dialog.success', { entity: res.id?.resourceUid }),
          color: 'positive'
        })
        commit('setThirdSelected', res);
      }).catch(err => {
        console.error(err)
        Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
          message: `Error saving third \n ${err}`,
          color: 'negative'
        });
      })
    },
    getPartyClass({ commit }, payload) {
      const { filter, callback } = payload
      getPartyClass(filter)
        .then(res => {
          callback(res);
        })
        .catch(e => console.error(e))
    },
    changeThird({ commit }, payload) {
      const { third } = payload;
      commit('setThirdSelected', third)
      //allows you to update the current context with the third party that was retrieved from the server
      this.dispatch("setCurrentContext", third);
      //-----------------------------------
    },
    executeSelectThird({ commit }, payload) {
      const { id, type, whenCreated, callback } = payload;      
      getThird(id, type, whenCreated).then(third => {
        mountSelectFields(third, ['legalCategory', 'activitySector'])
        if (third.organization && third.organization.facilities) {
          for (const facility of third.organization.facilities) {
            if (!facility.address) {
              facility.address = { ...store.getters["thirdModule/getAddressInit"] }
            }
          }
        }
        if (third.person && third.person.birthplace && third.person.birthplace.type) {
          if (!third.person.birthplace.type.config) {
            third.person.birthplace.type.config = {
              city: "",
              country: {
                id: "",
                label: ""
              },
            }
          }

        }
        commit('setThirdSelected', third)
        //permet de mettre à jour le contexte courant avec le tier qui a été récupérée depuis le serveur
        this.dispatch("setCurrentContext", third);
        //-----------------------------------
        if (callback) {
          callback(third)
        }
      }).catch(err => {  
        Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
          message: `Error searching third \n ${err}`,
          color: 'negative'
        });
      })
    },
    executeAddThirdAddress({ commit }, payload) {
      const { address } = payload;
      commit('addThirdAddress', address)
    },
    getPartyRelations({ commit }, payload) {
      getPartyRelations(payload).then(res => {
        commit('setPartyRelations', res)
      }).catch(err => {
        Notify.create({
timeout: 10000,
            actions: [{ icon: 'close', color: 'white' }],
          message: `Error getting party relations \n ${err}`,
          color: 'negative'
        });
      })
    },
    changeBirthDate({ commit }, payload) {
      const { age } = payload;
      commit('setBirthDate', age)
    },
    changeAddresses({ commit }, { address, index }) {
      commit('setAddresses', { address, index })
    },
    changeFacilties({ commit }, { address, index }) {
      commit('setFacilities', { address, index })
    }
  },
  mutations: {
    setAddresses(state, { address, index }) {
      if (
        state.third &&
        state.third.addresses &&
        index < state.third.addresses.length
      ) {
        if (address) {
          const resourceUidPartyPartyAddress = state.third.addresses[index].resourceUid || undefined
          state.third.addresses[index] = {
            daaq: '/',
            address: address,
            resourceUid: resourceUidPartyPartyAddress
          }
        }
      }
    },
    setFacilities(state, { address, index }) {
      if (
        state.third?.organization?.facilities &&
        index < state.third.organization.facilities.length
      ) {
        state.third.organization.facilities[index].address = address
      }
    },
    setRecoverState(state, payload) {
      const prevState = localStorage.getItem(globalConfig.storageKeys.thirdModule)
      if (prevState) {
        state.third = JSON.parse(prevState).third;
      }
    },
    setBirthDate(state, payload) {
      if (state.third?.person) {
        state.third.person.age = payload
      }
    },
    setInitial(state, payload) {
      delete state.third;
    },
    setThirdSelected(state, payload) {
      console.log('updates', payload)
      mountSelectFieldsRecursively(payload, unreadValues)
      console.log('updates', payload)
     
      state.third = payload
    },
    setPartyRelations(state, payload) {
      if (!state.third?.relations) {
        state.third = { ...state.third, relations: [] }
      }
      let relations = state.third?.relations;
      if (payload.length === 0) {
        relations = [{ ...this.newRelation }]
      }

      function buildRelation(item: any, relation: string, name: string): Relation {
        return {
          objectType: 'odm.party.partyrelation',
          systemUid: 'odm-party',
          resourceUid: item.id,
          name: name,
          partyAsc: {
            resourceUid: item.party_asc_id
          },
          partyDesc: {
            resourceUid: item.party_desc_id
          },
          relation: {
            type: {
              value: relation,
            }
          },
          relationType: {
            objectType: 'odm.party.relationtype',
            resourceUid: item.relation_type_code,
            type: {
              value: item.relation_type_code
            }
          },
          validity: {
            from: item.validity_from,
            until: item.validity_until
          },

        };
      }
      const listRelation = []

      for (const item of payload) {
        const getOrganizationName = !item.name ? item.commercial_name : item.name;
        const name: string = item._discriminator === ThirdType.ORGANIZATION ? getOrganizationName : item.family_name
        if (state.third?.id?.resourceUid === item.party_desc_id) {
          listRelation?.push(buildRelation(item, 'ascending', name))
        }
        else {
          listRelation?.push(buildRelation(item, 'descending', name))
        }
      }

      state.third.relations = listRelation
    },
    setSaveThirdResponse(state, payload) {
      state.third = payload
    },
    setResourceUid(state, payload) {
      if (state.third?.id) state.third.id = payload.id
    },
    setDeletedRelationUid(state, payload) {
      if (state.deletedRelationUid) state.deletedRelationUid = payload;
    }
  },
}

export default thirdModule;
