import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "d-block mb-0 small text-muted" }
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "d-block mb-0 small text-muted" }
const _hoisted_12 = ["id"]
const _hoisted_13 = { class: "d-block mb-0 small text-muted" }
const _hoisted_14 = ["id"]
const _hoisted_15 = { class: "d-block mb-0 small text-muted" }
const _hoisted_16 = ["id"]
const _hoisted_17 = { class: "d-block mb-0 small text-muted" }
const _hoisted_18 = { class: "row items-center justify-end" }
const _hoisted_19 = ["id"]
const _hoisted_20 = { class: "d-block mb-0 small text-muted" }
const _hoisted_21 = { class: "row items-center justify-end" }
const _hoisted_22 = ["id"]
const _hoisted_23 = { class: "d-block mb-0 small text-muted" }
const _hoisted_24 = ["id"]
const _hoisted_25 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_toggle = _resolveComponent("q-toggle")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_list, { class: "position-relative" }, {
    default: _withCtx(() => [
      _createVNode(_component_q_expansion_item, {
        "switch-toggle-side": "",
        "header-class": "card-header bg-transparent no-border p-4",
        class: _normalizeClass(["card tw-items-dashed-group tw-items-group-with-bg", 'section-account' + _ctx.tabPosition]),
        modelValue: _ctx.showAccountExpense,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.showAccountExpense) = $event))
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("middleOffice.party.organization.section.account")), 1)
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.accountsModel, (value, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "p-2 bg-white default-border-radius mb-3",
                key: index
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-lg-3 col-md-6 col-sm-12 form-control no-border", { 'disabled': _ctx.isMo() }]),
                        id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_country`
                      }, [
                        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.country")), 1),
                        _createVNode(_component_q_select, {
                          dense: "",
                          outlined: "",
                          modelValue: _ctx.countrySelected.label,
                          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.countrySelected.label) = $event))
                        }, null, 8, ["modelValue"])
                      ], 10, _hoisted_8),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-lg-3 col-md-6 col-sm-12 form-control no-border", { 'disabled': _ctx.isMo() }]),
                        id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_iban`
                      }, [
                        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.iban")), 1),
                        _createVNode(_component_q_input, {
                          "data-testid": "third_edit_account_iban",
                          dense: "",
                          outlined: "",
                          mask: _ctx.ibanMask,
                          "fill-mask": "",
                          modelValue: value.bankAccount.iban,
                          "onUpdate:modelValue": ($event: any) => ((value.bankAccount.iban) = $event),
                          rules: [
                      (val) => _ctx.required(val, 'IBAN est obligatoire'),
                      (val) =>
                        _ctx.lengthMin(val, 26, 'IBAN doit contenir plus de 27 caractères'),
                      (val) => _ctx.validateIban(val),
                    ]
                        }, null, 8, ["mask", "modelValue", "onUpdate:modelValue", "rules"])
                      ], 10, _hoisted_10),
                      (_ctx.bankName)
                        ? (_openBlock(), _createElementBlock("div", {
                            key: 0,
                            class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                            id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_bankName`
                          }, [
                            _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.bankName")), 1),
                            _createVNode(_component_q_input, {
                              dense: "",
                              outlined: "",
                              modelValue: _ctx.bankName.bankName,
                              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.bankName.bankName) = $event)),
                              class: _normalizeClass({ 'disabled': _ctx.isMo() })
                            }, null, 8, ["modelValue", "class"])
                          ], 8, _hoisted_12))
                        : _createCommentVNode("", true),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-lg-3 col-md-6 col-sm-12 form-control no-border", { 'disabled': _ctx.isMo() }]),
                        id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_bic`
                      }, [
                        _createElementVNode("label", _hoisted_15, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.bic")), 1),
                        _createVNode(_component_q_input, {
                          "data-testid": "third_edit_account_bic",
                          dense: "",
                          outlined: "",
                          modelValue: value.bankAccount.bic,
                          "onUpdate:modelValue": [($event: any) => ((value.bankAccount.bic) = $event), ($event: any) => (_ctx.updateBic(index, $event))],
                          "model-value": value.bankAccount.bic,
                          maxlength: "11",
                          mask: _ctx.bicMask,
                          rules: [
                      (val) => _ctx.required(val, 'Le bic est obligatoire'),
                      (val) =>
                        _ctx.lengthMin(val, 8, 'Le bic doit contenir plus de 8 caractères'),
                    ]
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "model-value", "mask", "rules"])
                      ], 10, _hoisted_14),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-lg-3 col-md-6 col-sm-12 form-control no-border", { 'disabled': _ctx.isMo() }]),
                        id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_validityStartDate`
                      }, [
                        _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.from")), 1),
                        _createVNode(_component_q_input, {
                          onChange: ($event: any) => (_ctx.formatDateFrom(value)),
                          dense: "",
                          outlined: "",
                          rules: _ctx.requiredRules,
                          modelValue: value.bankAccount.validity.from,
                          "onUpdate:modelValue": ($event: any) => ((value.bankAccount.validity.from) = $event)
                        }, {
                          append: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "event",
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, {
                                  ref_for: true,
                                  ref: "qDateProxy",
                                  cover: "",
                                  "transition-show": "scale",
                                  "transition-hide": "scale"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      modelValue: value.bankAccount.validity.from,
                                      "onUpdate:modelValue": ($event: any) => ((value.bankAccount.validity.from) = $event),
                                      mask: _ctx.dateMask,
                                      options: (date) =>
                            value.bankAccount.validity.until
                              ? date < _ctx.formatDate(value.bankAccount.validity.until)
                              : true
                            
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_18, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                            color: "primary",
                                            flat: ""
                                          }, null, 8, ["label"]), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "mask", "options"])
                                  ]),
                                  _: 2
                                }, 1536)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onChange", "rules", "modelValue", "onUpdate:modelValue"])
                      ], 10, _hoisted_16),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-lg-3 col-md-6 col-sm-12 form-control no-border", { 'disabled': _ctx.isMo() }]),
                        id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_validityEndDate`
                      }, [
                        _createElementVNode("label", _hoisted_20, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.until")), 1),
                        _createVNode(_component_q_input, {
                          readonly: "",
                          onChange: ($event: any) => (_ctx.formatDateUntil(value)),
                          dense: "",
                          outlined: "",
                          modelValue: value.bankAccount.validity.until,
                          "onUpdate:modelValue": ($event: any) => ((value.bankAccount.validity.until) = $event)
                        }, {
                          append: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              name: "event",
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, {
                                  ref_for: true,
                                  ref: "qDateProxy",
                                  cover: "",
                                  "transition-show": "scale",
                                  "transition-hide": "scale"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      modelValue: value.bankAccount.validity.until,
                                      "onUpdate:modelValue": ($event: any) => ((value.bankAccount.validity.until) = $event),
                                      mask: _ctx.dateMask,
                                      options: (date) =>
                            value.bankAccount.validity.from
                              ? date > _ctx.formatDate(value.bankAccount.validity.from)
                              : true
                            
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_21, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            label: _ctx.$t('task.completeDocument.party.person.financialSituation.close'),
                                            color: "primary",
                                            flat: ""
                                          }, null, 8, ["label"]), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["modelValue", "onUpdate:modelValue", "mask", "options"])
                                  ]),
                                  _: 2
                                }, 1536)
                              ]),
                              _: 2
                            }, 1024)
                          ]),
                          _: 2
                        }, 1032, ["onChange", "modelValue", "onUpdate:modelValue"])
                      ], 10, _hoisted_19),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-lg-3 col-md-6 col-sm-12 form-control no-border", { 'disabled': _ctx.isMo() }]),
                        id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_status`
                      }, [
                        _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.status")), 1),
                        _createVNode(_component_q_select, {
                          dense: "",
                          outlined: "",
                          modelValue: value.bankAccount.status,
                          "onUpdate:modelValue": ($event: any) => ((value.bankAccount.status) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ], 10, _hoisted_22),
                      _createElementVNode("div", {
                        class: _normalizeClass(["col-lg-3 col-md-6 col-sm-12 form-control no-border", { 'disabled': _ctx.isMo() }]),
                        id: `middleOffice_party_${_ctx.tabPosition}_person_account_${index}_isPreferred`
                      }, [
                        _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("middleOffice.party.organization.account.preferred")), 1),
                        _createVNode(_component_q_toggle, {
                          modelValue: value.bankAccount.isPreferred,
                          "onUpdate:modelValue": ($event: any) => ((value.bankAccount.isPreferred) = $event)
                        }, null, 8, ["modelValue", "onUpdate:modelValue"])
                      ], 10, _hoisted_24)
                    ])
                  ])
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["modelValue", "class"])
    ]),
    _: 1
  }))
}