
import { Options, Vue } from "vue-class-component";
import { Emit } from "vue-property-decorator";
import ThirdSearchDialog from "@/components/demand/dialogs/ThirdSearchDialog.vue";
import Comment from "@/components/communication/Comment.vue";
import PartyFinancialSituation from "@/components/middle-office/tabs/party/organization/PartyFinancialSituation.vue";
import TaskPartyClassification from "@/components/middle-office/tabs/party/TaskPartyClassification.vue";
import store from "@/store";
import {
  BirthChildren,
  ExpenseIncome,
  Jobs,
  PartyAddress,
  PartyContactMechanism,
  PartyCorrespondent,
  Person,
  PersonMaritalStatus,
  PicklistItem,
  PostalCode,
  RevenuesAnalysis,
  Third,
  ThirdType,
  customRoles
} from "@/types";
import {
  add,
  addExpandedItem,
  edit,
  getMaskFormat,
  getMaskInput,
  globalConfig,
  Masks,
  remove,
  removeExpandedItem,
  phoneRules
} from "@/utils";
import {
  formatDateLocale,
  formatDateService,
  getYearsOfDate,
  isValidDate,
  getSirenMask,
} from "@/utils/configuration/formatters-config";
import i18n from "@/i18n";
import { toUpperCase } from "uri-js/dist/esnext/util";
import { useRoute } from "vue-router";
import moment from "moment";
import { ref } from "vue";
import twCurrencyInput from "@/components/general/twCurrencyInput.vue";
import twPercent from "@/components/general/twPercent.vue";
import { currencyConfiguration } from "@/utils/configuration/currencyInput-config";
import { formatWithMask } from "@/utils/configuration/formatters-config";
import PartyAccount from "@/components/middle-office/tabs/party/person/PartyAccount.vue";
@Options({
  methods: {
    toUpperCase,
  },
  components: {
    ThirdSearchDialog,
    Comment,
    PartyFinancialSituation,
    TaskPartyClassification,
    twCurrencyInput,
    twPercent,
    PartyAccount,
  },
  props: ["party", "associatedParty", "role", "associatedPartyId","tabPosition"],
  watch: {
    "$i18n.locale"() {
      if (this.party.type.id) {
        this.party.type.label = i18n.global.t(
          "enum.party.type." + this.party.type.id
        );
      }
    },
    "party.person.birthDate"(newValue, oldValue) {
      if (oldValue) {
        this.dispatchUpdatePartyInsurance();
      }
    },
    "party.person.taxGeographicZone": function (newValue) {
      this.handleTaxGeographicZone(newValue);
    },
    "party.organization.taxGeographicZone": function (newValue) {
      this.handleTaxGeographicZone(newValue);
    },
    "party.type"() {
      store.dispatch("financingModule/refreshStateChangePartyType");
    },
    typeThird(value) {
      this.openDialog = value;
    },
    "party.person.birthplace.type.config.country.id": function (newValue) {
      this.country = newValue;
      if (this.party.person) {
        if (
          this.postalCodes &&
          this.postalCodes.filter((f: any) => f.config.country.id === newValue)
            .length === 1
        ) {
          let opt = this.postalCodes?.filter(
            (f: any) => f.config.country.id == this.country
          )[0];
          this.party.person.birthplace.type = {
            value: opt.value,
            label: opt.label,
            config: {
              city: opt.config.city,
              department: opt.config.department,
              country: {
                id: newValue,
                label: opt.config.country.label,
              },
            },
          };
        } else {
          this.party.person.birthplace.type.config.country.id = newValue
        }
      }
    },
    "manager.birthplace.type.config.country.id": function (newValue) {
      this.managerCountry = newValue;
      if (
        this.postalCodes &&
        this.postalCodes.filter((f: any) => f.config.country.id === newValue)
          .length === 1
      ) {
        let opt = this.postalCodes?.filter(
          (f: any) => f.config.country.id == this.managerCountry
        )[0];
        if (this.manager && this.manager.birthplace) {
          this.manager.birthplace.type = {
            value: opt.value,
            label: opt.label,
            config: {
              city: opt.config.city,
              department: opt.config.department,
              country: {
                id: newValue,
                label: opt.config.country.label,
              },
            },
          };
        }
      } else {
        if (this.manager && this.manager.birthplace) {
          this.manager.birthplace.type.config.country.id = newValue
        }
      }
    },
  },
  computed: {
    isIdentifyDisabled: function () {
      return !this.checkEditButtonIdentify;
    },

    isPersonBtnDisabled: function () {
      return this.personBtn;
    },
    isAddressBtnDisabled: function () {
      return this.addressBtn;
    },
    isIdentityDocBtnDisabled: function () {
      return this.identityDocBtn;
    },
    isPersonPhysique: function () {
      return this.party.type.id == ThirdType.PERSON;
    },
    isPersonMorale: function () {
      return this.party.type.id == ThirdType.ORGANIZATION;
    },
    isCommunicationPlusBtnDisabled: function () {
      return this.communicationPlusBtn;
    },
    correspondentsList: function () {
      if (this.party.type.id == ThirdType.PERSON) {
        this.correspondents = this.party.person.correspondents;
      } else {
        this.correspondents = this.party.organization.correspondents;
      }
      return this.correspondents;
    },
    isJobsBtnDisabled: function () {
      return this.personJobsBtn;
    },
    isSituationDisabled: function () {
      return this.situationBtn;
    },
    situationCanBeEdited: function () {
      return !this.checkEditSituation;
    },
    isAnalysisBtnDisabled: function () {
      return !this.analysisBtn;
    },
    isExposureBtnDisabled: function () {
      return !this.ExpossureBtn;
    },
    addressesList: function () {
      if (this.party.type.id == ThirdType.PERSON) {
        this.addresses = this.party.person?.addresses;
      } else {
        this.addresses = this.party?.organization.addresses;
      }
      return this.addresses;
    },
    facilities: function () {
      if (this.party?.organization?.facilities) {
        return this.party?.organization?.facilities;
      }
      return []
    }

  },
})
export default class DemandTabThird extends Vue {
  party?: Third;
  tabPosition?: number;
  correspondents: PartyCorrespondent[] = [];
  ExpossureBtn = true;
  ExpossureItemPlusBtn = false;
  financialExposures: any[] = [];
  name = "DemandTabThird";
  openDialog = "";
  dateMask = "DD/MM/YYYY";
  country = "";
  facilitiesOpened = false;
  companyPlusBtn = false;
  managerCountry = "";
  managerPostalCode = "";
  siretMask = "#####";
  a = "";
  c: any;
  add = add;
  remove = remove;
  edit = edit;
  addExpandedItem = addExpandedItem;
  removeExpandedItem = removeExpandedItem;
  maskFormat = getMaskFormat();
  maskInput = getMaskInput();

  showDialog?: boolean = false;
  showManagerDialog = false;
  checkEditButtonIdentify = true;
  checkEditButtonManager = true;
  personBtn = false;
  checkEditIdentify = true;
  checkEditSignatureMethod = false;
  checkEditManager = false;
  addressBtn = false;
  communicationPlusBtn = false;
  personJobsBtn = false;
  situationBtn = false;
  checkEditSituation = false;
  analysisBtn = true;
  addresses: Array<PartyAddress> = [];
  identityOpened = true;
  signatureMethodOpened = false;
  managerOpened = true;
  associatedParty?: any;
  role?: any;
  selectedPerson = true;
  globalConfig: any = globalConfig;
  phoneContact = store.getters["demandModule/getContactMechanismInit"];
  associatedPartyId!: number
  customRoles = customRoles;
  identityDocBtn = false;
  phoneRules = phoneRules
  removeContacts(index: number, contacts: Array<PartyContactMechanism>) {
    if (contacts) {
      store.state.demandModule?.deletedContacts?.push(contacts[index]);
      contacts.splice(index, 1);
    }
  }

  validateStreetNum(index: number, val: any) {
    if (val && val < 0) {
      if (this.addresses) {
        this.addresses[index].address.streetNum = 0;
      }
    }
  }
  
  addExpandDocumentIdentity(items: Jobs[]) {
    if (items && items.length === 0) {
      const element = store.getters["thirdModule/getThirdIdProofInit"];
      items.push(element);
    }
  }
  get currencyConfiguration() {
    return currencyConfiguration;
  }

  get facilityType() {
    return store.getters['picklistModule/facilityTypeItems']
  }
  get expossureList() {
    if (this.party?.type?.id == ThirdType.PERSON) {
      return this.party?.person?.financialExposures;
    } else {
      return this.party?.organization?.financialExposures;
    }
  }
  get documentIdentity() {
    return this.party?.person?.ids;
  }
  get taskDefinition() {
    return store.getters["taskModule/taskDefinition"];
  }
  changePostalCodePerson(index: number, value: any) {
    if (this.party && this.party.person && this.party.person.jobs) {
      if (this.party.person.jobs[index]) {
        if (this.party.person.jobs[index].jobLocation) {
          this.party.person.jobs[index].jobLocation.postalCode!.city =
            value.config.city;
          this.party.person.jobs[index].jobLocation.postalCode!.country.name =
            value.config.country.label;
          this.party.person.jobs[index].jobLocation.postalCode!.postalCode =
            value.value;
          this.party.person.jobs[index].jobLocation.postalCode!.type = {
            value: value.id,
            label: value.config.city,
          };
        }
      }
    }
  }

  private static populatePostalCode(value: PostalCode, event: PicklistItem) {
    value.city = event.config.city;
    if (value.country) {
      value.country.name = event.config.country.label;
    }
    value.postalCode = event.id;
  }

  formatDateFrom(value: any) {
    value.validity.from = value.validity.from.includes("/")
      ? formatDateLocale(value.validity.from, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
      : formatDateLocale(value.validity.from, "DDMMYYYY", "fr", "DD/MM/YYYY");
  }
  formatDateUntil(value: any) {
    value.validity.until = value.validity.until.includes("/")
      ? formatDateLocale(value.validity.until, "DD/MM/YYYY", "fr", "DD/MM/YYYY")
      : formatDateLocale(value.validity.until, "DDMMYYYY", "fr", "DD/MM/YYYY");
  }

  formatDate(date: string) {
    return formatDateLocale(date, this.dateMask, "fr", "YYYY/MM/DD");
  }

  removeExpanded<T>(index: number, items: T[]) {
    if (items && items.length > 1) {
      items.splice(index, 1);
    }
  }

  addExpandedExpossure(items: any[]) {
    if (!!items && items.length === 0) {
      const element = store.getters["thirdModule/getExpossureInit"];
      this.expossureList?.push(element);
    }
  }

  addExpossure() {
    const element = store.getters["thirdModule/getExpossureInit"];
    this.expossureList?.push(element);
  }

  onChangeFlagFinancialPool(value: any, event: any) {
    if (!event.target) {
      value.shareFinancialPool = null;
    }
  }

  onChangeFlagRiskPool(value: any, event: any) {
    if (!event.target) {
      value.shareRiskPool = null;
    }
  }

  removeExpandedDocIdentity(items: any[]) {
    if (!!items && items.length === 1) {
      items.slice(0, 1);
    }
  }

  get third() {
    return store.state.thirdModule.third;
  }
  get scaleSelected() {
    return store.state.financingModule.scalesModule.scaleSelected;
  }
  get proposalAssets() {
    return store.state.demandModule.assetState.proposalAssets;
  }
  get startDate() {
    if (store.state.demandModule.offer?.validity) {
      const startDate = store.state.demandModule.offer?.validity.from;
      return startDate
        ? startDate
        : ref(formatDateService(new Date(), "-")).value;
    }
  }

  get phonesListSize() {
    return this.contacts?.filter(
      (con) =>
        con.contactMechanism &&
        con.contactMechanism.type?.id?.includes("PHONE_CONTACT")
    ).length;
  }

  handleTaxGeographicZone(geographicalZone: string) {
    if (this.scaleSelected?.taxOnInterest && geographicalZone) {
      const countrySet = this.determineCountry() || 'FRA'
      const requestBody = {
        objectType: "",
        systemUid: "odm-product",
        resourceUid: "",
        businessData: {},
        geographicZone: geographicalZone,
        country: countrySet,
        evaluationDate: this.startDate,
        daaq: "",
      };
      store.dispatch("demandModule/setEvaluatedTaxRate", {
        taxCode: this.scaleSelected?.taxOnInterest,
        requestBody,
        callback: (result: any) => {
          if (this.scaleSelected?.productTax)
            this.scaleSelected.productTax.fixedValue = result[0].taxRate;
        },
      });
    }
  }

  determineCountry() {
    if (this.third?.type?.id?.includes("Organization")) {
      if (this.third?.organization?.addresses)
        return this.third?.organization?.addresses[0]?.address?.postalCode
          ?.country?.resourceUid;
    } else {
      if (this.third?.addresses)
        return this.third?.addresses[0]?.address?.postalCode?.country
          ?.resourceUid;
    }
  }
  calculateAge(dateBirth: string, person: Person): number | undefined {
    if (dateBirth !== "") {
      person.age = getYearsOfDate(
        formatDateLocale(dateBirth, "DD/MM/YYYY", "us", "YYYY-MM-DD")
      );
    }
    return person.age;
  }

  calculateChildrenAge(birthChildren: BirthChildren, person: Person): void {
    if (
      birthChildren.dateOfBirthChildren &&
      isValidDate(birthChildren.dateOfBirthChildren, "DD/MM/YYYY", "fr")
    ) {
      birthChildren.childrenAge = moment().diff(
        formatDateLocale(
          birthChildren.dateOfBirthChildren,
          "DD/MM/YYYY",
          "us",
          "YYYY-MM-DD"
        ),
        "years"
      );
    }
  }

  @Emit("changeDone")
  changeDone(done: boolean) {
    return done;
  }
  get currentRoute() {
    return useRoute().name;
  }
  updateContactList() {
    this.contacts?.push(this.phoneContact);
  }

  roundNumber(event: any, person: Person, number: any) {
    globalConfig.input_rules.ToFixNumber(event, person, number);
  }

  changePostalCodeIdentity(item: any, person: Person) {
    const postalCode = {
      config: item.config,
      label: item.label,
      value: item.value
    }
    if (person.birthplace) {
      person.birthplace.postalCode = postalCode;
      person.birthplace.country = this.country.toString;
      person.birthplace.department = item.config?.department?.toString();
    }
  }
  changePostalCodeManager(item: any, person: Person) {
    if (person.birthplace) {
      person.birthplace.postalCode = item.value;
      person.birthplace.country = this.managerCountry.toString;
    }
  }
  changePostalCodePM(value: PostalCode, event: PicklistItem, index: number) {
    DemandTabThird.populatePostalCode(value, event);
    if (this.facilitis && this.facilitis[index].address) {
      this.facilitis[index].address!.postalCode = value;
    }
  }

  get facilitis() {
    if (this.party?.organization?.facilities) {
      return this.party?.organization?.facilities;
    }

    return []
  }
  changePostalCode(index: number, value: any) {
    if (this.addresses) {
      this.addresses[index].address.postalCode!.city = value.config.city;
      this.addresses[index].address.postalCode!.country!.name =
        value.config.country.label;
      this.addresses[index].address.postalCode!.postalCode = value.value;
      this.addresses[index].address.postalCode!.type = {
        value: value.id,
        label: value.config.city,
      };
    }
  }

  postalCodePayload = (value: string, countryCode?: string) => {
    if (countryCode) {
      return { country_code: countryCode, query: `%${value}%` };
    } else return { query: `%${value}%` };
  };

  filterPostalCodeWithCountry(value: string, update: (el: unknown) => void) {
    update(() => {
      store.dispatch(
        "picklistModule/applyPostalCodeQuery",
        this.postalCodePayload(value, this.country)
      );
    });
  }

  filterPostalCodeManagerWithCountry(
    value: string,
    update: (el: unknown) => void
  ) {
    update(() => {
      store.dispatch(
        "picklistModule/applyPostalCodeQuery",
        this.postalCodePayload(value, this.managerCountry)
      );
    });
  }

  filterPostalCode(value: string, update: (el: unknown) => void) {
    update(() => {
      store.dispatch(
        "picklistModule/applyPostalCodeQuery",
        this.postalCodePayload(value)
      );
    });
  }

  editIdentify() {
    this.checkEditIdentify = !this.checkEditIdentify;
  }

  editSignatureMethod() {
    this.checkEditSignatureMethod = !this.checkEditSignatureMethod;
  }

  editSituation() {
    this.checkEditSituation = !this.checkEditSituation;
  }
  phoneNumber() {
    if (this.party?.person?.contacts && this.contacts) {
      return this.party.person.contacts[0].contactMechanism.phoneNumber !== ""
        ? this.party.person.contacts[0].contactMechanism.phoneNumber
        : this.contacts[0].contactMechanism.phoneNumber;
    }
  }

  addExpandCorrespondent(items: PartyCorrespondent[]) {
    if (items && items.length === 0) {
      const element = store.getters["demandModule/getCorrespondentInit"];
      this.correspondents?.push(element);
    }
  }

  showHideCommunication(value: PartyCorrespondent) {
    value.isDisabled = !value.isDisabled;
    if (value.contactCorrespondent && value.contactCorrespondent.length === 0) {
      add(
        value.contactCorrespondent,
        store.getters["demandModule/getContactCorrespondentInit"]
      );
    }
  }

  searchThird() {
    this.showDialog = true;
    this.openDialog = this.party?.type?.id ?? "party-FrenchPerson";
  }

  searchManager() {
    this.showManagerDialog = true;
    this.openDialog = ThirdType.MANAGER;
  }

  onCloseThird() {
    this.showDialog = false;
    store.dispatch("financingModule/serviceModule/refreshPartyInsurance", {});
  }
  onCloseManager() {
    this.showManagerDialog = false;
    store.dispatch("financingModule/serviceModule/refreshPartyInsurance", {});
  }
  onSelectedItem(row: any) {
    store.dispatch("thirdModule/executeSelectThird", {
      id: row.id,
      type: row._discriminator,
      callback: () => {
        if (this.party) {
          if (row._discriminator === ThirdType.PERSON) {
            this.buildPerson(this.party);
          } else {
            this.buildOrganization(this.party);
            if (this.party.organization?.relations) {
              if (this.manager?.show) this.manager.show = false;
            }
            this.selectedPerson = false;
          }
        }
      },
    });
    this.onCloseThird();
  }
  get typeOutstanding() {
    return store.getters["picklistModule/typeOutstandingItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t(
                "middleOffice.party.organization.typeOutstanding." +
                item.value
              )
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  get financialInstitution() {
    return store.getters["picklistModule/financialInstitutionItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t(
                "middleOffice.party.organization.financialInstitution." +
                item.value
              )
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  get documentType() {
    return store.getters["picklistModule/documentTypeItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t(
                "middleOffice.party.person.identityDoc.docTypes." + item.value
              )
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  get birthPlaceDepartment() {
    return this.party?.person?.birthplace?.department || this.party?.person?.birthplace?.type?.config.department
  }
  private buildPerson(party: Third) {
    party.person = store.state.thirdModule.third?.person;
    if (party.person?.addresses) {
      party.person.addresses = store.state.thirdModule.third?.person?.addresses;
      if (store.state.thirdModule.third?.person?.roles?.length === 0) {
        party.person.roles = [store.getters["demandModule/getRoleInit"]];
      }
    }
  }

  private buildOrganization(party: Third) {
    party.organization = store.state.thirdModule.third?.organization;
    if (party.organization?.addresses) {
      party.organization.addresses =
        store.state.thirdModule.third?.organization?.addresses;
      if (store.state.thirdModule.third?.organization?.roles?.length === 0) {
        party.organization.roles = [store.getters["demandModule/getRoleInit"]];
      }
    }
  }

  onManagerSelected(row: any) {
    store.dispatch("thirdModule/executeSelectThird", {
      id: row.id,
      type: row._discriminator,
      callback: (third: Third) => {
        if (this.manager) {
          this.buildManager(this.manager, third);
        }
      },
    });
    this.showManagerDialog = false;
  }

  private buildManager(manager: Person, third: Third) {
    if (third.person) {
      Object.assign(manager, third.person);
      if (manager.title?.type)
        manager.title.type.value = third.person.title?.type?.value ?? "";
      if (
        manager.contacts &&
        manager.contacts.length > 0 &&
        third.person.contacts &&
        third.person.contacts.length > 0
      ) {
        const contactFound = third.person.contacts
          .filter((contact) =>
            contact.contactMechanism.type?.id?.includes("PHONE_CONTACT")
          )
          .find((contact) => !!contact.contactMechanism);
        if (contactFound) {
          manager.contacts[0].contactMechanism.phoneNumber =
            contactFound?.contactMechanism.phoneNumber;
        } else {
          manager.contacts.unshift(this.phoneContact);
        }
      } else if (manager.contacts) {
        manager.contacts.push(
          store.getters["demandModule/getContactMechanismInit"]
        );
      }
      if (third.person.roles?.find((el) => el.role?.type?.value === "DIRG")) {
        manager.roles = third.person.roles;
      } else {
        third.person.roles?.forEach((el) => manager?.roles?.push(el));
      }
    }
  }

  addExpandJobs(items: Jobs[]) {
    if (items && items.length === 0) {
      const element = store.getters["demandModule/getThirdJobsInit"];
      items.push(element);
    }
  }

  addExpandSituation(items: PersonMaritalStatus[]) {
    if (items && items.length === 0) {
      const element = store.getters["demandModule/getSituationInit"];
      items.push(element);
    }
  }

  removeExpandedJobs(items: Jobs[]) {
    if (!!items && items.length === 1) {
      items.slice(0, 1);
    }
  }

  unshiftMeansOfCommunication<T>(items: T[], getter: T) {
    items.unshift(getter);
  }

  removeBirthChildren(index: number) {
    if (this.party?.person?.birthChildren && this.party?.person?.nbOfChildren) {
      this.party.person.birthChildren.splice(index, 1);
      this.party.person.nbOfChildren--;
    }
  }
  getSirenMask(locale?: string) {
    return getSirenMask(locale);
  }
  updateBirthChildren() {
    console.log('childrenchanged--------------')
    if (this.party?.person?.birthChildren && this.party?.person?.nbOfChildren) {
      if (
        this.party.person.birthChildren.length < this.party.person.nbOfChildren
      ) {
        const maximum = Number(
          this.party.person.nbOfChildren -
          this.party.person.birthChildren.length
        );
        for (let i = 0; i < maximum; i++) {
          this.party.person.birthChildren.push({
            dateOfBirthChildren: "",
            childrenAge: undefined,
          });
        }
      } else if (
        this.party.person.birthChildren.length > this.party.person.nbOfChildren
      ) {
        this.party.person.birthChildren.splice(this.party.person.nbOfChildren);
      }
    }
  }

  disableWhenExistOfferRefAndContactResourceUid(
    offerUid: string,
    contactUid: string
  ) {
    return !!offerUid && !!contactUid;
  }

  get opinionQualifications() {
    return store.getters["taskModule/analysisModule/getOpinionQualifications"];
  }

  get getColumnsRevenusMensuels() {
    return globalConfig.table_config.colsRevenusMensuels;
  }

  orderDetail(detail: ExpenseIncome[]) {
    return detail;
  }

  removeDetail(
    detail: ExpenseIncome[],
    index: number,
    code: string,
    indexAnalysis: number
  ) {
    const details = this.getDetails(detail, code);
    const detailsDifferent = this.getDetailsDifferent(detail, code);
    details.splice(index, 1);
    Array.prototype.push.apply(details, detailsDifferent);
    if (this.party && this.party.person && this.party.person.revenues) {
      this.party.person.revenues[indexAnalysis].detail = details;
    }
  }

  getDetails(detail: ExpenseIncome[], code: string) {
    return detail.filter((el: any) => el.type === code);
  }

  getDetailsDifferent(detail: ExpenseIncome[], code: string) {
    return detail.filter((el: any) => el.type !== code);
  }

  getNatures(code: string) {
    return store.getters["picklistModule/natureItems"]
      ?.filter((item: any) => item.config.internalCode === code)
      .map((item: any) => {
        return {
          ...item,
          label:
            item.label?.toString() !== ""
              ? i18n.global.t("incomeAndExpenses.type." + item.value)
              : "",
        };
      });
  }

  get gender() {
    return store.getters["picklistModule/genderItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t(
                "middleOffice.party.person.identity.gender." + item.value
              )
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  get socioProfessionalCategory() {
    return store.getters["picklistModule/jobOccupationTypeItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? `${item.value} - ${i18n.global.t(
                "picklists.socioProfessionalCode.".concat(item.value)
              )}`
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  get contractTypes() {
    return store.getters["picklistModule/contractTypeItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t(
                "middleOffice.party.person.occupation.occupationTypes." +
                item.value
              )
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  get propertyStatus() {
    return store.getters["picklistModule/propertyStatusItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t(
                "middleOffice.party.person.address.livingstatus." + item.value
              )
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }

  /**
   * Get the nace code options with translated labels.
   *
   * @return {Array<ComboBoxItem>} The translated nace code options.
   */
  get naceOptions() {
    return store.getters["picklistModule/codeNaceOptionsItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? `${item.value} - ${i18n.global.t(
                "picklists.codeNace.".concat(item.value)
              )}`
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  addNewDetail(detail: ExpenseIncome[], type: string) {
    let element = {};
    if (type === "EXPENSE") {
      element = store.getters["demandModule/getExpenseInit"];
    } else {
      element = store.getters["demandModule/getIncomeInit"];
    }
    detail.push(element);
  }

  addAnalysis() {
    const element = store.getters["demandModule/getThirdAnalysisInit"];
    this.analysis?.push(element);
  }

  editAnalysis(value: { isDisabled: boolean }) {
    value.isDisabled = !value.isDisabled;
  }

  removeAnalysis<T>(index: number, items: T[]) {
    if (items && items.length > 0) {
      items.splice(index, 1);
    }
  }

  getNatureIncome() {
    return this.getNatures("INCOME");
  }
  getNatureExpense() {
    return this.getNatures("EXPENSE");
  }

  showHideDetail(value: RevenuesAnalysis) {
    value.isDisabled = !value.isDisabled;
  }

  isValidEmail(val: any) {
    return globalConfig.input_rules.isValidEmail(val);
  }

  checkKeyDownAlpha(event: any) {
    return globalConfig.input_rules.checkKeyDownAlpha(event);
  }

  validateBirthDate(birthDate: string) {
    return globalConfig.input_rules.validateBirthDate(birthDate);
  }

  async dispatchUpdatePartyInsurance() {
    await store.dispatch(
      "financingModule/serviceModule/refreshPartyInsurance",
      {}
    );
    await store.dispatch(
      "financingModule/serviceModule/calculatePartyInsurance"
    );
  }
  changeSignature(event: any) {
    console.log(event);
  }

  get isManager() {
    return this.associatedParty?.role?.resourceUid === "DIRG";
  }

  get isManagerDisabled() {
    return !this.checkEditButtonManager;
  }

  get manager() {
    if (this.offer?.resourceUid && this.party?.organization?.manager) {
      this.party.organization.manager.show = false;
    }
    return this.party?.organization?.manager;
  }

  get disablePartySelection() {
    return (
      this.offer?.resourceUid !== "" &&
      this.associatedParty?.role?.resourceUid === "CUSTOMER"
    );
  }

  /* Picklists */
  get periodicities() {
    return store.getters["picklistModule/periodicityItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.label?.toString() !== ""
            ? i18n.global.t("incomeAndExpenses.periodicity." + item.value)
            : "",
      };
    });
  }

  get clientType() {
    return store.getters["picklistModule/clientTypeItems"]?.map((item: any) => {
      return {
        ...item,
        label:
          item.id?.toString() !== ""
            ? i18n.global.t("enum.party.type." + item.id)
            : "",
      };
    });
  }

  get legalCategory() {
    return store.getters["picklistModule/legalCategoryItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? `${item.value} - ${i18n.global.t(
              `picklists.legal-category.${item.value}`
            )}`
            : "",
      };
    });
  }

  get activitySector() {
    return store.getters["picklistModule/activitySectorItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? `${item.value} - ${i18n.global.t(
              "picklists.activity.".concat(item.value)
            )}`
            : "",
      };
    });
  }

  get countries() {
    return store.getters["picklistModule/countriesItems"];
  }

  get statusesMarital() {
    return store.getters["picklistModule/statusMaritalItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.statusMarital." + item.value)
            : "",
      };
    });
  }
  isPartySupplierOrDistributor() {
    return !customRoles.includes(this.role?.role_code ? this.role?.role_code : this.role?.associatedParty?.role_code)
  }

  get canAccessClassification() {
    return this.$store.getters["authModule/getClassificationAccess"];
  }
  get title() {
    return store.getters["picklistModule/titlesItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.title." + item.value)
            : "",
      };
    });
  }

  get signatureMethods() {
    return store.getters["picklistModule/signatureMethodsItems"]?.map(
      (item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t("demand.party.signatureMethod." + item.value)
              : "",
        };
      }
    );
  }

  get qualificationType() {
    return store.getters["picklistModule/qualificationTypeItems"].map(
      (item: any) => {
        return {
          ...item,
          label:
            item.label?.toString() !== ""
              ? i18n.global.t(
                "middleOffice.party.organization.contact.fonction." +
                item.value
              )
              : "",
        };
      }
    );
  }

  get contactType() {
    return store.getters["picklistModule/contactTypeItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.id?.toString() !== ""
            ? i18n.global.t("demand.party.contactMechanism.options." + item.id)
            : "",
      };
    });
  }
  get addressPreference() {
    const types = store.getters["picklistModule/addressPreferenceTypeItems"].map((item: any) => {
      return {
        ...item,
        label: item.label?.toString() !== "" ? i18n.global.t("demand.party.addressPreferenceType." + item.label) : ''
      };
    }).sort((a: any, b: any) =>
        a.label.localeCompare(b.label)
    );
    return types?.filter((item: any) => item.value === 'SIEGE')
  }
  get addressType() {
    return store.getters["picklistModule/addressTypeItems"];
  }

  get addressPreferenceType() {
    const types = store.getters["picklistModule/addressPreferenceTypeItems"]
      .map((item: any) => {
        return {
          ...item,
          label:
            item.label?.toString() !== ""
              ? i18n.global.t(
                "demand.party.addressPreferenceType." + item.label
              )
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
    return types?.filter((item: any) => item.value !== "SIEGE");
  }

  get postalCodes() {
    return store.getters["picklistModule/postalCodesItems"];
  }

  get offer() {
    return store.state.demandModule.offer;
  }

  get contacts() {

    return this.party?.person?.contacts?.sort((a, b) => {
      if (
        a.contactMechanism &&
        a.contactMechanism.type?.id === "PHONE_CONTACT" &&
        b.contactMechanism &&
        b.contactMechanism.type?.id !== "PHONE_CONTACT"
      ) {
        return -1;
      } else if (
        b.contactMechanism &&
        b.contactMechanism.type?.id === "PHONE_CONTACT" &&
        a.contactMechanism &&
        a.contactMechanism.type?.id !== "PHONE_CONTACT"
      ) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  get nationality() {
    return store.getters["picklistModule/nationalityItems"]
      ?.map((item: any) => {
        return {
          ...item,
          label:
            item.value?.toString() !== ""
              ? i18n.global.t("demand.party.country." + item.value)
              : "",
        };
      })
      .sort((a: any, b: any) => a.label.localeCompare(b.label));
  }
  get geographicalZoneItems() {
    return store.getters["picklistModule/geographicZoneItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.geographicZone." + item.value)
            : "",
      };
    });
  }
  get personJobs() {
    return this.party?.person?.jobs;
  }

  get requiredField() {
    return i18n.global.t("main.requiredField");
  }

  get jobActivity() {
    return store.getters["picklistModule/jobActivityItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.jobActivity." + item.value)
            : "",
      };
    });
  }

  get jobPositionType() {
    return store.getters["picklistModule/jobPositionTypeItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.positionType." + item.value)
            : "",
      };
    });
  }

  get jobOccupationType() {
    return store.getters["picklistModule/jobOccupationTypeItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.occupationType." + item.value)
            : "",
      };
    });
  }

  get matrimonialRegime() {
    return store.getters["picklistModule/matrimonialRegimeItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.regimeMatrimonial." + item.value)
            : "",
      };
    });
  }

  get relationTypes() {
    return store.getters["picklistModule/partyRelationTypeItems"].map((item: any) => {
      return {
        ...item,
        label:
          item.value?.toString() !== ""
            ? i18n.global.t("demand.party.relationType." + item.value)
            : "",
      };
    });
  }

  get analysis() {
    return this.party?.person?.revenues;
  }

  get streetLabel() {
    return i18n.global.t("demand.party.label.street");
  }

  get AdressLine1Label() {
    return i18n.global.t("demand.party.label.adressLine1Label");
  }

  get AdressLine2Label() {
    return i18n.global.t("demand.party.label.adressLine2Label");
  }

  get AdressLine3Label() {
    return i18n.global.t("demand.party.label.adressLine3Label");
  }

  verifyKSIOPCombination(financialInstitution: string, outstandingType: string) {
    if (financialInstitution !== 'KSIOP') {
      return true
    } else if (outstandingType === 'AXA') {
      return false
    }
    return true;
  }

  get selectedParty() {
    return store.state.taskModule.offer?.businessData?.parties[this.associatedPartyId] || {};
  }
  get displayOptions () {
    const isExternalApi = store.state.taskModule.offer?.businessData.API;

    if (this.selectedParty && isExternalApi) {
      return this.selectedParty.renewal !== undefined && this.selectedParty.isCfClient !== undefined;
    }

    return false;
  }

  changePostal(c: any) {
    if (this.c.businessData) {
      this.c.businessData.zipCode = c.zipCode;
      this.c.businessData.city = c.city;
    }
  }
  getCodeCity(value: any): string {
    if (!value?.businessData?.zipCode || !value?.businessData?.city) {
      return '';
    }
    return `${value.businessData.zipCode} - ${value.businessData.city}`;
  }

  recalculateKSIOP(value: any, index: number) {
    store.dispatch("demandModule/recalculateKSIOP", {
      associatedPartyIndex: this.associatedPartyId,
      financial: value,
      currency: this.currencyConfiguration.currency,
      callback: (result: any) => {
        if (this.party?.type?.id == ThirdType.PERSON) {
          this.party?.person?.financialExposures ? this.party.person.financialExposures[index].businessData = result.businessData : ''
        } else {
          this.party?.organization?.financialExposures ? this.party.organization.financialExposures[index].businessData = result.businessData : ''
        }
      },
    });
  }


}
