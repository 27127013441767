
import { Vue, Options } from "vue-class-component";
import { formatWithMask,formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import { Proposal } from "@/types";
import LabelAndValue from "@/components/general/LabelAndValue.vue";
import store from "@/store";

@Options({
  components: { LabelAndValue },
  props: ["proposal"],
})
export default class FinancingForm extends Vue {
  formatWithMask = formatWithMask;
  proposal?: Proposal;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  valueStyle = {
    "font-weight": "bold",
    "font-size": "1rem",
    "margin-left": "20px",
  };
  priceWithTax(items: any): number  {
    return items.sumOfTotalPaymentsWTax ? items.sumOfTotalPaymentsWTax : items.businessData?.financing?.sumOfTotalPaymentsWTax;
  }
  priceWithoutTax(items: any): number  {
    return items.sumOfTotalPaymentsWoTax ? items.sumOfTotalPaymentsWoTax : items.businessData?.financing?.sumOfTotalPaymentsWoTax;
  }


  getInstallmentDetails (items:any) {
    return items.businessData && items?.businessData ? {
      totalInstallmentWTax: items.businessData?.financing.totalInstallmentWTax  ? items.businessData?.financing.totalInstallmentWTax : '-',
      totalInstallmentWoTax: items.businessData?.financing.totalInstallmentWoTax ?items.businessData?.financing.totalInstallmentWoTax : '-',
      sumOfFinancialPaymentsWTax : items.businessData?.financing.sumOfFinancialPaymentsWTax  ? items.businessData?.financing.sumOfFinancialPaymentsWTax : '-',
      sumOfFinancialPaymentsWoTax: items.businessData?.financing.sumOfFinancialPaymentsWoTax ?items.businessData?.financing.sumOfFinancialPaymentsWoTax : '-',
      residualValueAmountWTax: items.businessData?.financing.residualValueAmountWTax ?items.businessData?.financing.residualValueAmountWTax : '-',
      sumOfTotalPaymentsWoTax: items.businessData?.financing.sumOfTotalPaymentsWoTax ?items.businessData?.financing.sumOfTotalPaymentsWoTax : '-',
      sumOfTotalPaymentsWTax:  items.businessData?.financing.sumOfTotalPaymentsWTax ?items.businessData?.financing.sumOfTotalPaymentsWTax : '-'

    } : ''
  }

  get isExternalApi(){
    return this.offer?.businessData.API;
  }
  get offer() {
    return store.state.middleOfficeModule.offer || store.state.taskModule.offer;
  }
}
