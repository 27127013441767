import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "d-flex" }
const _hoisted_6 = { class: "flex-grow-1" }
const _hoisted_7 = { class: "row" }
const _hoisted_8 = ["id"]
const _hoisted_9 = { class: "d-block mb-0 small text-muted" }
const _hoisted_10 = ["id"]
const _hoisted_11 = { class: "d-block mb-0 small text-muted" }
const _hoisted_12 = ["id"]
const _hoisted_13 = { class: "d-block mb-0 small text-muted" }
const _hoisted_14 = { class: "row items-center justify-end" }
const _hoisted_15 = ["id"]
const _hoisted_16 = { class: "d-block mb-0 small text-muted" }
const _hoisted_17 = { class: "row items-center justify-end" }
const _hoisted_18 = ["id"]
const _hoisted_19 = { class: "d-block mb-0 small text-muted" }
const _hoisted_20 = ["id"]
const _hoisted_21 = { class: "d-block mb-0 small text-muted" }
const _hoisted_22 = { class: "d-flex" }
const _hoisted_23 = { class: "card-title mb-0" }
const _hoisted_24 = { class: "col-12 form-control no-border" }
const _hoisted_25 = { class: "font-size-12 font-semi-bold" }
const _hoisted_26 = { class: "d-flex" }
const _hoisted_27 = { class: "flex-grow-1" }
const _hoisted_28 = {
  class: "row",
  id: "contactsDiv"
}
const _hoisted_29 = ["id"]
const _hoisted_30 = { class: "d-block mb-0 small text-muted" }
const _hoisted_31 = ["id"]
const _hoisted_32 = { class: "d-block mb-0 small text-muted" }
const _hoisted_33 = ["id"]
const _hoisted_34 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_date = _resolveComponent("q-date")!
  const _component_q_popup_proxy = _resolveComponent("q-popup-proxy")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_file = _resolveComponent("q-file")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_list, { class: "position-relative" }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass(["tw-unchor", 'section-financialSituation' + _ctx.tabPosition])
      }, null, 2),
      _createVNode(_component_q_expansion_item, {
        "switch-toggle-side": "",
        "header-class": "card-header bg-transparent no-border p-4",
        class: "card tw-items-dashed-group tw-items-group-with-bg",
        modelValue: _ctx.showFinancialSituationExpense,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.showFinancialSituationExpense) = $event))
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("middleOffice.party.organization.section.financialSituation")), 1)
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(this.balanceSheetsList, (value, index) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "p-2 bg-white default-border-radius mb-3",
                key: index
              }, [
                _createElementVNode("div", _hoisted_5, [
                  _createElementVNode("div", _hoisted_6, [
                    _createElementVNode("div", _hoisted_7, [
                      _createElementVNode("div", {
                        class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                        id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_label`
                      }, [
                        _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.label")), 1),
                        _createVNode(_component_q_input, {
                          "data-testid": "demand_edit_financialSituation_label",
                          dense: "",
                          outlined: "",
                          modelValue: value.label,
                          "onUpdate:modelValue": ($event: any) => ((value.label) = $event),
                          disable: _ctx.isdisable
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disable"])
                      ], 8, _hoisted_8),
                      _createElementVNode("div", {
                        class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                        id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_country`
                      }, [
                        _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.country")), 1),
                        _createVNode(_component_q_select, {
                          "data-testid": "demand_edit_financialSituation_country",
                          dense: "",
                          outlined: "",
                          modelValue: value.country,
                          "onUpdate:modelValue": ($event: any) => ((value.country) = $event),
                          disable: _ctx.isdisable,
                          options: _ctx.countriesOptions
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disable", "options"])
                      ], 8, _hoisted_10),
                      _createElementVNode("div", {
                        class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                        id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_from`
                      }, [
                        _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.from")), 1),
                        _createVNode(_component_q_input, {
                          "data-testid": "demand_edit_financialSituation_from",
                          dense: "",
                          outlined: "",
                          "model-value": value.period?.from,
                          disable: _ctx.isdisable
                        }, {
                          append: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              "data-listid": 'demand_edit_classification_endDate_icon_' + index
                        ,
                              name: "event",
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, {
                                  "data-listid": 'demand_edit_classification_endDate_proxy_' +
                          index
                          ,
                                  ref_for: true,
                                  ref: "qDateProxy",
                                  cover: "",
                                  "transition-show": "scale",
                                  "transition-hide": "scale"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      "data-listid": 'demand_edit_classification_endDate_date_' +
                            index
                            ,
                                      modelValue: value.period.from,
                                      "onUpdate:modelValue": ($event: any) => ((value.period.from) = $event),
                                      mask: _ctx.dateMask
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_14, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            "data-listid": 'demand_edit_classification_endDate_btn_' +
                                index
                                ,
                                            label: _ctx.$t(
                                  'task.completeDocument.party.person.financialSituation.close'
                                )
                                  ,
                                            color: "primary",
                                            flat: ""
                                          }, null, 8, ["data-listid", "label"]), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["data-listid", "modelValue", "onUpdate:modelValue", "mask"])
                                  ]),
                                  _: 2
                                }, 1032, ["data-listid"])
                              ]),
                              _: 2
                            }, 1032, ["data-listid"])
                          ]),
                          _: 2
                        }, 1032, ["model-value", "disable"])
                      ], 8, _hoisted_12),
                      _createElementVNode("div", {
                        class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                        id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_until`
                      }, [
                        _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.until")), 1),
                        _createVNode(_component_q_input, {
                          "data-testid": "demand_edit_financialSituation_until",
                          dense: "",
                          outlined: "",
                          "model-value": value.period?.until,
                          disable: _ctx.isdisable
                        }, {
                          append: _withCtx(() => [
                            _createVNode(_component_q_icon, {
                              "data-listid": 'demand_edit_classification_endDate_icon_' + index
                        ,
                              name: "event",
                              class: "cursor-pointer"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(_component_q_popup_proxy, {
                                  "data-listid": 'demand_edit_classification_endDate_proxy_' +
                          index
                          ,
                                  ref_for: true,
                                  ref: "qDateProxy",
                                  cover: "",
                                  "transition-show": "scale",
                                  "transition-hide": "scale"
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_component_q_date, {
                                      "data-listid": 'demand_edit_classification_endDate_date_' +
                            index
                            ,
                                      modelValue: value.period.until,
                                      "onUpdate:modelValue": ($event: any) => ((value.period.until) = $event),
                                      mask: _ctx.dateMask
                                    }, {
                                      default: _withCtx(() => [
                                        _createElementVNode("div", _hoisted_17, [
                                          _withDirectives(_createVNode(_component_q_btn, {
                                            "data-listid": 'demand_edit_classification_endDate_btn_' +
                                index
                                ,
                                            label: _ctx.$t(
                                  'task.completeDocument.party.person.financialSituation.close'
                                )
                                  ,
                                            color: "primary",
                                            flat: ""
                                          }, null, 8, ["data-listid", "label"]), [
                                            [_directive_close_popup]
                                          ])
                                        ])
                                      ]),
                                      _: 2
                                    }, 1032, ["data-listid", "modelValue", "onUpdate:modelValue", "mask"])
                                  ]),
                                  _: 2
                                }, 1032, ["data-listid"])
                              ]),
                              _: 2
                            }, 1032, ["data-listid"])
                          ]),
                          _: 2
                        }, 1032, ["model-value", "disable"])
                      ], 8, _hoisted_15),
                      _createElementVNode("div", {
                        class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                        id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_status`
                      }, [
                        _createElementVNode("label", _hoisted_19, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.status")), 1),
                        _createVNode(_component_q_select, {
                          "data-testid": "demand_edit_financialSituation_status",
                          dense: "",
                          outlined: "",
                          modelValue: value.status,
                          "onUpdate:modelValue": ($event: any) => ((value.status) = $event),
                          disable: _ctx.isdisable,
                          options: _ctx.statusOptions
                        }, null, 8, ["modelValue", "onUpdate:modelValue", "disable", "options"])
                      ], 8, _hoisted_18),
                      _createElementVNode("div", {
                        class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                        id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_supportingDocument`
                      }, [
                        _createElementVNode("label", _hoisted_21, _toDisplayString(_ctx.$t(
                        "middleOffice.party.organization.financialSituation.supportingDocument"
                      )), 1),
                        _createVNode(_component_q_file, {
                          dense: "",
                          outlined: "",
                          modelValue: value.supportingDocument,
                          "onUpdate:modelValue": ($event: any) => ((value.supportingDocument) = $event),
                          label: _ctx.$t('middleOffice.party.organization.financialSituation.supportingDocument'),
                          style: {"margin-bottom":"20px","margin-right":"10px"},
                          disable: value.isDisabled
                        }, {
                          append: _withCtx(() => [
                            _createVNode(_component_q_icon, { name: "attach_file" })
                          ]),
                          _: 2
                        }, 1032, ["modelValue", "onUpdate:modelValue", "label", "disable"])
                      ], 8, _hoisted_20)
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_22, [
                  _createElementVNode("div", _hoisted_23, [
                    _createElementVNode("div", _hoisted_24, [
                      _createElementVNode("span", _hoisted_25, _toDisplayString(_ctx.$t("middleOffice.party.organization.section.detail")), 1)
                    ])
                  ])
                ]),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(value.detail, (detail, idx) => {
                  return (_openBlock(), _createElementBlock("div", {
                    class: "bg-white pt-0",
                    key: idx
                  }, [
                    _createElementVNode("div", _hoisted_26, [
                      _createElementVNode("div", _hoisted_27, [
                        _createElementVNode("div", _hoisted_28, [
                          _createElementVNode("div", {
                            class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                            id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_detail_${idx}_nature`
                          }, [
                            _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.nature")), 1),
                            _createVNode(_component_q_select, {
                              "data-testid": "demand_edit_financialSituation_nature",
                              dense: "",
                              outlined: "",
                              modelValue: detail.nature,
                              "onUpdate:modelValue": ($event: any) => ((detail.nature) = $event),
                              disable: _ctx.isdisable,
                              options: _ctx.natureOptions
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disable", "options"])
                          ], 8, _hoisted_29),
                          _createElementVNode("div", {
                            class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                            id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_detail_${idx}_label`
                          }, [
                            _createElementVNode("label", _hoisted_32, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.label")), 1),
                            _createVNode(_component_q_input, {
                              "data-testid": "demand_edit_financialSituation_label",
                              dense: "",
                              outlined: "",
                              modelValue: detail.label,
                              "onUpdate:modelValue": ($event: any) => ((detail.label) = $event),
                              disable: _ctx.isdisable
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disable"])
                          ], 8, _hoisted_31),
                          _createElementVNode("div", {
                            class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
                            id: `middleOffice_party_${_ctx.tabPosition}_organization_financialSituation_${index}_detail_${idx}_amount`
                          }, [
                            _createElementVNode("label", _hoisted_34, _toDisplayString(_ctx.$t("middleOffice.party.organization.financialSituation.amount")), 1),
                            _createVNode(_component_q_input, {
                              "data-testid": "demand_edit_financialSituation_amount",
                              dense: "",
                              outlined: "",
                              modelValue: detail.value.amount,
                              "onUpdate:modelValue": ($event: any) => ((detail.value.amount) = $event),
                              disable: _ctx.isdisable
                            }, null, 8, ["modelValue", "onUpdate:modelValue", "disable"])
                          ], 8, _hoisted_33)
                        ])
                      ])
                    ])
                  ]))
                }), 128))
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }))
}