
import {Options, Vue} from "vue-class-component";
import store from "@/store";
import {formatAmoutswitchCurrency, formatDateService, formatWithMask,} from "@/utils/configuration/formatters-config";
import Notify from 'quasar/src/plugins/Notify.js';;
import {EvaluateTaxRate, ProposalAssetItem, ProposalAssetItemType} from "@/types";
import i18n from "@/i18n";
import AssetProductPackItem from "@/components/demand/tabs/DemandTabAssetDetail/AssetProductPackItem.vue";
import twCurrencyInput from "@/components/general/twCurrencyInput.vue";
import {currencyConfiguration} from "@/utils/configuration/currencyInput-config";

import { Ref, ref } from "vue";
@Options({
  props: ["tabPosition"],
  components: {
    AssetProductPackItem,
    twCurrencyInput,
  },
  watch: {
    assetProductCalculated(newValue) {
      this.productDiscount = newValue.discount;
      this.unitaryPrice = newValue.unitaryPrice;
      this.priceWithDiscount = newValue.priceWithDiscount;
      this.productTax = newValue.tax;
      this.priceWithTax = newValue.priceWithTax;
      store.dispatch("demandModule/calculateProposalAsset", {
        index: this.tabPosition,
      });
    },
    totalPriceWithTax() {
      store.dispatch("financingModule/calculateProposalAssetCarAmounts");
    },
  },
})
export default class DemandVehicleCustom extends Vue {
  formatWithMask = formatWithMask;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  option = ProposalAssetItemType.option;
  other = ProposalAssetItemType.other;
  accessory = ProposalAssetItemType.accessory;

  openDialog = "";
  taxeList = [];

  tabPosition?: number;
  taxModel?: any = 0;


  get third() {
    return store.state.thirdModule.third;
  }
  
  get startDate() {
      if (store.state.demandModule.offer?.validity) {
        const startDate = store.state.demandModule.offer?.validity.from;
        return startDate ? startDate : ref(formatDateService(new Date(), "-")).value;
      }
    }

  get currencyConfiguration() {
    return currencyConfiguration;
  }
  get assetState() {
    return store.state.demandModule.assetState;
  }

  get assets() {
    return this.assetState.proposalAssets;
  }

  get asset():any {
    return this.assetState.proposalAssets[this.tabPosition!];
  }

  get assetProductCalculated() {
    return this.asset &&
      this.asset.config &&
      this.asset.config.proposalAssetCalculated &&
      this.asset.config.proposalAssetCalculated.product
      ? this.asset.config.proposalAssetCalculated.product
      : {};
  }

  get proposalAssetItems() {
    return this.asset ? this.asset.proposalAssetItems || [] : [];
  }

  get formattedproposalAssetItemsTotalWithTax() {
    return formatAmoutswitchCurrency("EUR", this.proposalAssetItemsTotalWithTax);
  }
  get proposalAssetItemsTotalWithTax() {
    return this.proposalAssetItems
      .filter((item:any) => item.config.selected)
      .reduce((a: number, b: ProposalAssetItem) => {
        return a + (b.totalAmountWTax?.amount || 0);
      }, 0);
  }

  get proposalAssetItemsTotalWOTax() {
    return this.proposalAssetItems
      .filter((item:any) => item.config.selected)
      .reduce((a: number, b: ProposalAssetItem) => {
        return a + (b.totalAmountWoTax?.amount || 0);
      }, 0);
  }

  get totalPriceWithTax() {
    const totalAmountWTax = this.asset ? this.asset.totalAmountWTax.amount || 0 : 0;
    return this.proposalAssetItemsTotalWithTax + totalAmountWTax || 0;
  }

  get totalPriceWOTax() {
    return (
      this.proposalAssetItemsTotalWOTax + (this.asset.totalAmountWoTax?.amount || 0) || 0
    );
  }

  get proposalAssetName() {
    return (
      this.asset.description ||
      `${this.asset.config.formBind.brand.label} ${this.asset.config.formBind.range.label}`
    );
  }

  get proposalAssetAmount() {
    return this.asset.amountWTax?.amount || 0;
  }

  get proposalAssetTotalAmount() {
    return formatAmoutswitchCurrency("EUR", this.asset.totalAmountWTax.amount);
  }
  get purchasePercentage() {
    return this.asset?.businessData && this.asset?.businessData?.purchasePercentage ? this.asset?.businessData?.purchasePercentage + "%" : ''
  }

  get purchasePercentageColor() {
    return this.asset?.businessData && this.asset?.businessData?.purchasePercentage ? (this.asset?.businessData?.purchasePercentage > 60 ? 'bg-custom-danger' : '') : ''
  }
  onProposalAssetTaxChange() {
    this.assetProductCalculated.tax = this.taxModel?.id || 0;
    store.dispatch("demandModule/calculateProposalAsset", {
      index: this.tabPosition,
    });
  }

  onProposalAssetChange(value: number, key: string) {
    if (key === "unitaryPrice" && value < 0) {
      Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
        message: `${i18n.global.t("main.dialog.validation.negative")}`,
        color: "warning",
      });
      this.assetProductCalculated.unitaryPrice = value * -1;
    } else if (key === "discount") {
      if (value < 0) {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: `${i18n.global.t("main.dialog.validation.negative")}`,
          color: "warning",
        });
        value *= -1;
        this.assetProductCalculated.discount = value;
      }
      if (value > 100) {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: `${i18n.global.t("main.dialog.validation.greater100")}`,
          color: "warning",
        });
        this.assetProductCalculated.discount = 100;
      }
    }
    store.dispatch("demandModule/calculateProposalAsset", {
      index: this.tabPosition,
    });
  }

  created() {
  
    const taxModel = this.assetProductCalculated.tax;
    if (taxModel) {
      this.taxModel = taxModel;
    }
    store.dispatch("demandModule/assetTax", {
      callback: (item: any) =>{
        this.taxeList = item
      }
      
    });
  }
}
