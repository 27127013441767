
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import FinancingForm from "@/components/middle-office/tabs/financing/FinancingForm.vue";
import FinancingServices from "./financing/FinancingServices.vue";
import FinancingServicesDetail from "@/components/middle-office/tabs/financing/FinancingServicesDetail.vue";
import GuaranteeTab from "@/components/middle-office/tabs/financing/guarantee/GuaranteeTab.vue"
import { Proposal } from "@/types";
import { ref } from "vue";

@Options({
  props: ["proposals"],
  components: {
    FinancingForm,
    FinancingServices,
    FinancingServicesDetail,
    GuaranteeTab,
  },
  watch: {
    "$store.state.middleOfficeModule.showService"(action: boolean) {
    },
  },
})
export default class Asset extends Vue {
  proposals?: Proposal[];
  internTab = 1;
  tab = ref("simulation");

  get isExternalApi(){
    return store.state.middleOfficeModule.offer?.businessData.API;
  }

  get showService() {
    return store.state.middleOfficeModule.showService;
  }
  get offer() {
    return store.state.middleOfficeModule.offer || store.state.taskModule.offer;
  }

  financingProduct(proposalIndex: number, itemIndex: number) {
    const product = this.offer?.proposals[proposalIndex]?.proposalItems[itemIndex]?.financingProduct;

    return product
        ? `scales.picklist.category_class.${product.replace(/\./g, '_')}`
        : `scales.picklist.category_class.${store.state.middleOfficeModule?.proposalScale?.category}_${store.state.middleOfficeModule?.proposalScale?.categoryClass}`;
  }


  scaleName(proposalIndex: number, itemIndex: number) {
    const businessData = this.offer?.proposals[proposalIndex]?.proposalItems[itemIndex]?.businessData;
    if (businessData && this.isExternalApi) {
      const scaleName = businessData.financing
          ? businessData.financing.scaleName.replace(/\./g, '_')
          : businessData.productPackLabel.replace(/\./g, '_');
      return `demand.product.financingProduct.productLabel.${scaleName}`;
    }

    const resourceUid = store.state.middleOfficeModule?.proposalScale?.resourceUid?.replace(/\./g, '_') || "main.empty";
    return `demand.product.financingProduct.productLabel.${resourceUid}`;
  }
}
