import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_2 = { class: "d-flex align-items-center" }
const _hoisted_3 = { class: "font-semi-bold font-size-12" }
const _hoisted_4 = { class: "card-body" }
const _hoisted_5 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_6 = { class: "d-flex" }
const _hoisted_7 = { class: "flex-grow-1" }
const _hoisted_8 = { class: "card-title flex-grow-1 justify-content-between align-items-center mb-0" }
const _hoisted_9 = { class: "d-flex align-items-center" }
const _hoisted_10 = { class: "font-semi-bold font-size-12" }
const _hoisted_11 = { class: "card-body" }
const _hoisted_12 = { class: "p-2 bg-white default-border-radius mb-3" }
const _hoisted_13 = { class: "d-flex" }
const _hoisted_14 = { class: "flex-grow-1" }
const _hoisted_15 = { class: "row" }
const _hoisted_16 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_twPercent = _resolveComponent("twPercent")!
  const _component_twCurrencyInput = _resolveComponent("twCurrencyInput")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_list = _resolveComponent("q-list")!

  return (_openBlock(), _createBlock(_component_q_list, null, {
    default: _withCtx(() => [
      _createVNode(_component_q_expansion_item, {
        "switch-toggle-side": "",
        "header-class": "card-header bg-transparent no-border p-4",
        class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
        onShow: _cache[2] || (_cache[2] = () => {}),
        onHide: _cache[3] || (_cache[3] = () => {})
      }, {
        header: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("task.study.ratios")), 1)
            ])
          ])
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("div", _hoisted_6, [
                _createElementVNode("div", _hoisted_7, [
                  _createVNode(_component_q_list, null, {
                    default: _withCtx(() => [
                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratiosByCategories, (categoryRatios, categoryKey) => {
                        return (_openBlock(), _createBlock(_component_q_expansion_item, {
                          "switch-toggle-side": "",
                          "header-class": "card-header bg-transparent no-border p-4",
                          class: "card tw-items-dashed-group tw-items-group-with-bg tw-level-two-group",
                          onShow: _cache[0] || (_cache[0] = () => {}),
                          onHide: _cache[1] || (_cache[1] = () => {}),
                          key: categoryKey
                        }, {
                          header: _withCtx(() => [
                            _createElementVNode("div", _hoisted_8, [
                              _createElementVNode("div", _hoisted_9, [
                                _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.$t("RATIO.CAT." + categoryKey)), 1)
                              ])
                            ])
                          ]),
                          default: _withCtx(() => [
                            _createElementVNode("div", _hoisted_11, [
                              _createElementVNode("div", _hoisted_12, [
                                _createElementVNode("div", _hoisted_13, [
                                  _createElementVNode("div", _hoisted_14, [
                                    _createElementVNode("div", _hoisted_15, [
                                      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(categoryRatios, (ratio, index) => {
                                        return (_openBlock(), _createElementBlock("div", {
                                          class: "col-lg-4 col-md-4 col-sm-12 form-control no-border",
                                          key: index
                                        }, [
                                          _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("task.study."+ ratio.ratio_id)), 1),
                                          ( ratio.ratio_id === 'Taux_endettement')
                                            ? (_openBlock(), _createBlock(_component_twPercent, {
                                                key: 0,
                                                readonly: true,
                                                "model-value": _ctx.getRatioValue(ratio)?.value,
                                                disable: true
                                              }, null, 8, ["model-value"]))
                                            : (_openBlock(), _createBlock(_component_twCurrencyInput, {
                                                key: 1,
                                                "model-value": _ctx.getRatioValue(ratio)?.value,
                                                options: { currency: 'EUR', locale: _ctx.$i18n.locale },
                                                disabled: true,
                                                readonly: true
                                              }, null, 8, ["model-value", "options"]))
                                        ]))
                                      }), 128))
                                    ])
                                  ])
                                ])
                              ])
                            ])
                          ]),
                          _: 2
                        }, 1024))
                      }), 128))
                    ]),
                    _: 1
                  })
                ])
              ])
            ])
          ])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}