import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "tw-form-controle-wrapper" }
const _hoisted_2 = { class: "font-weight-bold" }
const _hoisted_3 = { class: "table-responsive" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_th = _resolveComponent("q-th")!
  const _component_q_tr = _resolveComponent("q-tr")!
  const _component_q_td = _resolveComponent("q-td")!
  const _component_q_table = _resolveComponent("q-table")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.ratiosHistory, (ratio, index) => {
      return (_openBlock(), _createElementBlock("div", { key: index }, [
        _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.$t("task.study." + ratio.title)), 1),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_q_table, {
            class: "shadow-0 table table-sm",
            rows: ratio.rows,
            columns: _ctx.columns
          }, {
            header: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_th, {
                      key: col.name,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t(col.label)), 1)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            body: _withCtx((props) => [
              _createVNode(_component_q_tr, { props: props }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(props.cols, (col) => {
                    return (_openBlock(), _createBlock(_component_q_td, {
                      key: col.name,
                      props: props
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("span", {
                          class: _normalizeClass(this.colorClass(col.name,col.value))
                        }, _toDisplayString((col.name === "ValidityUntil" && props.rowIndex === ratio.rows.length - 1) ? '-' : ((col.name ===
                  "whenModified" || col.name === "ValidityFrom" ||
                  col.name === "ValidityUntil") && col.value !== '') ? col.value: col.value), 3)
                      ]),
                      _: 2
                    }, 1032, ["props"]))
                  }), 128))
                ]),
                _: 2
              }, 1032, ["props"])
            ]),
            _: 2
          }, 1032, ["rows", "columns"])
        ])
      ]))
    }), 128))
  ]))
}