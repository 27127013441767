
import { Options, Vue } from "vue-class-component";
import twCurrencyInput from "@/components/general/twCurrencyInput.vue";
import twPercent from "@/components/general/twPercent.vue";
@Options({
  props: ["data"],
  watch: {},
  components: {
    twCurrencyInput,
    twPercent
  }
})
export default class Ratios extends Vue {
  data: any;
  get ratiosByCategories() {
    return this.sortCategories(this.$store.state.analysisModule?.ratiosByCategories);
  }
  get ratios() {
    return this.$store.state.analysisModule?.analysis?.ratios;
  }
 sortCategories(data:any) {
  // Get all category keys
  const categories = Object.keys(data);

  // Sort categories with ENCOURS first
  const sortedCategories = categories.sort((a, b) => {
    if (a === 'ENCOURS') return -1;
    if (b === 'ENCOURS') return 1;
    return a.localeCompare(b);
  });

  // Create new sorted object
  const sortedData:any = {};
  sortedCategories.forEach((category:any) => {
    sortedData[category] = data[category];
  });

  return sortedData;
}
  getRatioValue(ratio: any) {
    let currentModel = null;
    if (this.ratios) {
      currentModel = this.ratios.find(
        (item) => item.ratioParam?.resourceUid === ratio.ratio_id
      );
    }
    return currentModel;
  }
}
