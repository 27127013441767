import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card bg-light border-left-0 mb-3 tw-asset-prices-wrapper" }
const _hoisted_2 = { class: "row" }
const _hoisted_3 = { class: "bg-light col-12 d-flex flex-row justify-between p-3 align-items-center" }
const _hoisted_4 = { class: "row flex-gap-default" }
const _hoisted_5 = {
  class: "text-right",
  id: "demand_edit_asset_totalPriceTTC"
}
const _hoisted_6 = { class: "d-block font-medium mb-0 text-muted" }
const _hoisted_7 = {
  "data-testid": "demand_edit_asset_totalPriceTTC",
  class: "h6 tw-formattedNumberFont"
}
const _hoisted_8 = {
  class: "text-right",
  id: "demand_edit_asset_totalCostsTTC"
}
const _hoisted_9 = { class: "d-block font-medium mb-0 text-muted" }
const _hoisted_10 = {
  "data-testid": "demand_edit_asset_totalCostsTTC",
  class: "h6 tw-formattedNumberFont"
}
const _hoisted_11 = { class: "bg-warning default-border-radius p-3 row flex-gap-default" }
const _hoisted_12 = {
  class: "text-right",
  id: "demand_edit_asset_totalTTC"
}
const _hoisted_13 = { class: "d-block font-medium mb-0 text-white" }
const _hoisted_14 = {
  "data-testid": "demand_edit_asset_totalTTC",
  class: "h6 text-white tw-formattedNumberFont"
}
const _hoisted_15 = {
  class: "text-right",
  id: "demand_edit_asset_totalHT"
}
const _hoisted_16 = { class: "d-block font-medium mb-0 text-white" }
const _hoisted_17 = {
  "data-testid": "demand_edit_asset_totalHT",
  class: "h6 text-white tw-formattedNumberFont"
}
const _hoisted_18 = { class: "col-12 p-3" }
const _hoisted_19 = { class: "card-title font-medium text-dark" }
const _hoisted_20 = { class: "row tw-custom-row-spacing" }
const _hoisted_21 = {
  class: "col-4 form-control no-border",
  id: "demand_edit_asset_unitPriceExcVat"
}
const _hoisted_22 = { class: "d-block mb-0 small text-muted" }
const _hoisted_23 = {
  class: "col-2 form-control no-border",
  id: "demand_edit_asset_tax"
}
const _hoisted_24 = { class: "d-block mb-0 small text-muted" }
const _hoisted_25 = {
  class: "col-2 form-control no-border",
  id: "demand_edit_asset_delivery"
}
const _hoisted_26 = { class: "d-block mb-0 small text-muted" }
const _hoisted_27 = {
  class: "col-2 form-control no-border",
  id: "demand_edit_asset_unitPriceIncVAT"
}
const _hoisted_28 = { class: "d-block mb-0 small text-muted" }
const _hoisted_29 = {
  class: "col-2 form-control no-border",
  id: "demand_edit_asset_verhicle_purchasePercentage"
}
const _hoisted_30 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_twCurrencyInput = _resolveComponent("twCurrencyInput")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_AssetProductPackItem = _resolveComponent("AssetProductPackItem")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("div", _hoisted_5, [
              _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.$t("demand.asset.totalPriceTTC")), 1),
              _createElementVNode("p", _hoisted_7, _toDisplayString(_ctx.proposalAssetTotalAmount), 1)
            ]),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("task.completeOffer.asset.totalFraisTTC")), 1),
              _createElementVNode("p", _hoisted_10, _toDisplayString(_ctx.formattedproposalAssetItemsTotalWithTax), 1)
            ])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("div", _hoisted_12, [
              _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("demand.asset.totalTTC")), 1),
              _createElementVNode("p", _hoisted_14, _toDisplayString(_ctx.formatAmoutswitchCurrency("EUR", _ctx.totalPriceWithTax)), 1)
            ]),
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", _hoisted_16, _toDisplayString(_ctx.$t("demand.asset.totalHT")), 1),
              _createElementVNode("p", _hoisted_17, _toDisplayString(_ctx.formatAmoutswitchCurrency("EUR", _ctx.totalPriceWOTax)), 1)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("div", _hoisted_19, _toDisplayString(_ctx.$t("demand.financialSituation.pricingDetails")), 1),
          _createElementVNode("div", _hoisted_20, [
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.$t("demand.asset.unit_price_ht")), 1),
              (_ctx.asset.config.readOnly)
                ? (_openBlock(), _createBlock(_component_twCurrencyInput, {
                    key: 0,
                    "data-testid": "demand_edit_asset_unitPriceExcVat",
                    modelValue: _ctx.assetProductCalculated.unitaryPrice,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.assetProductCalculated.unitaryPrice) = $event)),
                    disable: "",
                    options: _ctx.currencyConfiguration
                  }, null, 8, ["modelValue", "options"]))
                : (_openBlock(), _createBlock(_component_twCurrencyInput, {
                    key: 1,
                    "data-testid": "demand_edit_asset_unitPriceExcVat",
                    modelValue: _ctx.assetProductCalculated.unitaryPrice,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.assetProductCalculated.unitaryPrice) = $event)),
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (
                  _ctx.onProposalAssetChange(
                    _ctx.assetProductCalculated.unitaryPrice,
                    'unitaryPrice'
                  )
                )),
                    options: _ctx.currencyConfiguration
                  }, null, 8, ["modelValue", "options"]))
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.$t("demand.asset.vehicle.taxe")), 1),
              (_ctx.asset.config.readOnly)
                ? (_openBlock(), _createBlock(_component_q_select, {
                    key: 0,
                    "data-testid": "demand_edit_asset_tax",
                    dense: "",
                    outlined: "",
                    "model-value": _ctx.formatWithMask(_ctx.assetProductCalculated.tax, 2),
                    disable: "",
                    class: "tw-percent-wrapper",
                    suffix: "%"
                  }, null, 8, ["model-value"]))
                : (_openBlock(), _createBlock(_component_q_select, {
                    key: 1,
                    "data-testid": "demand_edit_asset_tax",
                    dense: "",
                    outlined: "",
                    modelValue: _ctx.taxModel,
                    "onUpdate:modelValue": [
                      _cache[3] || (_cache[3] = ($event: any) => ((_ctx.taxModel) = $event)),
                      _ctx.onProposalAssetTaxChange
                    ],
                    options: _ctx.taxeList,
                    class: "tw-percent-wrapper",
                    "input-class": "text-right",
                    suffix: "%"
                  }, null, 8, ["modelValue", "options", "onUpdate:modelValue"]))
            ]),
            _createElementVNode("div", _hoisted_25, [
              _createElementVNode("label", _hoisted_26, _toDisplayString(_ctx.$t("demand.asset.delivery")), 1),
              _createVNode(_component_q_input, {
                "data-testid": "demand_edit_asset_delivery",
                dense: "",
                outlined: "",
                modelValue: _ctx.assetProductCalculated.discount,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.assetProductCalculated.discount) = $event)),
                min: "0",
                onChange: _cache[5] || (_cache[5] = ($event: any) => (
                  _ctx.onProposalAssetChange(_ctx.assetProductCalculated.discount, 'discount')
                )),
                suffix: "%"
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_27, [
              _createElementVNode("label", _hoisted_28, _toDisplayString(_ctx.$t("demand.asset.unit_price_ttc_after_discount")), 1),
              _createVNode(_component_twCurrencyInput, {
                "data-testid": "demand_edit_asset_unitPriceIncVAT",
                modelValue: _ctx.proposalAssetAmount,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.proposalAssetAmount) = $event)),
                disable: "",
                options: _ctx.currencyConfiguration
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_29, [
              _createElementVNode("label", _hoisted_30, _toDisplayString(_ctx.$t("demand.asset.purchasePercentage")), 1),
              _createVNode(_component_q_input, {
                class: _normalizeClass(_ctx.purchasePercentageColor),
                dense: "",
                outlined: "",
                "model-value": _ctx.purchasePercentage,
                disable: ""
              }, null, 8, ["class", "model-value"])
            ])
          ])
        ])
      ])
    ]),
    _createVNode(_component_AssetProductPackItem, {
      tabPosition: _ctx.tabPosition,
      type: _ctx.option,
      label: "OPTIONS"
    }, null, 8, ["tabPosition", "type"]),
    _createVNode(_component_AssetProductPackItem, {
      tabPosition: _ctx.tabPosition,
      type: _ctx.accessory,
      label: _ctx.$t('summaryPanel.asset.accessories')
    }, null, 8, ["tabPosition", "type", "label"]),
    _createVNode(_component_AssetProductPackItem, {
      tabPosition: _ctx.tabPosition,
      type: _ctx.other,
      label: _ctx.$t('demand.asset.OTHER_CAR')
    }, null, 8, ["tabPosition", "type", "label"])
  ]))
}