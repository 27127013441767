
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import RatingHistory from "../general/RatingHistory.vue";
import SideBar from "@/components/general/SideBarEfficom.vue";
import i18n from "@/i18n";

@Options({
  props: ["data", "reCalculation"],
  watch: {},
  created() {
    store.dispatch("analysisModule/getHistoryRating", {
      filter: {
        resourceUid: this.$store.state.analysisModule?.analysis.resourceUid,
      },
      callback: this.setRatingHistory,
    });
  },
  components: {
    RatingHistory,
    SideBar,
  },
})
export default class Ratings extends Vue {
  componentToRenderSB = RatingHistory;
  data: any;
  reCalculation: any;
  showLabels = false;
  modalOpen = false;
  ratingsData: any[] = [];
  valueChanged: any;

  get cancelButtonLabel() {
    return i18n.global.t("main.dossier.globalLabels.cancelButton");
  }

  get ratingHistoryTitle() {
    return i18n.global.t("task.study.ratingHistoryTitle");
  }
  get historyRating() {
    return this.$store.state.analysisModule?.analysis?.historyRating;
  }
  get ratings() {
    const historyRating = this.$store.state.analysisModule?.analysis?.historyRating
      ? this.$store.state.analysisModule?.analysis?.historyRating
      : [];

    this.$store.state.analysisModule?.analysis?.analysisRatings?.forEach((rating) => {
      const correspondingHistory = historyRating.find(
        (history: any) => history.type === rating.type
      );
      if (correspondingHistory) {
        const correspondingHistoryRating =
          correspondingHistory.ratings[correspondingHistory.ratings.length - 1];
        rating.updatedValue = correspondingHistoryRating.updatedvalue; // Update the updatedValue directly
      }
    });
    return this.$store.state.analysisModule?.analysis?.analysisRatings;
  }
  handelCalculation() {
    store.state.analysisModule!.AlgorithmeChanged = true;
  }
  showRatingHistory() {
    this.modalOpen = !this.modalOpen;
  }
  closeModal() {
    this.modalOpen = false;
  }
  setRatingHistory(RatingHistory: any) {
    this.groupAndSortRatings(RatingHistory.data);
    store.commit(
      "analysisModule/setRatingHistory",
      this.groupAndSortRatings(RatingHistory.data)
    );
  }
  groupAndSortRatings(ratings: any[]) {
    if (!ratings) return [];

    // Group ratings by typerating and sort within each group
    const groupedAndSortedRatings: any[] = [];
    const uniqueTypes = [...new Set(ratings.map((rating) => rating.typerating))];
    uniqueTypes.forEach((type) => {
      const ratingsOfType = ratings.filter((rating) => rating.typerating === type).sort((a, b) => b.whencreated.localeCompare(a.whencreated));

      groupedAndSortedRatings.push({ type, ratings: ratingsOfType });
    });

    return groupedAndSortedRatings;
  }

  // Other methods and lifecycle hooks...

  color(index: number) {
    return this.$store.state.analysisModule?.analysis?.analysisRatings?.[index]
      ? `bg-${this.$store.state.analysisModule?.analysis?.analysisRatings?.[index].decision}`
      : "";
  }
}
