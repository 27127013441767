
import { ref } from 'vue';
import {Options, Vue } from 'vue-class-component';
import SummaryActionsHistory from "@/components/summary-panel/SummaryActionsHistory.vue";
import SummaryMessage from "@/components/summary-panel/SummaryMessage.vue";
@Options({
  components: {SummaryMessage, SummaryActionsHistory},
  props: ["entityState", "historiesStep"],
})

export default class SummaryHistory extends Vue {
  activeTab = ref('actions');
  entityState?: any;
  historiesStep?: any;
}
