
import { Vue, Options } from "vue-class-component";

import { AssociatedParty, Person } from "@/types";
import store from "@/store";
import { useRoute } from "vue-router";
import { globalConfig, UON, upsertWithDataMessageReturn } from "@/utils";
import { formatDateLocale } from "@/utils/configuration/formatters-config";

@Options({
  props: ["party", "tabPosition"],
  watch: {
    "$store.state.middleOfficeModule.section"(newValue) {
      this.showAccountExpense = newValue === `section-account${this.tabPosition}`;
    },
  },
  computed: {
    requiredRules() {
      return [this.required];
    }
  },
})
export default class PartyAccount extends Vue {
  party?: Person;
  tabPosition!: number;
  bicSuffix = "XXX";
  countrySelected = {
    id: "FRA",
    label: "France",
  };
  dateMask = "DD/MM/YYYY";

  showAccountExpense = false;
  system_uid = UON.SystemUid.odmParty;
  get accountsModel() {
    return (this.party?.accounts && this.party.accounts.length > 0) ? this.party?.accounts : [store.getters['thirdModule/getAccountEmpty']];
  }

  get currentRoute() {
    return useRoute().name;
  }
  required(value: any, message?: string) {
    return globalConfig.input_rules.required(value, message);
  }
  lengthMin(value: any, length: number, message?: string) {
    return globalConfig.input_rules.lengthMin(value, length, message);
  }
  updateBic(index: number, newValue: any) {
    const size = newValue.length;
    if (size === 8) {
      newValue = newValue + this.bicSuffix;
    }
    this.accountsModel[index].bankAccount.bic = newValue;
  }
  formatDate(date: string) {
    return formatDateLocale(date, this.dateMask, "fr", "YYYY/MM/DD");
  }
  formatDateUntil(value: any) {
    value.bankAccount.validity.until = value.validity.until.includes("/")
      ? formatDateLocale(
        value.bankAccount.validity.until,
        "DD/MM/YYYY",
        "fr",
        "DD/MM/YYYY"
      )
      : formatDateLocale(
        value.bankAccount.validity.until,
        "DDMMYYYY",
        "fr",
        "DD/MM/YYYY"
      );
  }
  formatDateFrom(value: any) {
    value.bankAccount.validity.from = value.validity.from.includes("/")
      ? formatDateLocale(
        value.bankAccount.validity.from,
        "DD/MM/YYYY",
        "fr",
        "DD/MM/YYYY"
      )
      : formatDateLocale(value.bankAccount.validity.from, "DDMMYYYY", "fr", "DD/MM/YYYY");
  }
  async validateIban(value: string) {
    const newValue = value?.split(" ").join("");
    const response = await upsertWithDataMessageReturn(
      this.system_uid,
      newValue,
      "validateIban"
    );
    if (!!response && !!response.message && response.message !== "IBAN is correct") {
      return response.message;
    }
  }

  isMo() {
    return this.currentRoute !== 'TaskDetail';

  }
  get offer() {
    return this.isMo()
      ? store.state.middleOfficeModule?.offer
      : store.state.taskModule?.offer;
  }


  get bankName() {
    const party = this.offer?.businessData.parties?.[this.tabPosition];
    return party?.bankAccount ? { bankName: party.bankAccount.bankName } : null;
  }
}
