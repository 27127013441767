
import { Vue, Options } from "vue-class-component";
import { businessDataUtils, Proposal, proposalAccessoriesObjectTypes } from "@/types";
import { formatMask, formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import store from "@/store";
import FinancingPartyInsurance from "@/components/middle-office/tabs/financing/FinancingPartyInsurance.vue";
import { serviceName, amountHT, amountTTC, paymentDatesOption} from "@/utils";
@Options({
  computed: {
    businessDataUtils() {
      return businessDataUtils
    }
  },
  props: ["proposal"],
  components: { FinancingPartyInsurance }
})
export default class FinancingServices extends Vue {
  serviceName = serviceName;
  amountHT = amountHT;
  amountTTC = amountTTC;
  paymentDatesOption = paymentDatesOption;
  
  proposal?: Proposal;
  money = formatMask;
  get calculatestatus() {
    return config.VUE_APP_CALCULATE
  }
  get partyInsurance() {
  let accessories;

  if (
    config.VUE_APP_CALCULATE === 'false' &&
    this.offer?.businessData &&
    this.offer?.businessData.API === businessDataUtils.offerApiSource
  ) {
    accessories =
      this.proposal?.proposalItems[0].proposalAccessories.filter(
        (accessorie: any) =>
          accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalPartyInsuranceObjectType)||
          accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalassetinsurance)
      ) || [];
  } else {
    accessories =
      store.state.middleOfficeModule?.proposalScale?.scalePresentation
        ?.servicePartyInsurance || [];
  }

  const consolidatedAccessories = accessories.reduce((acc: any[], current: any) => {
    const existingAccessory = acc.find(
      (item) =>
        item.accessoryProduct?.resourceUid === current.accessoryProduct?.resourceUid
    );

    if (existingAccessory) {
      if (current.associatedInsuranceBeneficiaries?.length) {
        existingAccessory.associatedInsuranceBeneficiaries = [
          ...(existingAccessory.associatedInsuranceBeneficiaries || []),
          ...current.associatedInsuranceBeneficiaries,
        ];
      }
    } else {
      acc.push({
        ...current,
        associatedInsuranceBeneficiaries:
          current.associatedInsuranceBeneficiaries || [],
      });
    }

    return acc;
  }, []);

  return consolidatedAccessories;
}


  get offer() {
    return store.state.middleOfficeModule?.offer
  }

  get servicesList() {
    let services;
    if (config.VUE_APP_CALCULATE === 'false' && 
        (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
        services = this.proposal?.proposalItems[0].proposalAccessories.filter((accessorie: any) => {
            return (
                accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalsimpleservice) ||
                accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalCommission) ||
                accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalmaintenance) 
            );
        }) || [];
    } else {
        services = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.services.filter((accessorie: any) => 
            accessorie.objectType.includes(proposalAccessoriesObjectTypes.simpleServiceProductItem) ||
            accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalmaintenance)
        ) || [];
    }
    return services;
}

 
  get totalAmountTTC() {
    const Items = this.proposal?.proposalItems;
    if (Items) {
      let totalAmount = 0;
      this.proposal?.proposalItems.forEach((item: any) => {
        totalAmount = totalAmount + item.financialPaymentWTax;
      });

      return formatAmoutswitchCurrency('EUR', totalAmount);
    }
    return 0;
  }

  get totalAmountHT() {
    const Items = this.proposal?.proposalItems;
    if (Items) {
      let totalAmountHs = 0;
      this.proposal?.proposalItems.forEach((item: any) => {
        totalAmountHs = totalAmountHs + item.financialPaymentWoTax;
      });

      return formatAmoutswitchCurrency('EUR', totalAmountHs);
    }
    return "0";
  }
  get getInstallmentDetails() {
    const proposalItems = this.proposal?.proposalItems;

    if (proposalItems) {
      let totalInstallmentWoTax = 0;
      let totalInstallmentWTax = 0;
      proposalItems.forEach((item: any) => {
        totalInstallmentWoTax += item.businessData?.financing.totalInstallmentWoTax || 0;
        totalInstallmentWTax += item.businessData?.financing.totalInstallmentWTax || 0;
      });

      return {
        totalInstallmentWoTax: formatAmoutswitchCurrency('EUR', totalInstallmentWoTax),
        totalInstallmentWTax: formatAmoutswitchCurrency('EUR', totalInstallmentWTax),
      };
    }
  }

  get numberOfPaymentTerm() {
    const Items = this.proposal?.proposalItems;
    if (Items) {
      let numberOfPaymentTerm = 0;
      this.proposal?.proposalItems.forEach((item: any) => {
        if (item.numberOfPaymentTerm > numberOfPaymentTerm)
          numberOfPaymentTerm = item.numberOfPaymentTerm;
      });

      return numberOfPaymentTerm;
    }
    return 0;
  }

  openServicesDetail() {
    store.dispatch("middleOfficeModule/showService", { showService: true });
  }
}
