
import { Vue, Options } from 'vue-class-component';

import { businessDataUtils, Proposal, proposalAccessoriesObjectTypes } from "@/types";
import store from '@/store';
import { globalConfig, serviceName } from '@/utils';
import i18n from '@/i18n';
import { formatAmoutswitchCurrency } from "@/utils/configuration/formatters-config";
import { getServiceCol } from "@/store/services/financing/financingSeriviceMapper";

@Options({
  props: ['proposal'],
})
export default class FinancingServicesDetail extends Vue {

  proposal?: Proposal;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  serviceName = serviceName;
  get calculatestatus() {
    return config.VUE_APP_CALCULATE
  }
  openServicesDetail() {
    store.dispatch("middleOfficeModule/showService", { showService: false });
  }

  get partyInsurance() {
    let accessories: any[] = [];
    if (config.VUE_APP_CALCULATE === 'false' &&
      (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      accessories = this.proposal?.proposalItems[0].proposalAccessories.filter(
        (accessory: any) => accessory.objectType.includes(proposalAccessoriesObjectTypes.proposalPartyInsuranceObjectType)
        ||
          accessory.objectType.includes(proposalAccessoriesObjectTypes.proposalassetinsurance)
      ) || [];
    } else {
      accessories = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.servicePartyInsurance || [];
    }

    const uniqueInsurances = new Map();
    accessories.forEach((accessory: any) => {
      const key = JSON.stringify({
        resourceUid: accessory.accessoryProduct?.resourceUid,
        amountWTax: accessory.amountWTax?.amount,
        amountWoTax: accessory.amountWoTax?.amount
      });
      if (!uniqueInsurances.has(key)) {
        uniqueInsurances.set(key, accessory);
      } else {
        const existingAccessory = uniqueInsurances.get(key);
        if (accessory.associatedInsuranceBeneficiaries?.length) {
          existingAccessory.associatedInsuranceBeneficiaries = [
            ...existingAccessory.associatedInsuranceBeneficiaries || [],
            ...accessory.associatedInsuranceBeneficiaries
          ];
        }
      }
    });
    console.log("uniqueInsurances", Array.from(uniqueInsurances.values()))
    return Array.from(uniqueInsurances.values());
  }

  get serviceCol() {
    return getServiceCol()
  }

  get offer() {
    return store.state.middleOfficeModule?.offer
  }

  get servicesList() {
    let services;
    if (config.VUE_APP_CALCULATE === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)) {
      services = this.proposal?.proposalItems[0].proposalAccessories.filter((accessorie: any) => 
                accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalsimpleservice) ||
                accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalCommission) ||
                accessorie.objectType.includes(proposalAccessoriesObjectTypes.proposalmaintenance) ) || []
    }

    else {
      services = store.state.middleOfficeModule?.proposalScale?.scalePresentation?.services || []
    }
    return services;
  }

  insuranceServiceName(item: any) {
    const reference = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (item.label || item.accessoryProduct.resourceUid) : (item.accessoryProduct ? item.accessoryProduct.resourceUid : item.reference);
    return i18n.global.te(`demand.product.services.names.${reference?.split('.').join('_')}`) ? i18n.global.t(`demand.product.services.names.${reference?.split('.').join('_')}`) : reference?.split('.').join('_')
  }


  getAmountHT(service: any) {

    let amount = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (service.amountWoTax ? service.amountWoTax.amount : null) : (service.amountValueWoTax ? service.amountValueWoTax : null);

    if (amount && amount !== '-') {
      return `${formatAmoutswitchCurrency("EUR", amount)}`
    }
    else {
      return "-";
    }

  }

  getInsuranceAmount(service: any, includesTax: boolean) {
    const taxProperty = includesTax ? 'amountWTax' : 'amountWoTax';
    const taxValueProperty = includesTax ? 'amountValueWTax' : 'amountValueWoTax';

    const amount = this.calculatestatus === 'false' &&
      (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource)
      ? (service[taxProperty]?.amount ?? null)
      : (service[taxValueProperty] ?? null);

    const numericAmount = amount !== null ? parseFloat(amount) : null;

    if (numericAmount !== null && !isNaN(numericAmount)) {
      return formatAmoutswitchCurrency("EUR", numericAmount);
    }

    return '';
  }

  getAmountTTC(service: any) {
    const tax: number = service.rate ? Number(service.rate) : 0;
    const amount = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (service.amountWTax ? service.amountWTax.amount : null) : (service.amountValueWTax ? service.amountValueWTax : null);
    if (amount && amount !== '-') {
      const value = amount * (1 + tax);
      const result = `${formatAmoutswitchCurrency("EUR", value)}`;
      return result;
    } else {
      return null;
    }
  }
  get columns() {
    return globalConfig.table_config.middelOfficeServiceCols
  }
  getRows(service: any) {
    console.log("serviceeeeeeee", service)
    const paymentDatesOption = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (service.proposalAccessoryPaymentDatesOption ? service.proposalAccessoryPaymentDatesOption.resourceUid : null) : service.paymentDatesOption
    const calculationMethodOption = this.calculatestatus === 'false' && (this.offer?.businessData && this.offer?.businessData.API === businessDataUtils.offerApiSource) ? (service.proposalAccessoryCalculationMethodOption ? service.proposalAccessoryCalculationMethodOption.resourceUid : '-') : service.calculationMethodOption

    return [{
      rate: service.rate,
      basis: i18n.global.t(`middleOfficeService.basisValue.${service.basis}`),
      basisValue: service.basisValue,
      proposalAccessoryCalculationMethodOption: (calculationMethodOption ? i18n.global.t(`middleOfficeService.proposalAccessoryCalculationMethodOptionValue.${calculationMethodOption}`) : '-'),
      proposalAccessoryPaymentDatesOption: (paymentDatesOption ? i18n.global.t(`middleOfficeService.proposalAccessoryPaymentDatesOptionValue.${paymentDatesOption}`) : '-'),
      taxValue: (service.taxValue || 0) + " %"
    }]
  }

}

