import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "card mb-3" }
const _hoisted_2 = { class: "p-3 mb-3" }
const _hoisted_3 = { class: "card border-bottom-0 mb-0 tw-custom-tabs-nav-top-filter" }
const _hoisted_4 = { class: "card-header bg-white no-border" }
const _hoisted_5 = { class: "card-title d-flex justify-between align-items-center" }
const _hoisted_6 = { class: "row flex-grow-1" }
const _hoisted_7 = { class: "col-md-8" }
const _hoisted_8 = { class: "d-flex justify-content-between align-items-center" }
const _hoisted_9 = { class: "d-block mb-0 small text-muted" }
const _hoisted_10 = { class: "d-block mb-0 small text-muted" }
const _hoisted_11 = { class: "d-block mb-0 small text-muted" }
const _hoisted_12 = { class: "d-block mb-0 small text-muted" }
const _hoisted_13 = { class: "d-block mb-0 small text-muted" }
const _hoisted_14 = { class: "d-block mb-0 small text-muted" }
const _hoisted_15 = {
  key: 0,
  class: "card-body pt-0"
}
const _hoisted_16 = { class: "row" }
const _hoisted_17 = { class: "font-size-14 mb-1 text-primary w-100" }
const _hoisted_18 = { class: "row w-100 mb-2" }
const _hoisted_19 = { class: "card-body" }
const _hoisted_20 = { class: "card-body pb-0 pt-0" }
const _hoisted_21 = { class: "row form-group" }
const _hoisted_22 = {
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
  id: "demand_edit_filter_salesNetwork"
}
const _hoisted_23 = { class: "d-block mb-0 small text-muted" }
const _hoisted_24 = {
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
  id: "demand_edit_filter_channel"
}
const _hoisted_25 = { class: "d-block mb-0 small text-muted" }
const _hoisted_26 = {
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
  id: "demand_edit_filter_subChannel"
}
const _hoisted_27 = { class: "d-block mb-0 small text-muted" }
const _hoisted_28 = {
  class: "col-lg-3 col-md-6 col-sm-12 form-control no-border",
  id: "demand_edit_filter_commercial"
}
const _hoisted_29 = { class: "d-block mb-0 small text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DemandProgress = _resolveComponent("DemandProgress")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_tooltip = _resolveComponent("q-tooltip")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_spinner_facebook = _resolveComponent("q-spinner-facebook")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!
  const _component_q_select = _resolveComponent("q-select")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_DemandProgress, { taskDefinition: _ctx.TaskDefinition }, null, 8, ["taskDefinition"])
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("div", _hoisted_7, [
              _createElementVNode("div", _hoisted_8, [
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_9, _toDisplayString(_ctx.$t("task.labels.offerRef")), 1),
                  _createVNode(_component_q_input, {
                    modelValue: _ctx.refDemand,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.refDemand) = $event)),
                    readonly: "",
                    dense: "",
                    class: "font-size-12",
                    "data-testid": "task_detail_offerRef"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.$t("demand.filtreMenu.externeReference")), 1),
                  _createVNode(_component_q_input, {
                    modelValue: _ctx.externalReference,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.externalReference) = $event)),
                    readonly: "",
                    dense: "",
                    class: "font-size-12"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_11, _toDisplayString(_ctx.$t("demand.filtreMenu.crmOpportuniteId")), 1),
                  _createVNode(_component_q_input, {
                    modelValue: _ctx.crmOppID,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.crmOppID) = $event)),
                    readonly: "",
                    dense: "",
                    class: "font-size-12"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.$t("task.labels.offerStatus")), 1),
                  _createVNode(_component_q_input, {
                    modelValue: _ctx.statusDemand,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.statusDemand) = $event)),
                    readonly: "",
                    dense: "",
                    class: "font-size-12"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t("task.historiesStep.headers.creationDate")), 1),
                  _createVNode(_component_q_input, {
                    modelValue: _ctx.startDate,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.startDate) = $event)),
                    readonly: "",
                    dense: "",
                    class: "font-size-12"
                  }, null, 8, ["modelValue"])
                ]),
                _createElementVNode("div", null, [
                  _createElementVNode("label", _hoisted_14, _toDisplayString(_ctx.$t("task.labels.clientRef")), 1),
                  _createVNode(_component_q_input, {
                    modelValue: _ctx.refClient,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.refClient) = $event)),
                    readonly: "",
                    dense: "",
                    class: "font-size-12"
                  }, null, 8, ["modelValue"])
                ])
              ])
            ])
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_q_btn, {
              id: "demand_cancelOfferBtn",
              icon: "delete",
              class: "btn btn-outline-danger mr-2",
              onClick: _ctx.handleCancel
            }, {
              default: _withCtx(() => [
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("demand.button.cancel")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["onClick"]),
            _createVNode(_component_q_btn, {
              id: "demand_saveOfferBtn",
              icon: "save",
              class: "btn btn-outline-primary",
              loading: _ctx.isSaveLoading,
              onClick: _ctx.handleClick,
              disable: _ctx.$store.getters['authModule/hasMiddleOffice']
            }, {
              loading: _withCtx(() => [
                _createVNode(_component_q_spinner_facebook)
              ]),
              default: _withCtx(() => [
                _createVNode(_component_q_tooltip, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t("demand.button.save")), 1)
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["loading", "onClick", "disable"]),
            (_ctx.offerReferenceValue)
              ? (_openBlock(), _createBlock(_component_q_btn, {
                  key: 0,
                  id: "demand_initOfferWorkflowBtn",
                  "data-testid": "east_button",
                  "icon-right": "east",
                  class: "btn btn-outline-primary ml-2",
                  loading: _ctx.isInitWorkflowLoading,
                  onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.toDoListFilter())),
                  label: _ctx.$t('demand.button.initWorkflow')
                }, {
                  loading: _withCtx(() => [
                    _createVNode(_component_q_spinner_facebook)
                  ]),
                  _: 1
                }, 8, ["loading", "label"]))
              : _createCommentVNode("", true)
          ])
        ]),
        (_ctx.offer.offerComments && _ctx.offer.offerComments.length > 0)
          ? (_openBlock(), _createElementBlock("div", _hoisted_15, [
              _createElementVNode("div", _hoisted_16, [
                _createElementVNode("div", _hoisted_17, _toDisplayString(_ctx.$t('main.labels.comments')), 1),
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.offerComments, (comment, index) => {
                  return (_openBlock(), _createElementBlock("div", _hoisted_18, [
                    _createVNode(_component_q_expansion_item, {
                      class: "col-md-12 card mb-2 tw-items-dashed-group tw-level-one-group",
                      "expand-separator": "",
                      label: _ctx.$t('enum.offer.comments.' + comment.commentType.resourceUid)
                    }, {
                      default: _withCtx(() => [
                        _createElementVNode("div", _hoisted_19, _toDisplayString(comment.offerComment), 1)
                      ]),
                      _: 2
                    }, 1032, ["label"])
                  ]))
                }), 256))
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_20, [
        _createElementVNode("div", _hoisted_21, [
          _createElementVNode("div", _hoisted_22, [
            _createElementVNode("label", _hoisted_23, _toDisplayString(_ctx.$t("demand.filtreMenu.filtresList.reseau_de_vente")), 1),
            _createVNode(_component_q_select, {
              modelValue: _ctx.salesChanel,
              "onUpdate:modelValue": [
                _cache[7] || (_cache[7] = ($event: any) => ((_ctx.salesChanel) = $event)),
                _cache[8] || (_cache[8] = ($event: any) => (_ctx.onChangeChannel($event)))
              ],
              options: _ctx.getChannel,
              outlined: "",
              dense: "",
              "options-dense": _ctx.denseOpts
            }, null, 8, ["modelValue", "options", "options-dense"])
          ]),
          _createElementVNode("div", _hoisted_24, [
            _createElementVNode("label", _hoisted_25, _toDisplayString(_ctx.$t("demand.filtreMenu.filtresList.Canal")), 1),
            _createVNode(_component_q_select, {
              outlined: "",
              modelValue: _ctx.networkModel,
              "onUpdate:modelValue": [
                _cache[9] || (_cache[9] = ($event: any) => ((_ctx.networkModel) = $event)),
                _cache[10] || (_cache[10] = ($event: any) => (_ctx.onChangeNetworknode($event)))
              ],
              options: _ctx.networkList,
              dense: "",
              "options-dense": _ctx.denseOpts
            }, null, 8, ["modelValue", "options", "options-dense"])
          ]),
          _createElementVNode("div", _hoisted_26, [
            _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.$t("demand.filtreMenu.filtresList.Sous_Canal")), 1),
            _createVNode(_component_q_select, {
              outlined: "",
              modelValue: _ctx.networknodeModel,
              "onUpdate:modelValue": [
                _cache[11] || (_cache[11] = ($event: any) => ((_ctx.networknodeModel) = $event)),
                _cache[12] || (_cache[12] = ($event: any) => (_ctx.onChangeCommercial($event)))
              ],
              options: _ctx.networkNode,
              dense: "",
              "options-dense": _ctx.denseOpts
            }, null, 8, ["modelValue", "options", "options-dense"])
          ]),
          _createElementVNode("div", _hoisted_28, [
            _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.$t("demand.party.addressType.Commercial")), 1),
            _createVNode(_component_q_select, {
              outlined: "",
              dense: "",
              "emit-value": "",
              "map-options": "",
              modelValue: _ctx.commercial,
              "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.commercial) = $event)),
              options: _ctx.commercialSales,
              "options-dense": _ctx.denseOpts
            }, null, 8, ["modelValue", "options", "options-dense"])
          ])
        ])
      ])
    ])
  ], 64))
}