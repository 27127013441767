import { settings } from "@/settings";
import {
  Analysis,
  businessDataUtils,
  Offer,
  Question,
  Task,
  TaskExecuteAction,
  TaskState,
} from "@/types";
import {
  deepClone,
  deleteAttributesRecursively,
  headers,
  removeAttributesRecursively,
  UON,
} from "@/utils";
import { useApi } from "@/requests/useApi";
import { api } from "@/auth/api";
import { executeTask } from "@/store/services/task/workflow";
import store from "@/store";
import { saveOfferFromTask } from "../taskService";
import i18n from "@/i18n";
import Notify from 'quasar/src/plugins/Notify.js';;

const odm_analysis = `odm-analysis`;
const odm_decision = `odm-decision`;
const systemUid = `${settings.api_url}/${odm_analysis}/api/1/${odm_analysis}`;
//const baseUrl = `${settings.api_url}/${odm_analysis}/api/1/${odm_analysis}`
const odm_supportingdocument = "odm-supportingdocument";
const supportingDocumentBaseUrl = `${settings.api_url}/${odm_supportingdocument}/api/1/${odm_supportingdocument}`;

export async function executeAnalysisAction(
  taskState: TaskState,
  userId: string,
  offer: Offer,
  task: Task
) {
  const { anyDocsQualified } = anyQualifiedDocuments();
  if (anyDocsQualified) {
    Notify.create({
      actions: [{ icon: "close", color: "white" }],
      message: i18n.global.t("main.dialog.errorQualifiedDocs"),
      color: "warning",
      timeout: 5000,
    });
  }

  let taskExecuteAction: TaskExecuteAction = { taskId: "" };

    const cutomerParty = offer.associatedParties?.find(
      (el: any) =>
        el.associatedParty?.role.resourceUid === "CUSTOMER" &&
        el.associatedParty.third.type.id.includes("Organization")
    );

    const balanceSheets = cutomerParty
      ? cutomerParty?.associatedParty?.third?.organization?.balanceSheets
      : null;
      const chiffreAffaireDetails = balanceSheets
      ? balanceSheets[0]?.detail?.filter(
          (item: any) => item.nature.id.includes("affaire")
        )
      : null;
    if (chiffreAffaireDetails) {
      taskExecuteAction = {
        taskId: task.taskId || "",

        variables: {
          chiffreAffaireDetails: {
            value: JSON.stringify(chiffreAffaireDetails),
            type: "Json",
          },
          analysisDataInfo: {
            value: JSON.stringify(store.state.analysisModule?.analysisDataInfo),
            type: "Json",
          },
        },
      };

  } else {
    taskExecuteAction = {
      taskId: task.taskId || "",
      variables: {
        analysisDataInfo: {
          value: JSON.stringify(store.state.analysisModule?.analysisDataInfo),
          type: "Json",
        },
      },
    };
  }
  store.state.taskModule.offer = await saveOfferFromTask(offer);

  await validDocAnalysesProcess();

  return executeTask(taskExecuteAction);
}

export function anyQualifiedDocuments() {
  const qualified = i18n.global.t(
    `task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`
  );

  const gatheringProcess = store.state.taskModule.gatheringProcess;
  const anyDocsQualified = gatheringProcess
    .flatMap((gp: any) => gp.entityTargets)
    .flatMap((targetEntity: any) => targetEntity.documents)
    .some((doc: any) => doc.status === qualified || doc.status === "QUALIFIED");

  return { anyDocsQualified };
}

export function validDocuments() {
  const valid = i18n.global.t(
    `task.uploadDocument.headers.supportingDocumentStatus.VALID`
  );
  const qualified = i18n.global.t(
    `task.uploadDocument.headers.supportingDocumentStatus.QUALIFIED`
  );

  const gatheringProcess = store.state.taskModule.gatheringProcess;
  const allDocsValid = !gatheringProcess
    .flatMap((gp: any) => gp.entityTargets)
    .flatMap((targetEntity: any) => targetEntity.documents)
    .some(
      (doc: any) =>
        doc.status !== valid &&
        doc.status !== "VALID" &&
        doc.status !== qualified &&
        doc.status !== "QUALIFIED"
    );

  return { allDocsValid };
}

export async function validDocAnalysesProcess() {
  try {
    const gatheringProcess = store.state.taskModule.gatheringProcess;
    const sentToValidProcess: string[] = [];
    for (const gp of gatheringProcess) {
      for (const targetEntity of gp.entityTargets) {
        for (const doc of targetEntity.documents) {
          if (sentToValidProcess.indexOf(doc.processResourceUid) < 0) {
            await validProcess(doc.processResourceUid);
            sentToValidProcess.push(doc.processResourceUid);
            Notify.create({
              timeout: 5000,
              actions: [{ icon: "close", color: "white" }],
              message: i18n.global.t("main.dialog.validation.success"),
              color: "positive",
            });
          }
        }
      }
    }
  } catch (e) {
    console.error(e);
    return Promise.reject(e);
  }
}

async function validProcess(processId: string) {
  const validationRequestPayload = {
    resourceUid: processId,
    objectType: "odm.supportingdocument.gatheringprocess",
    systemUid: "odm-supportingdocument",
    status: {
      objectType: "odm.supportingdocument.processstatus",
      systemUid: "odm-supportingdocument",
      resourceUid: "VALID",
    },
  };
  return api().put(
    `${supportingDocumentBaseUrl}/process/${processId}/valid/`,
    validationRequestPayload
  );
}

function setAnalysis(analysisCloned: Analysis, offerId?: string) {
  // const array = analysisCloned.opinionQualifications[0].opinionQualifications.length;
  if (
    analysisCloned.opinionQualifications &&
    analysisCloned.opinionQualifications.length > 0 &&
    analysisCloned.opinionQualifications[0].opinionQualifications.length > 0
  ) {
    analysisCloned = {
      ...analysisCloned,

      analysisEntity: {
        resourceUid: analysisCloned.analysisEntity?.resourceUid,
        objectType: "odm.decision.decisionprocess",
        systemUid: "odm-decision",
      },
      reference: analysisCloned.reference,
      // status.internalCode:analysisCloned.status.internalCode==="IN_PROGRESS",
      opinionQualifications:
        analysisCloned.opinionQualifications[0].opinionQualifications,
    };
  } else {
    analysisCloned = {
      ...analysisCloned,

      owner: {
        objectType: "odm.user.leaseforgeuser",
        systemUid: "odm-user",
        resourceUid: store.state.authModule?.user.userId, //this.$store.state.authModule?.user.userId,
      },

      analysisEntity: {
        resourceUid: analysisCloned.analysisEntity?.resourceUid,
        objectType: "odm.decision.decisionprocess",
        systemUid: "odm-decision",
      },
      reference: analysisCloned.reference,
      // status.internalCode:analysisCloned.status.internalCode==="IN_PROGRESS",
      opinionQualifications: [],
    };
  }

  return analysisCloned;
}
export async function takeOpinion(analysis: Analysis, offerId?: string) {
  const clone = setAnalysis(deepClone(analysis), offerId);
  const { error } = await useApi(UON.SystemUid.odmAnalysis, "process").upsert(
    clone
  );
  if (error) {
    throw error;
  }
}

export async function getAnalysis(resourceUID: string) {
  return new Promise<any>(async (resolve, reject) => {
    await api()
      .get(`${systemUid}/process/${resourceUID}/`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}
export async function getOpinion(resourceUid: string) {
  return new Promise<any>(async (resolve, reject) => {
    await api()
      .get(`${systemUid}/opinion/${resourceUid}/`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}

export async function updateAnalysis(analysis: Analysis, offerId?: string) {
  const clone = setAnalysis(deepClone(analysis), offerId);

  const { response, error } = await useApi(
    UON.SystemUid.odmAnalysis,
    "process"
  ).upsert(clone);
  if (error) {
    throw error;
  }
  return response;
}

export async function getAnalysisAlgorithmeData(
  resourceUID: string,
  payload: any,
  targetApi: string
) {
  return new Promise<any>(async (resolve, reject) => {
    await api()
      .post(`${systemUid}/analysis/${resourceUID}/${targetApi}/`, payload)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}

export async function getScoreAnalysis(resourceUID: string) {
  return new Promise<any>(async (resolve, reject) => {
    await api()
      .get(`${systemUid}/analysisscore/${resourceUID}/`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}

export const getQuestionsByProcessId = (process_id: string) =>
  new Promise((resolve, reject) => {
    return api()
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "weleaf-questionnaire-questions",
            offset: 0,
            limit: 10000,
            parameters: { process_id: process_id },
          }),
        }),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export const getRatiosByProcessId = (process_id: string) =>
  new Promise((resolve, reject) => {
    return api()
      .get(settings.api_query_url, {
        headers: headers({
          qc: JSON.stringify({
            queryId: "weleaf-ratio-category",
            offset: 0,
            limit: 10000,
            parameters: { process_id: process_id },
          }),
        }),
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((err) => {
        reject(err);
      });
  });

export async function UpdateQuestionnaireGathering(
  questions: Question[],
  gatheringItem: any
) {
  const clone = {
    ...gatheringItem,
    questions: questions,
  };

  const { response, error } = await useApi(
    UON.SystemUid.odmSupportingDocument,
    "questionnairegathering"
  ).upsert(clone);
  if (error) {
    throw error;
  }
  return response;
}

export async function getAnalysisEvaluatedReserves(payload: any) {
  const systemUid = `${settings.api_url}/${odm_decision}/api/1/${odm_decision}`;
  const analysisData = JSON.parse(payload.data);
  const request = {
    // "applicationName": payload.task.variables.applicationName.value,
    applicationName: "Origination",
    context: "Financing",
    partyInfo: analysisData.partyInfo,
    quoteInfo: JSON.stringify(analysisData.quoteInfo),
  };

  return new Promise<any>(async (resolve, reject) => {
    await api()
      .post(`${systemUid}/decision/selection/reserves/`, request)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}

export async function getAssociatedAnalysisProcess(resourceUID: string) {
  const systemUid = `${settings.api_url}/${odm_decision}/api/1/${odm_decision}`;

  return new Promise<any>(async (resolve, reject) => {
    await api()
      .get(`${systemUid}/decisionprocess/${resourceUID}/`)
      .then((res) => resolve(res.data))
      .catch((e) => reject(e));
  });
}

export async function UpdateAssociatedAnalysisProcess(payload: any) {
  const { response, error } = await useApi(
    UON.SystemUid.odmDecision,
    "associatedanalysisprocess"
  ).upsert(payload);
  if (error) {
    throw error;
  }
  return response;
}

export const decisionMock = async () => {
  return await [
    {
      decisionId: 1,
      decision: "Offre Acceptée",
      date: "19/12/2023 14:28",
      decideur: "Vendeur 1",
      profile: "Vendeur Partenaire",
      avis: [],
    },
    {
      decisionId: 2,
      decision: "Décision en cours",
      date: "-",
      decideur: "-",
      profile: "-",
      avis: [
        {
          avisId: 1,
          dateAvis: "19/12/2023 14:28",
          avis: "Favorable",
          referenceAnalyse: "ert1234",
          decideur: "Analyste_Junior",
          commentaire: "Avis favorable",
          reserves: [
            { id: 1, reserve: "Hypothèque", commentaire: "teamwill" },
            { id: 2, reserve: "Caution avec quotité %", commentaire: "weleaf" },
          ],
        },
        {
          avisId: 2,
          dateAvis: "19/12/2023 14:28",
          avis: "Favorable",
          referenceAnalyse: "uy659",
          decideur: "Analyste_Senior",
          commentaire: "Avis favorable",
          reserves: [{ id: 1, reserve: "Hypothèque", commentaire: "efficom" }],
        },
      ],
    },
  ];
};
