
import { Options, Vue } from "vue-class-component";
import store from "@/store";
import {
  formatAmoutswitchCurrency,
  formatWithMask,
} from "@/utils/configuration/formatters-config";
import { ProposalAssetItem, ProposalAssetItemType } from "@/types";
import { globalConfig } from "@/utils";
import { useRoute } from "vue-router";
import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import twCurrencyInput from "@/components/general/twCurrencyInput.vue";
import { currencyConfiguration } from "@/utils/configuration/currencyInput-config";
@Options({
  props: ["tabPosition", "type", "label"],
  components: {
    twCurrencyInput,
  },
})
export default class AssetProductPackItem extends Vue {
  formatWithMask = formatWithMask;
  formatAmoutswitchCurrency = formatAmoutswitchCurrency;
  tabPosition?: number;
  type?: ProposalAssetItemType;
  label?: string;
  list = [];
  taxList = [];
  formattedTotalAmountWTax = function formattedTotalAmountWTax(item: any) {
    return item.totalAmountWTax.amount || 0;
  };

  get currencyConfiguration() {
    return currencyConfiguration;
  }
  get currentRoute() {
    return useRoute().name;
  }

  get assetState() {
    return store.state.demandModule.assetState;
  }

  get assets() {
    return this.assetState.proposalAssets;
  }

  get asset() {
    return this.assetState.proposalAssets[this.tabPosition!];
  }

  get proposalAssetItems() {
    return (this.asset?.proposalAssetItems || []).filter((item: any) => {
      if (typeof item.type === 'string') { return item.type === this.type; }
      return item.type?.resourceUid === this.type;
    });
  }

  get selectLabel() {
    return globalConfig.capitalize((this.label || "").toLowerCase());
  }

  get proposalAssetItemsTotal() {
    const totalAmount = this.proposalAssetItems
      .filter((item) => item.config.selected)
      .reduce((a: number, b: ProposalAssetItem) => {
        return a + (b.totalAmountWTax?.amount || 0);
      }, 0);
    return formatAmoutswitchCurrency("EUR", totalAmount);
  }

  addNewProposalAssetItem() {
    store.dispatch("demandModule/addNewProposalAssetItem", {
      type: this.type,
      index: this.tabPosition,
    });
  }

  removeProposalAssetItem(item: ProposalAssetItem) {
    store.dispatch("demandModule/removeProposalAssetItem", {
      proposalAssetItem: item,
      index: this.tabPosition,
    });
  }

  unselectProposalAssetItem(item: ProposalAssetItem) {
    store.dispatch("demandModule/updateProposalAssetItem", {
      proposalAssetItem: item,
      index: this.tabPosition,
    });
  }

  changeProposalAssetItemDiscount(item: ProposalAssetItem) {
    item.discountPercentage = this.validateValue(item.discountPercentage || 0, true);
    store.dispatch("demandModule/calculateProposalAssetItem", {
      proposalAssetItem: item,
      index: this.tabPosition,
    });
  }

  changeAmountWoTaxProposalAssetItem(item: ProposalAssetItem, value: number) {
    ;
    const validatedValue = this.validateValue(value, false);
    item.config.unitaryAmountModel = validatedValue;
    item.amountWoTax = { amount: validatedValue, currency: "EUR" };
    store.dispatch("demandModule/calculateProposalAssetItem", {
      proposalAssetItem: item,
      index: this.tabPosition,
    });
  }

  changeSelectProposalAssetItem(item: ProposalAssetItem) {
    item.label = item.config.selectModel.label;
    const resourceUid = item.config.selectModel.id;
    store.dispatch("demandModule/setProposalItemFields", {resourceUid: resourceUid,
      callback: (result: any) => {
        item.config.unitaryAmountModel = result.feature[0].standardValue
        this.changeAmountWoTaxProposalAssetItem(item, result.feature[0].standardValue)
        }
      })
    }
    
  
  changeTaxProposalAssetItem(item: ProposalAssetItem) {
    item.taxValue = item.config.taxValueModel.id;
    store.dispatch("demandModule/calculateProposalAssetItem", {
      proposalAssetItem: item,
      index: this.tabPosition,
    });
  }

  validateValue(value: number, isPercent: boolean) {
    let result = value;
    if (!isPercent && value < 0) {
      Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
        message: `${i18n.global.t("main.dialog.validation.negative")}`,
        color: "warning",
      });
      result = value * -1;
    } else if (isPercent) {
      if (value < 0) {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: `${i18n.global.t("main.dialog.validation.negative")}`,
          color: "warning",
        });
        value *= -1;
        result = value;
      }
      if (value > 100) {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: `${i18n.global.t("main.dialog.validation.greater100")}`,
          color: "warning",
        });
        result = 100;
      }
    }
    return result;
  }

  created() {
    const categoryCode = "NATURE";
    const categoryClassCode =
      this.type === ProposalAssetItemType.option
        ? `${categoryCode}.OPTION`
        : this.type === ProposalAssetItemType.accessory
        ? `${categoryCode}.ACCESSORY`
        : `${categoryCode}.ASSETFEE`;

    store.dispatch("demandModule/assetCustom", {
      callback: (res: any) => (this.list = res),
      filter: {
        category_code: categoryCode,
        category_class_code: categoryClassCode,
      },
    });

    store.dispatch("demandModule/assetTax", {
      callback: (res: any) => (this.taxList = res),
    });
  }
}
