
import { Options, Vue } from "vue-class-component";

import { ionFootstepsOutline } from "@quasar/extras/ionicons-v5";

import { ref } from "vue";
import store from "@/store";
import { globalConfig } from "@/utils";
import {
  CalculationStep,
  CalculationType,
  PricingCalculationRequest,
  PricingCalculationResponse,
  ServiceConfig,
  SlideType,
  Step,
} from "@/types";
// import QCurrencyInput from "@/components/general/QCurrencyInput.vue";

import Notify from 'quasar/src/plugins/Notify.js';;
import i18n from "@/i18n";
import {
  deleteFormatMaskAndConvertToNumber,
  formatWithMask,
} from "@/utils/configuration/formatters-config";

@Options({
  // components: { QCurrencyInput },
  computed: {
    isStepBtnDisabled: function () {
      return !this.stepBtn;
    },
    isServiceBtnDisabled: function () {
      return !this.servicesBtn;
    },
  },
  watch: {
    dtPaymentModel() {
      this.setTouched();
    },
    termModel() {
      this.setTouched();
    },
    dtDebut() {
      this.setTouched();
    },
  },
})
export default class ExpertDemandSimulationForm extends Vue {
  touched = false;
  calculationTypeSelected = "Payment";
  stepList: Step[] = [];

  termModel: any = ref(null);
  inputPrice = "0,00";
  rate = 0;
  dtDebut = new Date();
  dtPaymentModel: any = ref(null);
  periodRateOptionModel: any = ref(null);
  inputTax = 0;
  servicesBtn = true;
  stepBtn = true;
  configurationBtn = true;
  residualValueRate = "0,0";
  residualValue = "0,00";
  monthlyRate = "0,0";
  monthlyValue = "0,00";
  apportValue = "0,00";
  apportRate = "0,0";
  identityBtn = true;
  inputWarrant = "";
  warrantMethodModel: any = ref(null);
  isCredit = false;

  get configuration() {
    return globalConfig.table_config.expertMode;
  }
  get services() {
    return store.state.simulationModule.selectedServices;
  }

  get serviceList() {
    return store.state.simulationModule.services;
  }

  get stepSelected() {
    return this.stepList.filter((step: Step) => step.selected)[0];
  }

  setTouched() {
    this.touched = true;
    store.dispatch("simulationModule/clearCalculatePricingResponse", {});
  }

  onSelectChange(step: Step, index: number) {
    step.step = index;
    this.stepList.forEach((item) => (item.selected = item.id === step.id));
  }

  addStep() {
    delete this.stepList[this.stepList.length - 1].residualValue;
    delete this.stepList[this.stepList.length - 1].residualValueRate;
    const id = this.stepList.length + 1;
    this.stepList.push(this.stepDefault(id, 1, this.rate));
  }

  stepDefault(id: number = 0, duration: number = 1, rate: number = 0): Step {
    return {
      id,
      duration,
      selected: false,
      terme: this.termModel,
      calculationMethod: this.dtPaymentModel,
      rate,
      periodicity: 1,
      periodicityCode: "MONTHS",
    };
  }
  updateAllStepTax() {
    const isValidTax = this.rate - this.rateFinancingTax >= 1 ? true : false;
    if (!isValidTax) {
      this.rate = this.rateFinancingTax + 1;
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidSlideValue"),
        color: "negative",
      });
    }
    this.stepList.forEach((item) => (item.rate = this.rate));
  }
  updateAllStepTerm() {
    this.stepList.forEach((item) => (item.terme = this.termModel));
  }

  removeService(service: ServiceConfig) {
    store.dispatch("simulationModule/removeSelectedServices", { service });
  }
  addService() {
    const service = {
      id: this.serviceList?.length ? this.serviceList?.length + 1 : 0,
      config: {
        required: false,
        selected: true,
      },
      type: "",
      name: "",
      baseCalcule: "",
      min: 0,
      valuePercent: 0,
      max: 0,
      qt: 0,
      duration: 0,
      montantHt: 0,
      montantTtc: 0,
      paymentFrequence: {
        id: "mois",
        label: "Mois",
      },
      multiple: 1,
    };
    store.dispatch("simulationModule/addSelectedService", { service });
  }

  removeStep(id: number) {
    this.stepList = this.stepList.filter((item) => item.id !== id);
  }

  calculate() {
    if (this.validateCalculationState()) {
      store.dispatch("simulationModule/executeCalculatePricing", {
        type: this.calculationTypeSelected,
        request: this.buildRequest(),
        callback: (result: PricingCalculationResponse) => {
          if (this.validateCalculationResult(result)) {
            this.readCalculationResult(result);
            this.touched = false;
          }
        },
      });
    }
  }

  validateCalculationState(): boolean {
    let result = false;
    if (this.stepSelected) {
      const emptyFields = [
        this.stepSelected.paymentValue || undefined,
        this.apportValue,
        this.residualValue,
      ].filter((item) => !item);
      if (emptyFields.length > 1) {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: "Error",
          color: "negative",
        });
      } else {
        result = true;
      }
    }
    return result;
  }

  validateCalculationResult(response: PricingCalculationResponse): boolean {
    let result = false;
    let value = response.terms[2].financialInstallment;
    if (value <= 0) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `Calculation returned an invalid result ${value}`,
        color: "negative",
      });
    } else {
      result = true;
    }
    return result;
  }

  readCalculationResult(response: PricingCalculationResponse) {
    let monthlyPayment = this.state.monthlyValue || 0;
    let firstPayment = this.state.firstPaymentValue || 0;
    let firstPaymentRate = this.state.firstPaymentRate || 0;
    let residualValue = this.state.residualValue || 0;
    let residualValueRate = this.state.residualValueRate || 0;
    let duration = this.state.duration || 0;
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    const stepIndex = this.stepSelected?.step || 0;
    let value = 0;
    const step = this.stepList[stepIndex];
    switch (this.calculationTypeSelected) {
      case CalculationType.PAYMENT:
        value =
          stepIndex > 0
            ? this.validateCalculationValue(response.terms[2].financialInstallment)
            : this.validateCalculationValue(response.terms[1].financialInstallment);
        step.paymentValue = value;
        step.paymentRate = (value / price) * 100;
        monthlyPayment = step.paymentValue;
        break;
      case CalculationType.ANNUAL_RATE:
        value = this.validateCalculationValue(response.result * 100);
        step.rate = value;
        break;
      case CalculationType.OUTSTANDING_BALANCE_AFTER:
        value = this.validateCalculationValue(
          response.terms[response.terms.length - 1].financialInstallment
        );
        step.residualValue = value;
        step.residualValueRate = (value / price) * 100;
        residualValue = value;
        break;
    }
    if (firstPayment == 0 && firstPaymentRate > 0) {
      firstPayment = (firstPaymentRate * price) / 100;
    }
    if (residualValue == 0 && residualValueRate > 0) {
      residualValue = (residualValueRate * price) / 100;
    }
    const summary = {
      monthlyPayment: monthlyPayment,
      firstPayment: firstPayment,
      duration: duration,
      residualValue: residualValue,
    };
    store.dispatch("simulationModule/updateSummary", { summary });
  }

  validateCalculationValue(value?: number, callback?: any) {
    if (value && value > 0) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("main.dialog.validation.success"),
        color: "positive",
      });
      if (callback) {
        callback(value);
      }
      return value;
    } else {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: `Calculation returned an invalid result ${value}`,
        color: "warning",
      });
      return 0;
    }
  }

  buildRequest(): PricingCalculationRequest {
    return {
      searchStep: this.stepSelected?.step || 0,
      date: globalConfig.formatters.formatDateService(this.dtDebut, "-"),
      steps: this.buildSteps(),
      services: [],
    };
  }

  buildSteps(): CalculationStep[] {
    const steps = this.stepList.map((item) => {
      const currency = "EUR";
      const unit = "MONTHS";
      return {
        currency,
        paymentDatesOption: item.calculationMethod.id,
        periodRateOption: "NominalPeriodRateMethod",
        paymentTermsOption: item.terme.id,
        annualRate: item.rate ? (Number(item.rate) + Number(this.inputTax)) / 100 : -1,
        numberOfPaymentTerms: item.duration,
        outstandingBalanceBefore: {
          amount: -1,
          currency,
        },
        outstandingBalanceAfter: {
          amount: item.residualValue || -1,
          currency,
        },
        payment: {
          amount: item.paymentValue || -1,
          currency,
        },
        periodBetween2Installments: {
          duration: item.periodicity || 1,
          unit: item.periodicityCode || unit,
        },
      };
    });

    steps[0].outstandingBalanceBefore.amount = deleteFormatMaskAndConvertToNumber(
      this.inputPrice.toString()
    );

    const lastStep = this.stepList[this.stepList.length - 1];
    steps[steps.length - 1].outstandingBalanceAfter = {
      amount: lastStep.residualValue || -1,
      currency: "EUR",
    };

    return steps;
  }

  get stepIcon() {
    return ionFootstepsOutline;
  }

  onChangeTypeService(typeServiceSelected: any) {
    const parentId = typeServiceSelected.id;
    store.dispatch("picklistModule/setNamesServices", { parentId });
  }

  get getNamesService() {
    return store.state.picklistModule.namesServices;
  }

  get getTerm() {
    return store.getters["picklistModule/termItems"];
  }
  get getBaseCalcule() {
    return store.getters["picklistModule/baseCalculeItems"];
  }

  get getTypeService() {
    return store.getters["picklistModule/typeServiceItems"];
  }

  get getPaymentDate() {
    return store.getters["picklistModule/paymentDateItems"];
  }

  get getPaymentFrequence() {
    return store.getters["picklistModule/paymentFrequenceItems"];
  }

  get getPeriodRateOption() {
    return store.getters["picklistModule/periodRateOptionItems"];
  }

  get getWarrantMethod() {
    return store.getters["picklistModule/warrantMethodItems"];
  }

  get state() {
    return store.state.simulationModule.expertModeState;
  }

  get rateFinancingTax() {
    return store.state.simulationModule.rateFinancingTax;
  }

  onPriceChange(value: string) {
    const numValue = deleteFormatMaskAndConvertToNumber(value);
    store.dispatch("simulationModule/changePrice", { price: numValue });
    this.inputPrice = formatWithMask(numValue || 0, 2);
    this.changeState();
  }

  onTypeSimulation() {
    const type =
      store.state.simulationModule.typeSimulation === "normal" ? "expert" : "normal";
    store.dispatch("simulationModule/updateTypeSimulation", { type: type });
  }

  created() {
    this.termModel = this.getTerm[0];
    this.dtPaymentModel = this.getPaymentDate[0];
    this.inputPrice = (
      store.state.financingModule.proposalAssetTotalWithTax || 0
    ).toString();
    this.inputTax = this.rateFinancingTax;
    this.initStepValues();
    this.initStateValues();
  }

  initStepValues() {
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    const paymentValue = price > 0 ? this.state.monthlyValue || 0 : 0;
    const paymentRate = price > 0 && paymentValue > 0 ? (paymentValue / price) * 100 : 0;
    if (this.state.firstPaymentValue) {
      const step1 = this.stepDefault(1);
      step1.paymentValue = this.state.firstPaymentValue;
      step1.paymentRate = deleteFormatMaskAndConvertToNumber(
        formatWithMask((this.state.firstPaymentValue / price) * 100, 2)
      );
      step1.rate = this.state.rate || 0;
      const step2 = this.stepDefault(2);
      step2.duration = (this.state.duration || 2) - 1;
      step2.rate = this.state.rate || 0;
      step2.residualValue = this.state.residualValue || 0;
      step2.residualValueRate = this.state.residualValueRate || 0;
      step2.paymentValue = paymentValue;
      step2.paymentRate = paymentRate;
      this.stepList.push(step1, step2);
    } else {
      const step1 = this.stepDefault(1);
      step1.duration = this.state.duration || 1;
      step1.rate = this.state.rate || 0;
      step1.residualValue = this.state.residualValue || 0;
      step1.residualValueRate = this.state.residualValueRate || 0;
      step1.paymentValue = paymentValue;
      step1.paymentRate = paymentRate;
      this.stepList.push(step1);
    }
  }

  initStateValues() {
    if (this.state) {
      this.rate = this.rate = this.state.rate || 0;
      this.residualValue = formatWithMask(this.state.residualValue || 0, 2);
      this.residualValueRate = formatWithMask(this.state.residualValueRate || 0, 2);
      this.monthlyValue = formatWithMask(this.state.monthlyValue || 0, 2);
      this.monthlyRate = formatWithMask(this.state.monthlyRate || 0, 2);
      this.apportValue = formatWithMask(this.state.firstPaymentValue || 0, 2);
      this.apportRate = formatWithMask(this.state.firstPaymentRate || 0, 2);
      this.isCredit = this.state.isCredit || false;
      this.onChangeResidualValueRate();
      this.onChangeResidualValue();
      this.onChangeApportRate();
      this.onChangeApport();
    }
  }

  mounted() {
    this.touched = false;
  }

  changeState() {
    this.setTouched();
    const duration = this.state.duration || 0;
    const rv = deleteFormatMaskAndConvertToNumber(this.residualValue);
    const rvRate = deleteFormatMaskAndConvertToNumber(this.residualValueRate);
    const apportValue = deleteFormatMaskAndConvertToNumber(this.apportValue);
    const apportRate = deleteFormatMaskAndConvertToNumber(this.apportRate);
    const monthlyValue = deleteFormatMaskAndConvertToNumber(this.monthlyRate);
    const monthlyRate = deleteFormatMaskAndConvertToNumber(this.monthlyRate);
    store.dispatch("simulationModule/changeExpertModeState", {
      body: {
        isCredit: this.isCredit,
        rate: this.rate,
        duration,
        monthlyValue: monthlyValue,
        monthlyRate: monthlyRate,
        residualValue: rv,
        residualValueRate: rvRate,
        firstPaymentValue: apportValue,
        firstPaymentRate: apportRate,
      },
    });
  }
  onChangeResidualValueRate() {
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    const rv = deleteFormatMaskAndConvertToNumber(this.residualValueRate.toString());
    if (price > 0 && rv > 0 && rv <= 100) {
      this.residualValue = formatWithMask((price * rv) / 100, 2);
      this.residualValueRate = formatWithMask(rv, 1);
    } else if (rv > 100) {
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: i18n.global.t("demand.simulation.invalidResidualValue"),
        color: "negative",
      });
      this.onChangeResidualValue();
    }

    this.changeState();
  }

  onChangeResidualValue() {
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    const rv = deleteFormatMaskAndConvertToNumber(this.residualValue);
    if (price > 0 && rv > 0) {
      const rvRate = (rv / price) * 100;
      if (rvRate <= 100) {
        this.residualValueRate = formatWithMask((rv / price) * 100, 1);
        this.residualValue = formatWithMask(rv, 2);
      } else {
        Notify.create({
          timeout: 10000,
          actions: [{ icon: 'close', color: 'white' }],
          message: i18n.global.t("demand.simulation.invalidResidualValue"),
          color: "negative",
        });
        this.onChangeResidualValueRate();
      }
    }
    this.changeState();
  }

  onChangeApportRate() {
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    const apportRate = deleteFormatMaskAndConvertToNumber(this.apportRate);
    if (price > 0 && apportRate > 0) {
      this.apportValue = formatWithMask((price * apportRate) / 100, 2);
      this.apportRate = formatWithMask(apportRate, 1);
    }
    this.changeState();
  }

  onChangeApport() {
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    const apportValue = deleteFormatMaskAndConvertToNumber(this.apportValue);
    if (price > 0 && apportValue > 0) {
      this.apportRate = formatWithMask((apportValue / price) * 100, 1);
      this.apportValue = formatWithMask(apportValue, 2);
    }
    this.changeState();
  }

  onChangeFirstPaymentRate(item: Step) {
    const rate = item.paymentRate || 0;
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    if (price > 0 && rate > 0) {
      item.paymentValue = (price * rate) / 100;
    } else {
      item.paymentValue = 0;
    }
    this.changeState();
  }

  onChangeFirstPayment(item: Step) {
    const price = deleteFormatMaskAndConvertToNumber(this.inputPrice.toString());
    const apportValue = deleteFormatMaskAndConvertToNumber(this.apportValue);
    const value = item.paymentValue || 0;
    if (price > 0 && apportValue > 0) {
      item.paymentRate = (value / price) * 100;
    } else {
      item.paymentRate = 0;
    }
    this.changeState();
  }
}
