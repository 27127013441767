import { createI18n } from 'vue-i18n'
import fr from './locales/fr-FR.json'


export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: "fr", //default Local to be updated by the response from API
  fallbackLocale: "fr", // used when key missing from json related to selected local
  messages: { fr }, //object for fallback "static JSON file into locales repo"
  silentTranslationWarn: true, // Suppress translation warnings
  silentFallbackWarn: true, // Suppress fallback warnings
  missingWarn: false, // Disable missing key warnings
  fallbackWarn: false, // Suppress warnings when falling back to fallbackLocale
  missing: (locale, key) => {
    return key; // Return the key as the fallback text
  },
})
