import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2e717374"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "col-12" }
const _hoisted_2 = { class: "row flex-column service-list-responsive" }
const _hoisted_3 = { class: "card-body border-bottom" }
const _hoisted_4 = { class: "col-lg-4" }
const _hoisted_5 = { class: "col-lg-4 font-weight-bold" }
const _hoisted_6 = { class: "col-lg-4 font-weight-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_item_section = _resolveComponent("q-item-section")!
  const _component_SummaryFinancingPartyInsurance = _resolveComponent("SummaryFinancingPartyInsurance")!
  const _component_q_expansion_item = _resolveComponent("q-expansion-item")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_q_expansion_item, {
        "default-opened": "",
        "expand-icon-toggle": "",
        "header-class": "align-items-center bg-secondary justify-content-between mb-3 mt-3"
      }, {
        header: _withCtx(() => [
          _createVNode(_component_q_item_section, null, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.capitalize(_ctx.$t("summaryPanel.financing.service.label").toLowerCase())), 1)
            ]),
            _: 1
          })
        ]),
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servicesList, (item, index) => {
              return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "bg-secondary default-border-radius p-3 mb-2 row"
              }, [
                _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.serviceName(_ctx.calculatestatus, item, _ctx.offer)), 1),
                _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.amountHT(true, _ctx.calculatestatus, _ctx.paymentDatesOption(_ctx.calculatestatus, item, _ctx.offer), item, _ctx.offer)), 1),
                _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.amountTTC(true, _ctx.calculatestatus, _ctx.paymentDatesOption(_ctx.calculatestatus, item, _ctx.offer), item, _ctx.offer)), 1)
              ]))
            }), 128)),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.partyInsurance, (item, index) => {
              return (_openBlock(), _createBlock(_component_SummaryFinancingPartyInsurance, {
                key: index,
                data: item
              }, null, 8, ["data"]))
            }), 128))
          ])
        ]),
        _: 1
      })
    ])
  ]))
}