
import { Options, Vue } from "vue-class-component";
import { ref } from "vue";
import { Emit } from "vue-property-decorator";
import { formatDate } from "@/utils/configuration/formatters-config";

import store from "@/store";
import {
  AssociatedParty,
  InventoryStatusEnum,
  Offer,
  OfferAssociatedParty,
  TaskSearchCriteria,
  ThirdType,
} from "@/types";
import router from "@/router";
import i18n from "@/i18n";
import DemandProgress from "@/components/demand/details/DemandProgress.vue";
import { getPartyOrganization } from "@/store/services/offerService";
import { getTaskListByUser } from "@/store/services/task/workflow";
import { ValidateMandatoryFields, deepClone, validateContents, sortOfferComments } from "@/utils";
import Notify from 'quasar/src/plugins/Notify.js';;

@Options({
  props: ["parties"],
  components: {
    DemandProgress,
  },
  computed: {
    refDemand() {
      return store.state.demandModule.offer?.reference
        ? store.state.demandModule.offer.reference
        : "- - - - - - -";
    },
    statusDemand() {
      if (store.state.demandModule.offer?.status) {
        const resourceUid = store.state.demandModule.offer?.status.resourceUid;
        return resourceUid
          ? i18n.global.t("enum.offer.status.".concat(resourceUid))
          : "- - - - - - -";
      } else {
        return "- - - - - - -";
      }
    },

    refClient() {
      if (store.state.demandModule.offer?.associatedParties) {
        const response = "- - - - - - -";
        const index = store.state.demandModule.offer?.associatedParties?.findIndex(
          (offerAssociatedParty: any) => offerAssociatedParty.associatedParty.role_code === "CUSTOMER"
        );
        if (index > -1) {
          const customer = store.state.demandModule.offer?.associatedParties[index];
          if (customer.associatedParty?.third && customer.associatedParty?.third.type?.id?.toLocaleLowerCase() !== ThirdType.ORGANIZATION.toLocaleLowerCase()) {
            return customer.associatedParty?.third.person?.reference ? customer.associatedParty?.third.person?.reference : response
          }
          return customer.associatedParty?.third?.organization?.reference ? customer.associatedParty?.third.organization?.reference : response
        }
        return response;
      }
    },
    startDate() {
      if (store.state.demandModule.offer?.validity) {
        const startDate = store.state.demandModule.offer?.validity.from;
        return startDate ? startDate : ref(formatDate(new Date(), "/"));
      }
    },
  },
})
export default class DemandRegister extends Vue {
  isInitWorkflowLoading?: boolean = false;
  parties?: OfferAssociatedParty[];
  associatedPartyUser = "";
  commercial = store.state.demandModule.offer?.assignedSalesPerson;
  destributer = "";
  networknodeModel = store.state.demandModule.offer?.associatedNetworkNodes;
  userconnected = store.state.authModule?.user.username;
  networkModel = store.state.demandModule.offer?.associatedNetworks;
  salesMan = ref();
  networkList = [];
  networkNode = [];
  commercialSales = [];
  options = ["A", "B", "C", "Démarche"];
  optionCanal = ["web site", "mobile app", "network"];
  date = ref(formatDate(new Date(), "/"));
  stepCard = ref(1);
  simulation = ref(false);
  clientFeedback = ref(false);
  validateContents = validateContents;
  get offerComments() {
    return sortOfferComments(this.offer?.offerComments)
  }
  get externalReference() {
    return store.state.demandModule.offer?.businessData?.proposalReference || '-'
  }
  get crmOppID() {
    return store.state.demandModule.offer?.businessData?.crmOpportuniteId || '-'
  }
  get offerReferenceValue() {
    return store.state.demandModule.offer?.reference;
  }
  get TaskDefinition(){
    return store.state.demandModule.offer?.status.resourceUid;
  }
  
  get isSaveLoading() {
    return store.state.demandModule.isSaving;
  }
    get requiredMessage() {
    return i18n.global.t("main.messageRequiredField");
  }
  handleCancel() {
  const assets = store.state.demandModule.offer?.proposals?.[0]?.proposalItems?.[0]?.proposalAssets;

  if (assets) {
    assets.forEach(asset => {
      if (asset.config?.productAssetModel?.config?.inventoryItems?.length > 0) {
        const inventoryItems = asset.config.productAssetModel.config.inventoryItems;
        inventoryItems.forEach((item:any) => {
          store.dispatch("orderModule/updateInventoryItem", {
            inventoryItemCode: item.resourceUid,
            status: InventoryStatusEnum.IN_STOCK
          });
        });
      }
    });
  }
  router.push("/demand/list");
}

   handleClick() {
    this.validateContents()
    if (ValidateMandatoryFields()) {
      this.$emit('registerClick');
    }
    else{
      Notify.create({
        timeout: 10000,
        actions: [{ icon: 'close', color: 'white' }],
        message: this.requiredMessage,
        color: "negative",
      });
    }
  }

  get offer() {
    return store.state.demandModule.offer;
  }

  get getChannel() {
    return store.getters["picklistModule/channelItems"];
  }
  get salesChanel() {
    if (store.state.demandModule.offer && !store.state.demandModule.offer?.salesChannel) {
      store.state.demandModule.offer.salesChannel = ""
    }
    return store.state.demandModule.offer?.salesChannel.resourceUid || store.state.demandModule.offer?.salesChannel
  }

  updateCommercial(res: any) {
    if (res.items.length == 1) {
      this.commercial = res.items[0];
    }
    this.commercialSales = res.items;
  }

  updateNetwork(res: any) {
    if (res.items.length == 1) {
      this.networkModel = res.items[0];
      store.dispatch("demandModule/networknode", {
        callback: this.updateNetworkNode,
        filter: {
          id: res.items[0].label,
          user: this.associatedPartyUser,
        },
      });
    }
    this.networkList = res?.items?.filter((item: any, index: any, self: any) =>
    index === self.findIndex((t: any) => (
        t.id === item.id
    ))) ?? [];
  }

  async updateNetworkNode(res: any) {
    if (res.items && res.items?.length == 1) {
      this.networknodeModel = res.items[0];
      store
        .dispatch("demandModule/commercial", {
          callback: this.updateCommercial,
          filter: {
            id: res.items[0].label,
            user: this.associatedPartyUser,
          },
        })
        .then(
          await store.dispatch("demandModule/destributer", {
            callback: this.updateDestributer,
            filter: {
              id: res.items[0].label,
              user: this.associatedPartyUser,
            },
          })
        );
    }
    this.networkNode = res.items;
  }

  onChangeNetworknode(event: any) {
    store.dispatch("demandModule/setNetwork", event);
    store.dispatch("demandModule/setNetworkNode"); // reset the network node for the current (parent) network.
    this.networknodeModel = undefined;
    store.dispatch("demandModule/networknode", {
      callback: this.updateNetworkNode,
      filter: {
        id: event.label,
        user: this.associatedPartyUser,
      },
    });
  }

  onChangeChannel(event: any) {
    store.dispatch("demandModule/changeChannel", event.id);
  }

  async updateDestributer(res: any) {

    if (!this.offer?.resourceUid || this.offer?.resourceUid === "") {

      if (res.items && res.items.length > 0) {
        const index = this.offer?.associatedParties?.findIndex(
          (offerAssociatedParty) => offerAssociatedParty.id === res.items[0].id
        );
        if (index === -1 && res.items.length == 1) {
          this.destributer = res.items[0].label;
          const offerAssociatedParty: OfferAssociatedParty =
            store.getters["demandModule/getOfferAssociatedPartyInit"];
          offerAssociatedParty.id = this.parties?.length
            ? this.parties?.length + 1
            : offerAssociatedParty.id++;
          offerAssociatedParty.tabName = "DISTRIBUTOR";
          offerAssociatedParty.showDeleteBtn = true;
          offerAssociatedParty.role_code = res.items[0].label;
          offerAssociatedParty.uid = res.items[0].id;

          const associatedParty: AssociatedParty = {
            party: {
              resourceUid: res.items[0].id,
            },
          };
          await store.dispatch("demandModule/getAssociatedPartyOrganization", {
            associatedParty: associatedParty,
            callback: (response: AssociatedParty) => {
              offerAssociatedParty.associatedParty = response;
              store.dispatch("demandModule/addAssociatedParty", offerAssociatedParty);
            },
          });
        }
      }
    }
  }

  async onChangeCommercial(event: any) {
    store.dispatch("demandModule/setNetworkNode", event);
    store
      .dispatch("demandModule/commercial", {
        callback: this.updateCommercial,
        filter: {
          id: event.label,
          user: this.associatedPartyUser,
        },
      })
      .then(
        await store.dispatch("demandModule/destributer", {
          callback: this.updateDestributer,
          filter: {
            id: event.label,
            user: this.associatedPartyUser,
          },
        })
      );
  }

  created() {
    store.dispatch("demandModule/fetchUser").then((roles) => {
      this.associatedPartyUser = roles.resourceUid;
      store.dispatch("demandModule/network", {
        callback: this.updateNetwork,
        filter: {
          user: this.associatedPartyUser,
        },
      });
    });
  }

  getTasksFilter() {
    const reference = deepClone(this.offer || { reference: "" }).reference;
    store.dispatch("releaseLockState").then(() => {
      store
        .dispatch("taskModule/setFilterByOfferReference", reference)
        .then(() => {
          this.isInitWorkflowLoading = false;
          router.push("/task/list");
        })
    });
  }

  toDoListFilter() {
    this.isInitWorkflowLoading = true;
    store
      .dispatch("demandModule/workflowInitialized", this.offerReferenceValue)
      .then((workflowInitialized) => {
        if (!workflowInitialized) {
          store.dispatch("demandModule/initOfferWorkflow", {
            callback: this.getTasksFilter,
          });
        } else {
          this.getTasksFilter();
        }
      });
  }
}
